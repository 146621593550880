import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'WellUseCategory/';

const getWellUseCategories = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetWellUseCategories`, constants.REQUIRE_INTERCEPTORS);

const getWellUseCategoryById = async (wellUseCategoryId) => axiosInstance.get(`${ENDPOINTORIGIN}GetWellUseCategoryById?id=${wellUseCategoryId}`, constants.REQUIRE_INTERCEPTORS);

const saveWellUseCategory = async (wellUseCategory) => axiosInstance.post(`${ENDPOINTORIGIN}SaveWellUseCategory`, wellUseCategory, constants.REQUIRE_INTERCEPTORS);

const deleteWellUseCategory = async (id) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteWellUseCategory?id=${id}`, constants.REQUIRE_INTERCEPTORS);

export {
  getWellUseCategories,
  getWellUseCategoryById,
  saveWellUseCategory,
  deleteWellUseCategory
};