const constants = {
  API_URL: process.env.REACT_APP_API_URL,
  SITE_KEY: process.env.REACT_APP_SITE_KEY,
  MAPQUEST_URL: 'http://www.mapquestapi.com/',
  MAPQUEST_KEY: 'PNCgtAvNVdDt03jC73fmtmawEyLdkb3Z',
  REQUIRE_INTERCEPTORS: {
    requestHandlerEnabled: true,
    responseHandlerEnabled: true
  },
};

export default constants;