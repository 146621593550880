// COMPONENTS
import React from 'react';
import PropTypes from 'prop-types';
// ICONS
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';

const InfoBlock = (props) => {
  const {
    type,
    children,
    lineOne,
    lineTwo,
    lineThree,
    isCentered
  } = props;

  const infoTypes = {
    info: 'info light-blue--sbg',
    warning: 'warning lighter-yellow--sbg warning--clr',
    danger: 'danger lighter-red--sbg danger--clr',
    success: 'success lighter-green--sbg success--clr',
    theme: 'theme primary--sbg white--clr'
  };

  return (
    <CustomBlock className={`info-block ${type && infoTypes[type]} ${isCentered ? 'centered' : ''}`}>
      <CustomBlock className="text-block">
        {children}
        <p>{lineOne}</p>
        <p>{lineTwo}</p>
        <p>{lineThree}</p>
      </CustomBlock>
    </CustomBlock>
  );
};

InfoBlock.propTypes = {
  type: PropTypes.string,
  children: PropTypes.any,
  lineOne: PropTypes.string,
  lineTwo: PropTypes.string,
  lineThree: PropTypes.string,
  isCentered: PropTypes.bool,
};

InfoBlock.defaultProps = {
  type: 'info',
  children: null,
  lineOne: null,
  lineTwo: null,
  lineThree: null,
  isCentered: false
};

export default InfoBlock;