import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Aquifer/';

const getAquifers = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetAquifers`, constants.REQUIRE_INTERCEPTORS);

const getAquiferById = async (aquiferId) => axiosInstance.get(`${ENDPOINTORIGIN}GetAquiferById?id=${aquiferId}`, constants.REQUIRE_INTERCEPTORS);

const saveAquifer = async (aquifer) => axiosInstance.post(`${ENDPOINTORIGIN}SaveAquifer`, aquifer, constants.REQUIRE_INTERCEPTORS);

const deleteAquifer = async (id) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteAquifer?id=${id}`, constants.REQUIRE_INTERCEPTORS);

export {
  getAquifers,
  getAquiferById,
  saveAquifer,
  deleteAquifer
};