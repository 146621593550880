/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import {
  DataGrid,
  useGridApiRef
} from '@mui/x-data-grid';
// ICONS
import { MdPayment } from 'react-icons/md';
// CUSTOM COMPONENTS
import Section from '../../../../../components/Section';
import ContentBlock from '../../../../../components/ContentBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import ContentHeader from '../../../../../components/ContentHeader';
import Button from '../../../../../components/Button';
// REDUX
import * as alert from '../../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../../redux/confirmModalRedux';
// SERVICES AND HELPERS
import * as helper from '../../../../../helpers/helper';
import * as invoiceService from '../../../../../services/payment/invoiceService';
import Overlay from '../../../../../components/Overlay';

const UnpaidInvoicePage = (props) => {
  const { auth, showAlert } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const apiRef = useGridApiRef();

  useEffect(() => {
    fetchUnpaidInvoices();
  }, []);

  const fetchUnpaidInvoices = () => {
    setIsLoading(true);
    invoiceService.getAllUnpaidInvoices(auth.clientIds).then((res) => {
      setInvoices(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const columns = [
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      headerClassName: 'primary--sbg white--clr',
      renderCell: (params) => (
        <CustomBlock>
          <Button
            size="xxs"
            text="Make Payment"
            className="info--bg"
            iconLeft={<MdPayment className="white--clr" size={20} />}
            to={`/client/payment/make-payment/${params.row.id}`}
          />
        </CustomBlock>
      )
    },
    {
      field: 'title',
      headerName: 'Invoice #',
      headerClassName: 'primary--sbg white--clr',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'totalAmount',
      headerName: 'Total',
      headerClassName: 'primary--sbg white--clr',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) => (
        <strong>
          {helper.currencyFormat(params.row.totalAmount)}
        </strong>
      )
    }
  ];

  return (
    <CustomBlock className="content-container--padded">
      <Section isFullWidth>
        <ContentBlock>
          <CustomBlock className="content-container--card-style--with-shadow">
            <ContentHeader
              title="Make Payment"
              headerSize="lg"
            />
            <Section hasNoContainer>
              <ContentBlock>
                {
                  isLoading && <Overlay hasLoader />
                }
                <DataGrid
                  rows={invoices}
                  columns={columns}
                  rowsPerPageOptions={[5]}
                  pageSize={12}
                  ref={apiRef}
                  autoHeight
                  showCellRightBorder
                  showColumnRightBorder
                  disableSelectionOnClick
                />
              </ContentBlock>
            </Section>
          </CustomBlock>
        </ContentBlock>
      </Section>
    </CustomBlock>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...alert.actions,
  ...confirmModal.actions,
})(UnpaidInvoicePage);