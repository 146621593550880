import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Parish/';

const getParishes = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetParishes`, constants.REQUIRE_INTERCEPTORS);

const getParishById = async (parishId) => axiosInstance.get(`${ENDPOINTORIGIN}GetParishById?id=${parishId}`, constants.REQUIRE_INTERCEPTORS);

const saveParish = async (parish) => axiosInstance.post(`${ENDPOINTORIGIN}SaveParish`, parish, constants.REQUIRE_INTERCEPTORS);

const deleteParish = async (id) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteParish?id=${id}`, constants.REQUIRE_INTERCEPTORS);

export {
  getParishes,
  getParishById,
  saveParish,
  deleteParish
};