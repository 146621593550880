/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useState } from 'react';
// COMPONENTS
import { Alert } from '@material-ui/lab';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
// ICONS
// CUSTOM COMPONENTS
import CustomBlock from '../../../../../components/CustomBlock';
import Button from '../../../../../components/Button';
// REDUX
// SERVICES AND HELPERS

const CreditCardPaymentModule = (props) => {
  const {
    auth,
    paymentIntent,
    invoiceId,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const stripe = useStripe();

  const elements = useElements();

  const handleSubmit = async (event) => {
    setIsLoading(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: { return_url: `${window.location.origin}/client/payment/invoice-confirmation/${auth.clientIds}/${invoiceId}/${paymentIntent.id}` },
    }).finally(() => {
      setIsLoading(false);
    });

    if (result.error) {
      // console.log('Result is ', result);
      setErrorMessage(result.error.message);
      // showAlert({ text: result.error.message, state: 'error' });
    }
  };

  return (
    <CustomBlock className="content-container--padded">
      {
        errorMessage && (
          <Alert className="mb-15" severity="error">{errorMessage}</Alert>
        )
      }
      <form onSubmit={handleSubmit}>
        <PaymentElement />

        <CustomBlock className="content-container--actions mt-20">
          <Button
            type="submit"
            text={isLoading ? 'Processing...' : 'Pay Now'}
            className="success--bg mt-10"
            isDisabled={isLoading}
          />
        </CustomBlock>
      </form>
    </CustomBlock>
  );
};

export default CreditCardPaymentModule;