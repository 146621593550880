/* eslint-disable default-param-last */
/* eslint-disable no-console */
/* eslint-disable max-len */
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const actionTypes = {
  SetWellDetails: 'SET_PROJECT_DETAILS',
  ResetWellDetails: 'RESET_PROJECT_DETAILS'
};

const initialWellDetails = { wellName: '', wellId: 0 };

export const reducer = persistReducer(
  { storage, key: 'well-details', whitelist: ['wellName', 'wellId'] },
  (state = initialWellDetails, action) => {
    switch (action.type) {
      case actionTypes.SetWellDetails: {
        return { wellName: action.payload.wellName, wellId: action.payload.wellId };
      }

      case actionTypes.ResetWellDetails: {
        return initialWellDetails;
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setWellDetails: (model) => ({ type: actionTypes.SetWellDetails, payload: model }),
  resetWellDetails: () => ({ type: actionTypes.ResetWellDetails })
};