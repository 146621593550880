/* eslint-disable */
import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Invoice/';

const getCurrentYearInvoices = async (clientId) => axiosInstance.get(`${ENDPOINTORIGIN}GetCurrentYearInvoices?clientId=${clientId}`, constants.REQUIRE_INTERCEPTORS);

const getCurretQuaterInvoiceId = async (clientId) => axiosInstance.get(`${ENDPOINTORIGIN}GetCurretQuaterInvoiceId?clientId=${clientId}`, constants.REQUIRE_INTERCEPTORS);

const getAllUnpaidInvoices = async (clientId) => axiosInstance.get(`${ENDPOINTORIGIN}GetAllUnpaidInvoices?clientId=${clientId}`, constants.REQUIRE_INTERCEPTORS);

const getAllClientInvoices = async (clientId) => axiosInstance.get(`${ENDPOINTORIGIN}GetAllClientInvoices?clientId=${clientId}`, constants.REQUIRE_INTERCEPTORS);

const getAllInvoices = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}GetAllInvoices`, model, constants.REQUIRE_INTERCEPTORS);

const getAllPumpagesWithNoPayment = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetAllPumpagesWithNoPayment`, constants.REQUIRE_INTERCEPTORS);

const getInvoiceById = async (clientId, invoiceId) => axiosInstance.get(`${ENDPOINTORIGIN}GetInvoiceById?clientId=${clientId}&invoiceId=${invoiceId}`, constants.REQUIRE_INTERCEPTORS);

const saveInvoiceItem = async (clientId, model) => axiosInstance.put(`${ENDPOINTORIGIN}SaveInvoiceItem?clientId=${clientId}`, model, constants.REQUIRE_INTERCEPTORS);

const createPaymentIntent = async (clientId, invoiceId) => axiosInstance.get(`${ENDPOINTORIGIN}CreatePaymentIntent?clientId=${clientId}&invoiceId=${invoiceId}`, constants.REQUIRE_INTERCEPTORS);

const cancelPaymentIntent = async (clientId, paymentIntentId) => axiosInstance.get(`${ENDPOINTORIGIN}CancelPaymentIntent?clientId=${clientId}&paymentIntentId=${paymentIntentId}`, constants.REQUIRE_INTERCEPTORS);

const recordPayment = async (clientId, invoiceId, paymentIntentId) => axiosInstance.put(`${ENDPOINTORIGIN}RecordPayment?clientId=${clientId}&invoiceId=${invoiceId}&paymentIntentId=${paymentIntentId}`, {}, constants.REQUIRE_INTERCEPTORS);

const updateInvoicePumpage = async (clientId, model) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateInvoicePumpage?clientId=${clientId}`, model, constants.REQUIRE_INTERCEPTORS);

const recordPaymentByAdmin = async (invoiceId) => axiosInstance.put(`${ENDPOINTORIGIN}RecordPaymentByAdmin?invoiceId=${invoiceId}`, {}, constants.REQUIRE_INTERCEPTORS);

const updateInvoiceItemsPumpageManually = async (clientId, invoiceId) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateInvoiceItemsPumpageManually?clientId=${clientId}&invoiceId=${invoiceId}`, null, constants.REQUIRE_INTERCEPTORS);

const updateInvoiceVisibility = async (invoiceId, isVisible) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateInvoiceVisibility?invoiceId=${invoiceId}&isVisible=${isVisible}`, null, constants.REQUIRE_INTERCEPTORS);

const sendInvoiceEmail = async (invoiceId) => axiosInstance.get(`${ENDPOINTORIGIN}SendEmailToClient?invoiceId=${invoiceId}`, constants.REQUIRE_INTERCEPTORS);

const sendConfirmationEmail = async (invoiceId) => axiosInstance.get(`${ENDPOINTORIGIN}SendConfirmationEmail?invoiceId=${invoiceId}`, constants.REQUIRE_INTERCEPTORS);

const addAdjustment = async (invoiceId, adjustmentData) => 
  axiosInstance.post(`${ENDPOINTORIGIN}AddAdjustment?invoiceId=${invoiceId}`, adjustmentData, constants.REQUIRE_INTERCEPTORS);

const updateAdjustment = async (adjustmentId, adjustmentData) => 
  axiosInstance.put(`${ENDPOINTORIGIN}UpdateAdjustment?adjustmentId=${adjustmentId}`, adjustmentData, constants.REQUIRE_INTERCEPTORS);

const removeAdjustment = async (adjustmentId) => 
  axiosInstance.delete(`${ENDPOINTORIGIN}RemoveAdjustment?adjustmentId=${adjustmentId}`, constants.REQUIRE_INTERCEPTORS);

export {
  getAllInvoices,
  getCurrentYearInvoices,
  getCurretQuaterInvoiceId,
  getAllUnpaidInvoices,
  getAllClientInvoices,
  getInvoiceById,
  saveInvoiceItem,
  getAllPumpagesWithNoPayment,
  createPaymentIntent,
  cancelPaymentIntent,
  recordPayment,
  updateInvoicePumpage,
  recordPaymentByAdmin,
  updateInvoiceItemsPumpageManually,
  sendInvoiceEmail,
  sendConfirmationEmail,
  addAdjustment,
  updateAdjustment,
  removeAdjustment,
  updateInvoiceVisibility
};