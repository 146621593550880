/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/no-array-index-key */
// DEPENDENCIES
import React, { useState } from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import { Route, useHistory } from 'react-router-dom';
// ICONS
import {
  TbBorderAll,
  TbReportMoney,
  TbRipple,
  TbStack2,
  TbReportAnalytics,
  TbUsers,
  TbMenu,
  TbX,
  TbUser
} from 'react-icons/tb';
// CUSTOM COMPONENTS;
import DrawerNav from '../components/DrawerNav';
import NavBlock from '../components/NavBlock';
import CustomBlock from '../components/CustomBlock';
import SideNav from '../components/SideNav';
import TopNav from '../components/TopNav';
// ASSETS
// import Logo from '../assets/img/logo.svg';
import Icon from '../assets/img/icon.svg';
// REDUX
import * as auth from '../redux/authRedux';
// SERVICES AND HELPERS

const ClientRoute = ({ component: Component, ...rest }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSideNavCollapsed, setIsSideNavCollapsed] = useState(false);
  const [isSideNavMenuTitleVisible, setIsSideNavMenuTitleVisible] = useState(true);

  const history = useHistory();
  const iconSize = 26;
  const iconSizeLarge = 30;
  const iconColor = 'primary--clr';
  const mainPage = '/projects';

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const navigateToPath = (path) => {
    history.push(path);
    setTimeout(() => {
      setIsDrawerOpen(false);
    }, 250);
  };

  const sideNavItems = [
    {
      id: 0,
      iconLeft: <TbBorderAll className={iconColor} size={iconSize} />,
      to: '/client/dashboard',
      name: 'Dashboard',
      link: '/client/dashboard/',
      isVisible: rest.auth.accessLevel >= 20
    },
    {
      id: 1,
      iconLeft: <TbReportMoney className={iconColor} size={iconSize} />,
      // onClick: () => {
      //   invoiceService.getCurretQuaterInvoiceId(rest.auth.clientIds).then((res) => {
      //     if (res > 0) {
      //       history.push(`/client/payment/make-payment/${res}`);
      //     } else {
      //       rest.showAlert({ text: 'No Payments to be made for this quater', state: 'info' });
      //     }
      //   }).catch((ex) => {
      //     rest.showAlert({ text: ex.message, state: 'danger' });
      //   });
      // },
      to: '/client/payment/make-payment',
      name: 'Make Payment',
      link: '/client/payment/make-payment',
      isVisible: rest.auth.accessLevel >= 50
    },
    {
      id: 2,
      iconLeft: <TbRipple className={iconColor} size={iconSize} />,
      name: 'Wells',
      to: '/client/well/all-wells/',
      link: '/client/well/all-wells/',
      isVisible: rest.auth.accessLevel >= 10
    },
    {
      id: 3,
      iconLeft: <TbStack2 className={iconColor} size={iconSize} />,
      name: 'Water Data',
      to: '/client/well/water-data',
      link: '/client/well/water-data',
      isVisible: rest.auth.accessLevel >= 20
    },
    {
      id: 4,
      iconLeft: <TbReportAnalytics className={iconColor} size={iconSize} />,
      name: 'Invoices',
      to: rest.auth.accessLevel >= 50 ? '/invoices' : '/client/payment/invoices',
      link: rest.auth.accessLevel >= 50 ? '/invoices' : '/client/payment/invoices',
      isVisible: rest.auth.accessLevel >= 40
    },
    {
      id: 5,
      iconLeft: <TbUsers className={iconColor} size={iconSize} />,
      name: 'Manage Users',
      to: '/client/users',
      link: '/client/users',
      isVisible: rest.auth.accessLevel >= 40
    },
    {
      id: 6,
      iconLeft: <TbUsers className={iconColor} size={iconSize} />,
      name: 'Organization Profile',
      to: `/client/edit/${rest.auth.clientIds}`,
      link: `/client/edit/${rest.auth.clientIds}`,
      isVisible: rest.auth.accessLevel >= 40
    },
    {
      id: 7,
      iconLeft: <TbUser className={iconColor} size={iconSize} />,
      name: 'Reset Password',
      to: '/client/my-profile',
      link: '/client/my-profile',
      isVisible: rest.auth.accessLevel >= 10
    }
  ];

  return (
    <Route
      {...rest} render={(props) => (
        <>
          <TopNav
            hasReturnAction
            hasReturnActionBackground
            // hasNavigation
            hasHeaderTitle
            icon={Icon}
            iconTo={mainPage}
            headerTitle={rest.title}
            routePath={rest.path}
            userInitials={`${rest.auth.user.firstName[0]}${rest.auth.user.lastName[0]}`}
            // menuItems={topNavMenuItems.filter((x) => x.isVisible)}
            menuToggleIcon={
              isDrawerOpen
                ? (
                  <TbX
                    size={iconSizeLarge} className="danger--clr"
                    strokeWidth="3"
                  />
                ) : (
                  <TbMenu
                    size={iconSizeLarge} className="dark-blue--clr"
                    strokeWidth="3"
                  />
                )
            }
            secondaryIconActionOnClick={() => {
              rest.logout();
            }}
            onClickAvatar={() => {
              history.push('/my-profile');
            }}
            onClick={() => {
              toggleDrawer();
            }}
          />
          <DrawerNav
            isOpen={isDrawerOpen}
            onClick={() => {
              setIsDrawerOpen(false);
            }}
            onClickLogout={() => {
              rest.resetProjectDetails();
              rest.logout();
            }}
            navigationItems={
              sideNavItems.filter((obj) => obj.isVisible).map((item, index) => (
                <NavBlock
                  tooltipText={item.name}
                  key={index}
                  title={item.name}
                  iconLeft={item.iconLeft}
                  titleColor={item.to === rest.path ? 'info--clr' : 'dark-blue--clr'}
                  backgroundColor={item.to === rest.path ? 'lighter-blue--sbg' : 'white--sbg'}
                  onClick={() => {
                    if (item.to) {
                      navigateToPath(item.subMenuItems == null ? item.to : item.subMenuItems.to);
                    }
                  }}
                />
              ))
            }
          />

          <CustomBlock className="content-wrapper">
            <SideNav
              isCollapsible
              isExpanded={!isSideNavCollapsed}
              onClick={() => {
                setIsSideNavCollapsed(!isSideNavCollapsed);

                if (isSideNavCollapsed) {
                  setTimeout(() => {
                    setIsSideNavMenuTitleVisible(true);
                  }, 200);
                } else {
                  setIsSideNavMenuTitleVisible(false);
                }
              }}
            >
              {
                sideNavItems.filter((obj) => obj.isVisible).map((item, index) => (
                  <NavBlock
                    isCollapsed={isSideNavCollapsed}
                    tooltipText={item.name}
                    key={index}
                    title={isSideNavMenuTitleVisible ? item.name : ''}
                    titleColor={(rest.path.includes(item.link) || item.link.includes(rest.path)) ? 'info--clr' : 'dark-blue--clr'}
                    backgroundColor={(rest.path.includes(item.link) || item.link.includes(rest.path)) ? 'lighter-blue--sbg' : 'white--sbg'}
                    iconLeft={item.iconLeft}
                    onClick={() => {
                      if (item.to) {
                        navigateToPath(`${item.link}`);
                      } else {
                        item.onClick();
                      }
                    }}
                  />
                ))
              }
            </SideNav>
            <CustomBlock className="component-wrapper">
              <Component {...props} />
            </CustomBlock>
          </CustomBlock>
        </>
      )}
    />
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...auth.actions })(ClientRoute);