/* eslint-disable */
// DEPENDENCIES
import React, {
  useState,
  useEffect
} from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import Box from '@mui/material/Box';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
// ICONS
// CUSTOM COMPONENTS
import Section from '../../../../../components/Section';
import ContentBlock from '../../../../../components/ContentBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import ContentHeader from '../../../../../components/ContentHeader';
import SelectBlock from '../../../../../components/SelectBlock';
import Button from '../../../../../components/Button';

// HELPERS AND SERVICES
import * as wellPumpageService from '../../../../../services/wellPumpage/WellPumpageService';
import * as clientService from '../../../../../services/management/clientService';
import * as aquiferService from '../../../../../services/management/aquiferService';
import * as parishService from '../../../../../services/management/parishService';
import * as wellUseService from '../../../../../services/well-use/wellUseService';
import * as excelExportService from '../../../../../services/excelExport/excelExportService';
import * as helper from '../../../../../helpers/helper';
// REDUX
import * as alert from '../../../../../redux/alertToastRedux';
import * as authRedux from '../../../../../redux/authRedux';
import * as confirmModal from '../../../../../redux/confirmModalRedux';
import Overlay from '../../../../../components/Overlay';

const model = {
  startDate: null,
  endDate: null,
  quarter: [],
  clientId: [],
  parishId: [],
  wellUseId: [],
  aquiferId: []
};

const quarters = [1, 2, 3, 4];

const tabTypes = [{ id: 1, name: 'Pumpage Volume By WellUse' }];
const pageSize = 5;
const VolumesByWellUse = (props) => {
  const { showAlert } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [wellPumpages, setWellPumpages] = useState([]);
  const [activeTab, setActiveTab] = useState('1');
  const [startDate, setStartDate] = useState('');
  const [quarterOptions, setquarterOptions] = useState(null);
  const [clients, setClients] = useState([]);
  const [aquifers, setAquifers] = useState([]);
  const [parishes, setParishes] = useState([]);
  const [wellUses, setWellUses] = useState([]);
  const [filterModel, setFilterModel] = useState({ ...model });
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedClientOption, setSelectedClientOption] = useState([]);
  const [selectedAquiferOption, setSelectedAquiferOption] = useState([]);
  const [selectedWellUseOption, setSelectedWellUseOption] = useState([]);
  const [selectedParishOption, setSelectedParishOption] = useState([]);
  const [applyFilter, setApplyFilter] = useState(false);
  const [selectedStartDateOption, setSelectedStartDateOption] = useState(null);
  const [selectedEndDateOption, setSelectedEndDateOption] = useState(null);
  const [selectedquarterOption, setSelectedquarterOption] = useState(null);

  useEffect(() => {
    setquarterOptions(quarters.map((x) => helper.setToOptionModel(x, x)));
    fetchClients();
    fetchAquifers();
    fetchParishes();
    fetchWellUse();
  }, []);

  useEffect(() => {
    if (parseInt(activeTab, 10) === 1) {
      getPumpageVolumesByWellUsage();
    }
  }, [activeTab, applyFilter, pageNumber, pageSize]);

  const fetchClients = () => {
    clientService.getAllClients(true).then((res) => {
      const tempClients = res.map((x) => ({ label: x.name, value: x.id }));
      setClients(tempClients);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    });
  };

  const fetchAquifers = () => {
    aquiferService.getAquifers().then((res) => {
      const tempAquifers = res.map((x) => ({ label: x.name, value: x.id }));
      setAquifers(tempAquifers);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    });
  };

  const fetchParishes = () => {
    parishService.getParishes().then((res) => {
      const tempParishes = res.map((x) => ({ label: x.name, value: x.id }));
      setParishes(tempParishes);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    });
  };

  const fetchWellUse = () => {
    wellUseService.getWellUses().then((res) => {
      const tempWellUses = res.map((x) => ({ label: x.description, value: x.id }));
      setWellUses(tempWellUses);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    });
  };

  const handleSelect = (eventKey) => {
    setActiveTab(eventKey);
    setTotalPages(0);
    setPageNumber(0);
  };

  const handleReset = () => {
    setFilterModel({
      clientId: [],
      startDate: null,
      endDate: null,
      quarter: [],
      aquiferId: [],
      parishId: []
    });
    setSelectedClientOption([]);
    setSelectedquarterOption(
      []
    );
    setSelectedStartDateOption(null);
    setSelectedEndDateOption(null);
    setSelectedParishOption([]);
    setSelectedWellUseOption([]);
    setSelectedAquiferOption([]);

    setStartDate(new Date());
    setApplyFilter(!applyFilter);
  };

  const getPumpageVolumesByWellUsageForExport = () => {
    setIsLoading(true);
    return wellPumpageService.getPumpageVolumesByWellUse({
      ...filterModel,
      pageNumber,
      pageSize,
      getAll: true
    })
      .then((res) => {
        setIsLoading(false);
        return res.data;
      }).catch((ex) => {
        showAlert({ text: ex.message, state: 'error' });
        setIsLoading(false);
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const getPumpageVolumesByWellUsage = () => {
    setIsLoading(true);
    wellPumpageService.getPumpageVolumesByWellUse({
      ...filterModel,
      pageNumber,
      pageSize,
      getAll: false
    })
      .then((res) => {
        const pageData = res.data;
        setWellPumpages(pageData);
        setTotalPages(Math.ceil(res.totalRecords / pageSize));
      }).catch((ex) => {
        showAlert({ text: ex.message, state: 'error' });
      }).finally(() => {
        setIsLoading(false);
      });
  };

  // const handleExport = async (pumpageData, activeTab) => {
  //   excelExportService.downloadWellPumpageByClientReport({
  //     ...filterModel,
  //     pageNumber,
  //     pageSize,
  //     getAll: false
  //   })
  //   .then(blob => {
  //     const url = window.URL.createObjectURL(blob);
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.download = `PumpageByClient_${filterModel.year ?? model.year}.xlsx`;
  //     document.body.appendChild(link);
  //     link.click();

  //     window.setTimeout(() => {
  //       window.URL.revokeObjectURL(url);
  //       link.remove();
  //     }, 100);
  //   })
  //   .catch(error => {
  //     console.error('Error while downloading the file:', error);
  //   });
  // }

  const handleExport = async (pumpageData, activeTab) => {
    let extractedData = pumpageData;
    if (activeTab === '1') {
      await getPumpageVolumesByWellUsageForExport().then((data) => {
        extractedData = data;
      });
    }
    const data = extractData(extractedData, activeTab);
    const exportData = [...data];
    const filterInfo = {
      "Start Date": startDate,
      "Active Tab": activeTab,
      "Selected Parish": selectedParishOption && selectedParishOption.length > 0 ? selectedParishOption.map((x) => x.label).join('| ') : null,
      "Selected Quarter": selectedquarterOption ? selectedquarterOption.value : null,
      "Selected StartDate": selectedStartDateOption ? selectedStartDateOption.value : null,
      "Selected EndDate": selectedEndDateOption ? selectedEndDateOption.value : null,
      "Selected Client": selectedClientOption && selectedClientOption.length > 0 ? selectedClientOption.map((x) => x.label).join('| ') : null,
      "Selected WellUse": selectedWellUseOption && selectedWellUseOption.length > 0 ? selectedWellUseOption.map((x) => x.label).join('| ') : null,
      "Selected Aquifer": selectedAquiferOption && selectedAquiferOption.length > 0 ? selectedAquiferOption.map((x) => x.label).join('| ') : null,
    };

    const fieldNames = { 1: ['WellNumber', 'Client', 'WellUse', 'Parish', 'Total (Mill. Gal.)', 'Daily (MGD)'] };

    let csvContent = '';
    let currentSeparator = '';

    const filterHeaders = Object.keys(filterInfo);
    const filterValues = Object.values(filterInfo);
    csvContent += filterHeaders.join(',') + '\n';
    csvContent += filterValues.join(',') + '\n';

    csvContent += '\n';
    exportData.forEach((row) => {
      if (row.separator) {
        if (csvContent.length > 0) {
          csvContent += '\n'; // Add a new line between different groups
        }
        currentSeparator = `${row.value}`;
        csvContent += currentSeparator;
        if (row.id === 2) {
          csvContent += '\n';
          csvContent += fieldNames[activeTab].join(',') + '\n'; // Add header row
          // csvContent += '\n';
        }

        if (row.id === 3) {
          csvContent += '\n';
          csvContent += row.totalPumpage + '\n'; // Add header row for data rows
          csvContent += row.dailyPumpRate + '\n'; // Add header row for data rows
          csvContent += '\n';
        }

        if (row.id === 4) {
          csvContent += '\n';
          csvContent += row.totalPumpage + '\n'; // Add header row for data rows
          csvContent += row.dailyPumpRate + '\n'; // Add header row for data rows
          csvContent += '\n';
        }
      } else {
        const { separator, ...rowData } = row;
        csvContent += Object.values(rowData).join(',') + '\n'; // Add data row
      }
    });
    const link = document.createElement('a');
    link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csvContent)}`;
    link.download = `export_tab_${activeTab}.csv`;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const extractData = (pumpages, activeTab) => {
    const extractedData = [];
    const aquiferTotals = {}; // To store totals for each aquifer
    let currentAquifer = '';
    let totalPumpageAquifer = 0; // Total for totalPumpage within this group
    let dailyPumpRateAquifer = 0; // Total for dailyPumpRate within this group

    pumpages.forEach((p, i, array) => {
      let totalPumpageGroup = 0; // Total for totalPumpage within this group
      let dailyPumpRateGroup = 0; // Total for dailyPumpRate within this group

      if (currentAquifer !== p.aquifer) {
        if (aquiferTotals[currentAquifer]) {
          extractedData.push({
            id: 4,
            separator: true,
            value: `Total for Aquifer: ${currentAquifer}`,
            totalPumpage: aquiferTotals[currentAquifer].totalPumpage,
            dailyPumpRate: aquiferTotals[currentAquifer].dailyPumpRate,
          });
        }
        // } else {
        //   if (currentAquifer === '') {
        //     extractedData.push({
        //       id: 4,
        //       separator: true,
        //       value: `Total for Aquifer: ${p.aquifer}`,
        //       totalPumpage: totalPumpageGroup,
        //       dailyPumpRate: dailyPumpRateGroup,
        //     });
        //   }
        // }
        totalPumpageAquifer = 0;
        dailyPumpRateAquifer = 0;
  
        currentAquifer = p.aquifer;
      }

      extractedData.push(
        {
          id: 0,
          separator: true,
          value: `Aquifer: ${p.aquifer}`
        },
        {
          id: 1,
          separator: true,
          value: `WellUse: ${p.wellUse}`
        },
        {
          id: 2,
          separator: true,
          value: `Parish: ${p.parish}`
        }
      );

      if (parseInt(activeTab, 10) === 1) {
        p.wellPumpageByVolumeItemsResources.forEach((d) => {
          extractedData.push({
            wellNumber: d.wellNumber,
            client: d.client,
            wellUse: d.wellUse,
            parish: d.parish,
            totalPumpage: d.totalPumpage,
            dailyPumpRate: d.dailyPumpRate,
          });

          // Calculate group totals
          totalPumpageGroup += d.totalPumpage;
          dailyPumpRateGroup += d.dailyPumpRate;
        });

        // Add group totals as a row after the group
        extractedData.push({
          id: 3,
          separator: true,
          value: `WellUse Total for ${p.wellUse}`,
          totalPumpage: totalPumpageGroup,
          dailyPumpRate: dailyPumpRateGroup,
        });

        totalPumpageAquifer = totalPumpageGroup;
        dailyPumpRateAquifer = dailyPumpRateGroup;

        if (currentAquifer === p.aquifer) {
          if (aquiferTotals[currentAquifer]) {
            aquiferTotals[currentAquifer].totalPumpage += totalPumpageAquifer;
            aquiferTotals[currentAquifer].dailyPumpRate += dailyPumpRateAquifer;
          } else {
            aquiferTotals[currentAquifer] = {
              totalPumpage: totalPumpageAquifer,
              dailyPumpRate: dailyPumpRateAquifer,
            };
          }
        }

        if (i === array.length - 1 && Object.keys(aquiferTotals).length !== 1) {
          extractedData.push({
            id: 4,
            separator: true,
            value: `Total for Aquifer: ${currentAquifer}`,
            totalPumpage: aquiferTotals[currentAquifer].totalPumpage,
            dailyPumpRate: aquiferTotals[currentAquifer].dailyPumpRate,
          });
        }
      }
    });

    if (Object.keys(aquiferTotals).length === 1) {
      const singleAquifer = Object.keys(aquiferTotals)[0];
      extractedData.push({
        id: 4,
        separator: true,
        value: `Total for Aquifer: ${singleAquifer}`,
        totalPumpage: aquiferTotals[singleAquifer].totalPumpage += totalPumpageAquifer,
        dailyPumpRate: aquiferTotals[singleAquifer].dailyPumpRate += dailyPumpRateAquifer,
      });
    }

    return extractedData;
  };

  return (
    <CustomBlock className="content-container--padded">
      {
        isLoading && <Overlay hasLoader />
      }
      <Section isFullWidth>
        <ContentBlock>
          <CustomBlock className="content-container--card-style--with-shadow">
            <ContentHeader
              title="Water Data"
              headerSize="lg"
            />
            <CustomBlock className="flex">
              <Section hasNoContainer>
                <>
                  <ContentBlock cols={4}>
                    <SelectBlock
                      label="Start Date"
                      placeholder="Select year"
                      options={helper.getYears()}
                      closeMenuOnSelect
                      isClearable
                      value={selectedStartDateOption}
                      onChange={(selectedOpt) => {
                        selectedOpt = selectedOpt === null ? null : selectedOpt;
                        setFilterModel({
                          ...filterModel,
                          startDate: (selectedOpt && selectedOpt.value) ?? null
                        });
                        setSelectedStartDateOption(selectedOpt);
                      }}
                    />
                  </ContentBlock>
                  <ContentBlock cols={4}>
                    <SelectBlock
                      label="End Date"
                      placeholder="Select year"
                      options={helper.getYears()}
                      closeMenuOnSelect
                      isClearable
                      value={selectedEndDateOption}
                      onChange={(selectedOpt) => {
                        selectedOpt = selectedOpt === null ? null : selectedOpt;
                        setFilterModel({
                          ...filterModel,
                          endDate: (selectedOpt && selectedOpt.value) ?? null
                        });
                        setSelectedEndDateOption(selectedOpt);
                      }}
                    />
                  </ContentBlock>
                  <ContentBlock cols={4}>
                    <SelectBlock
                      label="Quarter"
                      placeholder="Select quarter"
                      options={quarterOptions}
                      isClearable
                      isMulti
                      closeMenuOnSelect={false}
                      value={selectedquarterOption}
                      onChange={(selectedOpt) => {
                        selectedOpt = selectedOpt === null ? null : selectedOpt;
                        console.log(selectedOpt);
                        setFilterModel({
                          ...filterModel,
                          quarter: selectedOpt.map((x) => x.value) ?? ''
                        });
                        setSelectedquarterOption(selectedOpt);
                      }}
                    />
                  </ContentBlock>
                  <ContentBlock cols={6}>
                    <SelectBlock
                      label="Parish"
                      placeholder="Select parish"
                      isClearable
                      options={parishes}
                      closeMenuOnSelect={false}
                      isMulti
                      value={selectedParishOption}
                      onChange={(selectedOpt) => {
                        selectedOpt = selectedOpt === null ? null : selectedOpt;
                        setFilterModel({
                          ...filterModel,
                          parishId: selectedOpt.map((x) => x.value) ?? ''
                        });
                        setSelectedParishOption(selectedOpt);
                      }}
                    />
                  </ContentBlock>
                  <ContentBlock cols={6}>
                    <SelectBlock
                      label="Client"
                      placeholder="Select client"
                      options={clients}
                      isClearable
                      isMulti
                      closeMenuOnSelect={false}
                      value={selectedClientOption}
                      onChange={(selectedOpt) => {
                        selectedOpt = selectedOpt === null ? null : selectedOpt;
                        setFilterModel({
                          ...filterModel,
                          clientId: selectedOpt.map((x) => x.value) ?? ''
                        });
                        setSelectedClientOption(selectedOpt);
                      }}
                    />
                  </ContentBlock>
                  <ContentBlock cols={6}>
                    <SelectBlock
                      label="Aquifer"
                      placeholder="Select aquifer"
                      options={aquifers}
                      isClearable
                      isMulti
                      closeMenuOnSelect={false}
                      value={selectedAquiferOption}
                      onChange={(selectedOpt) => {
                        selectedOpt = selectedOpt === null ? null : selectedOpt;
                        setFilterModel({
                          ...filterModel,
                          aquiferId: selectedOpt.map((x) => x.value) ?? ''
                        });
                        setSelectedAquiferOption(selectedOpt);
                      }}
                    />
                  </ContentBlock>
                  <ContentBlock cols={6}>
                    <SelectBlock
                      label="Well Use"
                      placeholder="Select Well-Use"
                      options={wellUses}
                      isClearable
                      isMulti
                      closeMenuOnSelect={false}
                      value={selectedWellUseOption}
                      onChange={(selectedOpt) => {
                        selectedOpt = selectedOpt === null ? null : selectedOpt;
                        setFilterModel({
                          ...filterModel,
                          wellUseId: selectedOpt.map((x) => x.value) ?? ''
                        });
                        setSelectedWellUseOption(selectedOpt);
                      }}
                    />
                  </ContentBlock>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 20 }}>
                    <Button
                      text="Reset"
                      className="primary--bg flex justify-content-center"
                      onClick={handleReset}
                    />
                    &nbsp;
                    <Button
                      text="Search"
                      className="primary--bg ml-0"
                      size="xs"
                      onClick={() => setApplyFilter(!applyFilter)}
                    />
                  </div>
                </>
              </Section>

            </CustomBlock>

            <Section hasNoContainer>
              <ContentBlock>
                <Tabs
                  id="tab"
                  hidden
                  className="mb-3"
                  activeKey={parseInt(activeTab, 10)}
                  onSelect={handleSelect}
                >
                  {tabTypes && (tabTypes).map((type) => (
                    <Tab eventKey={type.id} title={type.name}>
                      {wellPumpages && wellPumpages.length > 0 && (
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Button
                            text="Export"
                            className="primary--bg"
                            onClick={() => handleExport(wellPumpages, activeTab)}
                          />
                        </div>
                      )}
                      <Table
                        striped
                        bordered
                        hover
                        responsive
                      >
                        {wellPumpages && wellPumpages.map((p) => (
                          <>
                            {/* <thead>
                              <tr
                                className="primary--sbg white--clr"
                                key={
                                  parseInt(activeTab, 10) === 3
                                    ? p.client
                                    : parseInt(activeTab, 10) === 4
                                      ? p.aquifer
                                      : p.wellNumber
                                }
                              >
                                <th colSpan={27}>
                                  {parseInt(activeTab, 10) === 3
                                    ? p.client
                                    : parseInt(activeTab, 10) === 4
                                      ? p.aquifer
                                      : p.wellNumber}
                                </th>
                              </tr>
                            </thead> */}
                            <thead>
                              <tr
                                className="primary--sbg white--clr"
                                key={
                                  p.aquifer
                                }
                              >
                                <th colSpan={27}>
                                  {`Aquifer:  ${p.aquifer ?? 'N/A'}`}
                                </th>
                              </tr>
                            </thead>
                            <thead>
                              <tr
                                className="info--sbg white--clr"
                                key={
                                  p.wellUse
                                }
                              >
                                <th colSpan={27}>
                                  {`Well Use:  ${p.wellUse ?? 'N/A'}`}
                                </th>
                              </tr>
                            </thead>
                            {parseInt(activeTab, 10) === 1 && (
                              <>
                                <thead>
                                  <tr>
                                    <th>Well Number</th>
                                    <th>Owner</th>
                                    <th>Aquifer</th>
                                    <th>Use</th>
                                    <th>Total (Mill. Gal.)</th>
                                    <th>Daily (MGD)</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {p.wellPumpageByVolumeItemsResources &&
                                    p.wellPumpageByVolumeItemsResources.map((d) => (
                                      <tr>
                                        <td>{d.wellNumber ?? '-'}</td>
                                        <td>{d.client ?? '-'}</td>
                                        <td>{d.aquifer ?? '-'}</td>
                                        <td>{d.wellUse ?? '-'}</td>
                                        <td>{d.totalPumpage}</td>
                                        <td>{d.dailyPumpRate}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </>
                            )}
                          </>
                        ))}
                      </Table>
                      <ReactPaginate
                        pageCount={totalPages}
                        onPageChange={({ selected }) => setPageNumber(selected + 1)}
                        containerClassName="pagination"
                        activeClassName="active"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        disabledClassName="disabled"
                        activeLinkClassName="active-link"
                        previousLabel="Previous"
                        nextLabel="Next"
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      />
                    </Tab>
                  ))}
                </Tabs>
              </ContentBlock>
            </Section>
          </CustomBlock>
        </ContentBlock>
      </Section>
    </CustomBlock>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...authRedux.actions,
    ...alert.actions,
    ...confirmModal.actions
  }
)(VolumesByWellUse);