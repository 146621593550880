/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable  no-nested-ternary */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
// import { Alert } from '@material-ui/lab';
// COMPONENTS
// import Spinner from 'react-bootstrap/Spinner';
// ICONS
// CUSTOM COMPONENTS
import Section from '../../../../../components/Section';
import ContentBlock from '../../../../../components/ContentBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import InfoBlock from '../../../../../components/InfoBlock';
import Overlay from '../../../../../components/Overlay';
import Button from '../../../../../components/Button';
// REDUX
import * as alert from '../../../../../redux/alertToastRedux';
// SERVICES AND HELPERS
import * as invoiceService from '../../../../../services/payment/invoiceService';

const PaymentConfirmationPage = (props) => {
  const { showAlert, history } = props;
  const [isLoading, setIsLoading] = useState(true);
  const {
    clientId,
    invoiceId,
    paymentIntentId,
  } = useParams();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    recordPayment();
  }, []);

  const recordPayment = () => {
    invoiceService.recordPayment(clientId, invoiceId, paymentIntentId).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      setTimeout(() => {
        history.push(`/client/payment/view-invoice/${invoiceId}`);
      }, 2500);
    }).catch((ex) => {
      showAlert({ text: 'Error! Something went wrong. Please check the error', state: 'error' });
      cancelPayment();
      setErrorMessage(ex.message);
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const cancelPayment = () => {
    invoiceService.cancelPaymentIntent(clientId, paymentIntentId).then(() => {
      console.log('Payment intent canceled');
    }).catch(() => {

    }).finally(() => { });
  };

  return (
    <>
      {
        isLoading
          ? (
            <Overlay
              hasLoader
              loaderText="Please wait while we process your payment..."
            />
          )
          : (
            <CustomBlock className="content-container--padded">
              <Section isFullWidth>
                <ContentBlock>
                  <CustomBlock>
                    {
                      errorMessage
                        ? (
                          <CustomBlock>
                            <InfoBlock isCentered type="danger">
                              <p>{errorMessage}</p>
                            </InfoBlock>

                            <CustomBlock className="flex-center">
                              <Button
                                text="Back to Invoice"
                                className="primary--bg"
                                onClick={() => {
                                  history.goBack();
                                }}
                              />
                            </CustomBlock>
                          </CustomBlock>
                        ) : (
                          <InfoBlock isCentered type="success">
                            <p>Payment processed successfully! Please wait while we redirect you...</p>
                          </InfoBlock>
                        )
                    }
                  </CustomBlock>
                </ContentBlock>
              </Section>
            </CustomBlock>
          )
      }
    </>
    // <div
    //   className="payment-processing-container"
    //   style={{
    //     display: 'felx',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     flexWrap: 'wrap',
    //     height: '80vh'
    //   }}
    // >
    //   {isLoading ? (
    //     <div
    //       style={{
    //         display: 'grid',
    //         placeItems: 'center',
    //         height: '80vh',
    //       }}
    //     >
    //       <Spinner
    //         animation="border"
    //         role="status"
    //       >
    //         <span className="visually-hidden">Loading...</span>
    //       </Spinner>
    //       <p
    //         className="payment-processing-text"
    //         style={{
    //           margintop: '30px',
    //           textalign: 'center',
    //           fontsize: '18px'
    //         }}
    //       >
    //         Please wait while we process your payment...
    //       </p>
    //     </div>
    //   ) : (
    //     errorMessage
    //       ? (
    //         <InfoBlock isCentered type="danger">
    //           <p>{errorMessage}</p>
    //         </InfoBlock>
    //         // <Alert severity="error">
    //         //   {errorMessage}
    //         // </Alert>
    //       )
    //       : (
    //         <p
    //           className="payment-success-text"
    //           style={{
    //             textAlign: 'center',
    //             fontSize: '18px',
    //             color: 'green',
    //           }}
    //         >
    //           Payment processed successfully! Please wait while we redirect you...
    //         </p>
    //       )
    //   )}
    // </div>
  );
};

export default connect(null, { ...alert.actions })(PaymentConfirmationPage);