import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Well/';

const getWells = async (isActive) => axiosInstance.get(`${ENDPOINTORIGIN}GetWells?isActive=${isActive}`, constants.REQUIRE_INTERCEPTORS);

const getWellsForDashboard = async (filters) => axiosInstance
  .post(`${ENDPOINTORIGIN}GetWellsWithFilters`, filters, constants.REQUIRE_INTERCEPTORS);

const getWellById = async (clientId, id) => axiosInstance.get(`${ENDPOINTORIGIN}GetWellById?clientId=${clientId}&id=${id}`, constants.REQUIRE_INTERCEPTORS);

const saveWell = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}SaveWell`, model, constants.REQUIRE_INTERCEPTORS);

const deleteWell = async (id) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteWell?id=${id}`, constants.REQUIRE_INTERCEPTORS);

export {
  getWells,
  getWellById,
  getWellsForDashboard,
  saveWell,
  deleteWell
};