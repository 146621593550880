import * as Yup from 'yup';

export default Yup.object().shape({
  clientId: Yup.number().nullable(),
  aquiferId: Yup.number().nullable(),
  wellUseId: Yup.number().nullable(),
  parishId: Yup.number().nullable(),
  identificationNumber: Yup.string().required('Identification Number is required').max(30),
  wellNumber: Yup.string().required('Well Number is required').max(30),
  permitNumber: Yup.number().nullable(),
  wellDepth: Yup.number(),
  dailyPumpRate: Yup.number().nullable('Daily Pump Rate is required'),
  clientWellName: Yup.string().nullable(),
  isRegistered: Yup.bool(),
  availableInformation: Yup.string().nullable(),
  latitude: Yup.string().nullable(),
  longitude: Yup.string().nullable(),
  flag: Yup.string().nullable(),
  completionDateTime: Yup.string().nullable(),
  wellStatusDate: Yup.string().nullable(),
  cagcdPermitcompletionDate: Yup.string().nullable(),
  ldnrNotificationDate: Yup.string().nullable(),
  inactiveDateTime: Yup.string().nullable(),
  comment: Yup.string().nullable()
});