/* eslint-disable */
import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'ExcelExport/';

const downloadComplianceReport = async (year, quarter) => axiosInstance.get(`${ENDPOINTORIGIN}DownloadComplianceReport?year=${year}&quarterString=${encodeURIComponent(quarter.join(','))}`, {
  ...constants.REQUIRE_INTERCEPTORS,
  responseType: 'blob'
});

const downloadWellPumpageByClientReport = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}DownloadWellPumpageByClientReport`, model, {
  ...constants.REQUIRE_INTERCEPTORS,
  responseType: 'blob'
});


export {
  downloadComplianceReport,
  downloadWellPumpageByClientReport
};