/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
// COMPONENTS
// ICONS
// CUSTOM COMPONENTS
import Overlay from '../../../../components/Overlay';
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import FormBlock from '../../../../components/FormBlock';
import CheckboxBlock from '../../../../components/CheckboxBlock';
import ContentHeader from '../../../../components/ContentHeader';
import InputBlock from '../../../../components/InputBlock';
import Button from '../../../../components/Button';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';
// SERVICES AND HELPERS
import * as wellUseCategoryService from '../../../../services/well-use/wellUseCategoryService';
import WellUseCategoryValidator from '../../../../helpers/validators/well-use/wellUseCategoryValidator';
import * as helper from '../../../../helpers/helper';

const initialWellUseCategoryModel = {
  id: 0,
  description: '',
  isActive: true,
};

const WellUseCategoryManagementPage = (props) => {
  const { showAlert } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [wellUseCategory, setWellUseCategory] = useState(initialWellUseCategoryModel);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      populateForm();
    }
  }, []);

  const formik = useFormik({
    initialValues: wellUseCategory,
    validationSchema: WellUseCategoryValidator,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      saveWellUseCategory(values);
    },
  });

  const populateForm = () => {
    setIsLoading(true);
    wellUseCategoryService.getWellUseCategoryById(id).then((res) => {
      setWellUseCategory(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const saveWellUseCategory = (model) => {
    setIsLoading(true);
    wellUseCategoryService.saveWellUseCategory(model).then((res) => {
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <FormBlock className="content-container--card-style--with-shadow" onSubmit={formik.handleSubmit}>
            <ContentHeader
              title="Well-Use Category Details"
              headerSize="lg"
            />

            <Section hasNoContainer>
              <ContentBlock>
                <ContentHeader
                  title="Basic Information"
                  headerSize="md"
                  className="alt-font fw-600 pb-15"
                />
              </ContentBlock>

              <ContentBlock cols={6}>
                <InputBlock
                  label="Well-Use Category Description"
                  isRequired
                  errorMessage={formik.errors.description}
                  inputState={`${helper.getInputClasses(formik, 'description')}`}
                  {...formik.getFieldProps('description')}
                />
              </ContentBlock>

              <ContentBlock>
                <CheckboxBlock
                  label="Is active?"
                  id="isActive"
                  {...formik.getFieldProps('isActive')}
                  isChecked={formik.values.isActive}
                />
              </ContentBlock>
              <ContentBlock className="content-container--actions flex-end">
                <Button
                  text={id ? 'Save Changes' : 'Create'}
                  className="primary--bg"
                  onClick={formik.handleSubmit}
                />
              </ContentBlock>
              
            </Section>
          </FormBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...alert.actions,
  ...confirmModal.actions
})(WellUseCategoryManagementPage);