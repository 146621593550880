import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'WellUse/';

const getWellUses = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetWellUses`, constants.REQUIRE_INTERCEPTORS);

const getWellUseById = async (wellUseId) => axiosInstance.get(`${ENDPOINTORIGIN}GetWellUseById?id=${wellUseId}`, constants.REQUIRE_INTERCEPTORS);

const saveWellUse = async (wellUse) => axiosInstance.post(`${ENDPOINTORIGIN}SaveWellUse`, wellUse, constants.REQUIRE_INTERCEPTORS);

const deleteWellUse = async (id) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteWellUse?id=${id}`, constants.REQUIRE_INTERCEPTORS);

export {
  getWellUses,
  getWellUseById,
  saveWellUse,
  deleteWellUse
};