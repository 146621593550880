/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
// DEPENDENCIES
import React, {
  useState, useEffect, useRef
} from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
// COMPONENTS
// ICONS
import {
  FiEye,
  FiEyeOff,
  FiMail
} from 'react-icons/fi';
// CUSTOM COMPONENTS
import Section from '../../../components/Section';
import ContentBlock from '../../../components/ContentBlock';
import ContentHeader from '../../../components/ContentHeader';
import FormBlock from '../../../components/FormBlock';
import CustomBlock from '../../../components/CustomBlock';
import InputBlock from '../../../components/InputBlock';
import Button from '../../../components/Button';
import OverLay from '../../../components/Overlay';
// HELPERS AND SERVICES
import * as userService from '../../../services/management/userService';
import constants from '../../../constants/constants';
import * as helper from '../../../helpers/helper';
import RegisterUserValidator from '../../../helpers/validators/management/user/RegisterUserValidator';
// REDUX
import * as alert from '../../../redux/alertToastRedux';
import * as confirmModal from '../../../redux/confirmModalRedux';

const userRegisterModel = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  roleIds: '',
  registrationCode: '',
  clientId: '',
  username: '',
  phone: '',
  password: '',
  reCAPTCHAToken: '',
  confirmPassword: '',
  isActive: true
};

const RegistrationPage = (props) => {
  const captchaRef = useRef(null);
  const { showAlert, history } = props;
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
  }, []);

  const iconSize = 22;
  // const iconColor = 'white--clr';
  const inputIconColor = 'grey--clr';
  // const pinCodeIcon = <IoIosKeypad size={iconSize} className={inputIconColor} />;
  const emailIcon = <FiMail size={iconSize} className={inputIconColor} />;

  const registerUserFormik = useFormik({
    initialValues: userRegisterModel,
    validationSchema: RegisterUserValidator,
    onSubmit: (values, { setSubmitting }) => {
      registerUser(values, setSubmitting);
    },
  });

  const registerUser = (model, setSubmitting) => {
    if (!captchaRef.current.getValue()) {
      setSubmitting(false);
      window.alert('Please complete the recaptcha before proceeding.');
    } else {
      setIsLoading(true);
      setSubmitting(true);
      userService.register({
        ...model,
        username: model.email,
        isActive: true,
        reCAPTCHAToken: captchaRef.current.getValue()
      })
        .then((res) => {
          captchaRef.current.reset();
          history.push('/login');
          showAlert({ text: res.message, state: 'success' });
        }).catch((ex) => {
          showAlert({ text: ex.message, state: 'error' });
        }).finally(() => {
          setIsLoading(false);
          setSubmitting(false);
        });
    }
  };
  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Register"
              />
              <FormBlock onSubmit={registerUserFormik.handleSubmit}>
                <Section hasNoContainer>
                  <ContentBlock cols={6}>
                    <InputBlock
                      label="First Name"
                      isRequired
                      errorMessage={registerUserFormik.errors.firstName}
                      inputState={`${helper.getInputClasses(registerUserFormik, 'firstName')}`}
                      {...registerUserFormik.getFieldProps('firstName')}
                    />
                  </ContentBlock>

                  <ContentBlock cols={6}>
                    <InputBlock
                      label="Last Name"
                      isRequired
                      errorMessage={registerUserFormik.errors.lastName}
                      inputState={`${helper.getInputClasses(registerUserFormik, 'lastName')}`}
                      {...registerUserFormik.getFieldProps('lastName')}
                    />
                  </ContentBlock>

                  <ContentBlock cols={6}>
                    <InputBlock
                      label="Email Address"
                      iconLeft={emailIcon}
                      isRequired
                      errorMessage={registerUserFormik.errors.email}
                      inputState={`${helper.getInputClasses(registerUserFormik, 'email')}`}
                      {...registerUserFormik.getFieldProps('email')}
                    />
                  </ContentBlock>

                  <ContentBlock cols={6}>
                    <InputBlock
                      mask="(999)-999-9999"
                      label="Phone"
                      errorMessage={registerUserFormik.errors.phone}
                      inputState={`${helper.getInputClasses(registerUserFormik, 'phone')}`}
                      {...registerUserFormik.getFieldProps('phone')}
                    />
                  </ContentBlock>

                  <ContentBlock cols={6}>
                    <InputBlock
                      type={isPasswordVisible ? 'text' : 'password'}
                      label="Password"
                      placeholder="Enter password"
                      isRequired
                      iconRight={isPasswordVisible ? <FiEye size={iconSize} className="dark-blue--clr" /> : <FiEyeOff size={iconSize} className="dark-blue--clr" />}
                      iconRightOnClick={() => {
                        setIsPasswordVisible(!isPasswordVisible);
                      }}
                      errorMessage={registerUserFormik.errors.password}
                      inputState={`${helper.getInputClasses(registerUserFormik, 'password')}`}
                      {...registerUserFormik.getFieldProps('password')}
                    />
                  </ContentBlock>
                  <ContentBlock cols={6}>
                    <InputBlock
                      type={isConfirmPasswordVisible ? 'text' : 'password'}
                      label="Confirm Password"
                      placeholder="Enter password"
                      isRequired
                      iconRight={isConfirmPasswordVisible ? <FiEye size={iconSize} className="dark-blue--clr" /> : <FiEyeOff size={iconSize} className="dark-blue--clr" />}
                      iconRightOnClick={() => {
                        setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
                      }}
                      errorMessage={registerUserFormik.errors.confirmPassword}
                      inputState={`${helper.getInputClasses(registerUserFormik, 'confirmPassword')}`}
                      {...registerUserFormik.getFieldProps('confirmPassword')}
                    />
                  </ContentBlock>
                  
                  <ContentBlock cols={6}>
                    <InputBlock
                      label="Registration Code"
                      // iconLeft={emailIcon}
                      isRequired
                      errorMessage={registerUserFormik.errors.registrationCode}
                      inputState={`${helper.getInputClasses(registerUserFormik, 'registrationCode')}`}
                      {...registerUserFormik.getFieldProps('registrationCode')}
                    />
                  </ContentBlock>

                  <ContentBlock cols={6}>
                    <ReCAPTCHA
                      ref={captchaRef}
                      sitekey={constants.SITE_KEY}
                    />
                  </ContentBlock>
                </Section>
               
                <CustomBlock className="button-container--flex-end">
                  
                  <Button
                    type="submit"
                    text="Register"
                    className="primary--bg"
                    isLoading={registerUserFormik.isSubmitting}
                    isDisabled={registerUserFormik.isSubmitting || !registerUserFormik.isValid}
                    onClick={registerUserFormik.handleSubmit}
                  />
                </CustomBlock>
              </FormBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...alert.actions,
    ...confirmModal.actions
  }
)(RegistrationPage);