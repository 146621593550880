// DEPENDENCIES
import React from 'react';
import { useHistory } from 'react-router-dom';
// COMPONENTS
import PropTypes from 'prop-types';
// ICONS
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';

const NavBlock = (props) => {
  const {
    title,
    titleColor,
    subtitle,
    subtitleColor,
    badgeText,
    badgeColor,
    type,
    iconLeft,
    iconRight,
    to,
    containerClassName,
    onClick,
    backgroundColor,
    tooltipText,
    tooltipTextColor,
    tooltipBackgroundColor,
    isCollapsed
  } = props;

  const history = useHistory();

  const navigateToPath = (path) => {
    history.push(path);
  };

  return (
    <CustomBlock className={`nav-block-container ${containerClassName}`}>
      <button
        type={type || 'button'}
        onClick={to ? () => navigateToPath(to) : onClick}
        className={`nav-block ${backgroundColor}`}
      >
        <CustomBlock className="content-block-container">
          {
            iconLeft
            && (
              <CustomBlock className="icon-left-container">
                {iconLeft}

                {
                  tooltipText && isCollapsed
                  && (
                    <CustomBlock className={`nav-tooltip ${tooltipBackgroundColor}`}>
                      <p className={tooltipTextColor}>{tooltipText}</p>
                    </CustomBlock>
                  )
                }
              </CustomBlock>
            )
          }
          <CustomBlock className="title-container">
            <p className={`title ${titleColor}`}>
              {title}
            </p>
            {subtitle && <p className={`subtitle ${subtitleColor}`}>{subtitle}</p>}
          </CustomBlock>

        </CustomBlock>
        {
          iconRight
          && (
            <CustomBlock className="nav-icon-container">
              {iconRight}
            </CustomBlock>
          )
        }

        {
          (badgeText && iconRight === null)
          && (
            <CustomBlock className="badge-container">
              <span className={`title-badge ${badgeColor}`}>{badgeText}</span>
            </CustomBlock>
          )
        }
      </button>
    </CustomBlock>
  );
};

// PROPS
NavBlock.propTypes = {
  // MAIN PROPS
  type: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleColor: PropTypes.string,
  badgeText: PropTypes.string,
  badgeColor: PropTypes.string,
  containerClassName: PropTypes.string,

  // ICON PROPS
  iconLeft: PropTypes.any,
  iconRight: PropTypes.any,

  // TOOLTIP PROPS
  tooltipText: PropTypes.string,
  tooltipTextColor: PropTypes.string,
  tooltipBackgroundColor: PropTypes.string,

  // APPEARANCE PROPS
  backgroundColor: PropTypes.string,

  // NAVIGATION PROPS
  to: PropTypes.string,

  // BOOLEAN PROPS
  isCollapsed: PropTypes.bool,

  // FUNCTION PROPS
  onClick: PropTypes.func,
};

// DEFAULT PROPS
NavBlock.defaultProps = {
  // MAIN PROPS
  title: '',
  titleColor: 'dark-blue--clr',
  subtitle: '',
  subtitleColor: 'dark--clr',
  badgeText: '',
  badgeColor: 'light-red--sbg',
  type: '',
  containerClassName: '',

  // ICON PROPS
  iconLeft: null,
  iconRight: null,

  // TOOLTIP PROPS
  tooltipText: '',
  tooltipTextColor: 'white--clr',
  tooltipBackgroundColor: 'dark-blue--sbg',

  // APPEARANCE PROPS
  backgroundColor: 'white--sbg',

  // NAVIGATION PROPS
  to: '',

  // BOOLEAN PROPS
  isCollapsed: false,

  // FUNCTION PROPS
  onClick: null,
};

export default NavBlock;