/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
// ICONS
// CUSTOM COMPONENTS
import Section from '../../../../../components/Section';
import ContentBlock from '../../../../../components/ContentBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import ContentHeader from '../../../../../components/ContentHeader';
import Button from '../../../../../components/Button';
import SelectBlock from '../../../../../components/SelectBlock';

import OverLay from '../../../../../components/Overlay';
// REDUX
import * as alert from '../../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../../redux/confirmModalRedux';
// SERVICES AND HELPERS
import * as wellPumpageService from '../../../../../services/wellPumpage/WellPumpageService';
import * as wellUseService from '../../../../../services/well-use/wellUseService';
import * as aquiferService from '../../../../../services/management/aquiferService';
import * as parishService from '../../../../../services/management/parishService';
import * as clientService from '../../../../../services/management/clientService';
import * as helper from '../../../../../helpers/helper';

const model = {
  startDate: new Date().getFullYear(),
  endDate: new Date().getFullYear(),
  aquiferId: null,
  wellUseId: null,
  clientId: null,
  parishId: null,
  wellNumber: null
};

const WellPumpageListingPage = (props) => {
  const { showAlert, auth, showConfirmModal, hideConfirmModal } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [toggleWellListing, setToggleWellListing] = useState(false);
  const [clients, setClients] = useState([]);
  const [aquifers, setAquifers] = useState([]);
  const [parishes, setParishes] = useState([]);
  const [selectedAquiferOptions, setSelectedAquiferOptions] = useState([]);
  const [selectedParishOptions, setSelectedParishOptions] = useState([]);
  const [selectedClientOption, setSelectedClientOption] = useState([]);
  const [selectedWellUseOption, setSelectedWellUseOption] = useState([]);
  const [wellPumpages, setWellPumpages] = useState([]);
  const [wellUses, setWellUses] = useState([]);
  const [currentTableData, setCurrentTableData] = useState([]);
  const [selectedStartDateOption, setSelectedStartDateOption] = useState( {
    label: new Date().getFullYear(),
    value: new Date().getFullYear()
  });
  const [selectedEndDateOption, setSelectedEndDateOption] = useState( {
    label: new Date().getFullYear(),
    value: new Date().getFullYear()
  });
  const [filterModel, setFilterModel] = useState({ ...model });
  const [tableKey, setTableKey] = useState(0);
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [count, setCount] = useState(0);
  const [tempStartDate, setTempStartDate] = useState('');
  const [tempEndDate, setTempEndDate] = useState('');

  useEffect(() => {
    getWellPumpages(page, rowsPerPage, filterModel);
  }, [toggleWellListing, page, rowsPerPage]);

  useEffect(() => {
    if (auth.accessLevel >= 20) {
      fetchClients();
      fetchAquifers();
      fetchParishes();
      fetchWellUses();
    }
  }, []);

  // const getWellPumpages = () => {
  //   setIsLoading(true);
  //   wellPumpageService.getAllWellPumpages(filterModel).then((res) => {
  //     setWellPumpages(res);
  //     setSelectedIndexes([]);
  //   }).catch((ex) => {
  //     showAlert({ text: ex.message, state: 'error' });
  //   }).finally(() => {
  //     setIsLoading(false);
  //   });
  // };

  const handleFilterChange = (column, filterList) => {
    const newFilterModel = { ...filterModel };
  
    if (column === 'date') {
      newFilterModel.startDateTime = filterList[4][0] || null;
      newFilterModel.endDateTime = filterList[4][1] || null;
    } else {
      filterList.forEach((filter, index) => {
        if (index === 0) {
          newFilterModel.wellNumber = filter[0] || null;
        }
      });
    }
  
    setFilterModel(newFilterModel);
    setPage(0);  // Reset to the first page
    getWellPumpages(0, rowsPerPage, newFilterModel);
  };

  const getWellPumpages = (page = 0, rowsPerPage = 25, filterModel = {}, sortOrder = {}) => {
    setIsLoading(true);
  
    const paginationParams = {
      page: page + 1,
      rowsPerPage
    };
  
    const sortParams = {
      sortBy: sortOrder.name || 'Date',
      sortDirection: sortOrder.direction || 'desc'
    };

    console.log(filterModel, paginationParams, sortParams);
  
    wellPumpageService.getAllWellPumpages(filterModel, paginationParams, sortParams)
      .then(res => {
        setWellPumpages(res.data);
        setCount(res.totalCount);
        setSelectedIndexes([]);
      })
      .catch(ex => {
        showAlert({ text: ex.message, state: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDeleteSelectedRows = (selectedRows) => {
    const idsToDelete = selectedRows.map(row => wellPumpages[row.dataIndex].id);

    showConfirmModal({
      title: 'Delete well pumpages',
      text: 'Are you sure you want to delete selected well pumpages?',
      rightBtnText: 'Confirm',
      btnAction: () => deleteSelectedWellPumpages(idsToDelete),
    });
  };

  const options = {
    filter: true,
    print: false,
    download: true,
    selectableRows: 'multiple',
    selectableRowsOnClick: true,
    selectableRowsHeader: true,
    serverSide: true,
    rowsPerPage: rowsPerPage,
    count: count,
    page: page,
    rowsPerPageOptions: [25, 50, 100],
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          setPage(tableState.page);
          getWellPumpages(tableState.page, tableState.rowsPerPage, filterModel, tableState.sortOrder);
          break;
        case 'changeRowsPerPage':
          setRowsPerPage(tableState.rowsPerPage);
          setPage(0);
          getWellPumpages(0, tableState.rowsPerPage, filterModel, tableState.sortOrder);
          break;
        case 'filterChange':
          handleFilterChange('', tableState.filterList);
        break;
        case 'sort':
          getWellPumpages(0, tableState.rowsPerPage, filterModel, tableState.sortOrder);
          break;
        default:
          break;
      }
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      // Check if there are selected rows
      if (selectedIndexes.length > 0) {
        // Filter data to include only selected rows
        const filteredData = data.filter((_, index) => selectedIndexes.includes(index));
        // Build CSV content from filtered data
        return `\uFEFF${buildHead(columns)}${buildBody(filteredData)}`;
      } else {
        // If no rows are selected, export all data as usual
        return `\uFEFF${buildHead(columns)}${buildBody(data)}`;
      }
    },

    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      setSelectedIndexes(rowsSelected);
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return (
        <div>
          <IconButton onClick={() => exportSelectedRows(selectedRows.data)}>
            <DownloadIcon />
          </IconButton>
          <IconButton onClick={() => handleDeleteSelectedRows(selectedRows.data)}>
            <DeleteIcon />
          </IconButton>
        </div>
      );
    },
    onRowsDelete: (rowsDeleted) => {
      return false;
    },
    rowsSelected: selectedIndexes,
  };

  const exportSelectedRows = (selectedRows) => {
    const idsToExport = selectedRows.map((row) => wellPumpages[row.dataIndex].id);
    const dataToExport = wellPumpages.filter(pumpage => idsToExport.includes(pumpage.id));
    
    const columnHeaders = ['id', 'wellNumber', 'client', 'parish', 'aquifer', 'Date', 'Time', 'pumpage'];

    const formattedData = dataToExport.map(item => ({
        ...item,
        Date: moment(item.date).format('YYYY-MM-DD'), // Extract the date part
        Time: moment(item.date).format('HH:mm:ss'), // Extract the time part
    }));

    const csvData = helper.convertToCSV(formattedData, columnHeaders);
    helper.downloadCSV(csvData, `WellPumpages_${moment().format('YYYY-MM-DD HH:mm:ss')}.csv`);
};


  const fetchClients = () => {
    clientService.getAllClients(true).then((res) => {
      const tempClients = res.map((x) => ({ label: x.name, value: x.id }));
      setClients(tempClients);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    });
  };

  const fetchWellUses = () => {
    wellUseService.getWellUses().then((res) => {
      const tempWellUses = res.map((x) => ({ label: `${x.description}`, value: x.id }));
      setWellUses(tempWellUses);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    });
  };

  const fetchAquifers = () => {
    aquiferService.getAquifers().then((res) => {
      const tempAquifers = res.map((x) => ({ label: x.name, value: x.id }));
      setAquifers(tempAquifers);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    });
  };

  const fetchParishes = () => {
    parishService.getParishes().then((res) => {
      const tempParishes = res.map((x) => ({ label: x.name, value: x.id }));
      setParishes(tempParishes);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    });
  };

  const resetFilters = () => {
    setFilterModel({ ...model, startDate: new Date().getFullYear(), endDate: new Date().getFullYear() });
    setSelectedAquiferOptions([]);
    setSelectedClientOption([]);
    setSelectedParishOptions([]);
    setSelectedWellUseOption([]);
    setSelectedStartDateOption({
      label: new Date().getFullYear(),
      value: new Date().getFullYear()
    });
    setSelectedEndDateOption({
      label: new Date().getFullYear(),
      value: new Date().getFullYear()
    });
    setPage(0);
    setToggleWellListing(!toggleWellListing);
  };

  const deleteSelectedWellPumpages = async (idsToDelete) => {
    setIsLoading(true);
    hideConfirmModal();
    await wellPumpageService.deleteWellPumpages(idsToDelete).then((res) => {
      getWellPumpages(page, rowsPerPage, filterModel, () => {
        showAlert({ text: res.message, state: 'success' });
      });
      showAlert({ text: res.message, state: 'success' });
    }).catch((err) => {
      showAlert({ text: err.message || 'An error occurred while deleting well pumpages.', state: 'error' });
    }).finally(() => {
      setIsLoading(false);
      setSelectedIndexes([]);
    });
  };

  const handleToggleSelectAll = async () => {
    if (selectedIndexes.length === wellPumpages.length) {
      // Deselect all
      setSelectedIndexes([]);
    } else {
      // Select all
      console.log('here is count', count);
      const allData = await fetchAllData(filterModel, count);
      const allIndexes = allData.map((_, index) => index);
      setWellPumpages(allData);
      console.log(allIndexes);
      setSelectedIndexes(allIndexes);
    }
  };

  const fetchAllData = async (filterModel, count) => {
    const pageSize = 100;
    const totalPages = Math.ceil(count / pageSize);
    let allData = [];
    setIsLoading(true);
    for (let page = 1; page <= totalPages; page++) {
      const response = await wellPumpageService.getAllWellPumpages(filterModel, { page, rowsPerPage: pageSize });
      allData = allData.concat(response.data);
    }
    setIsLoading(false); 
    return allData;
  };

  const handleUpload = async (uploadFile) => {
    if (!uploadFile) {
      showAlert({ text: 'Please select a file first.', state: 'error' });
      return;
    }
  
    await wellPumpageService.uploadWellPumpages(helper.convertJsonToFormData({ file: uploadFile }))
      .then((res) => {
        showAlert({ text: res, state: 'success' });
        getWellPumpages(page, rowsPerPage, filterModel);
      })
      .catch((err) => {
        showAlert({ text: err.message, state: 'error' });
      })
      .finally(() => {
        hideConfirmModal();
      });
  };

  const openUploadModal = () => {
    let uploadFile;
    showConfirmModal({
      title: 'Upload Module',
      text: (
        <>
          <div>
            This process will insert corrected well pumpage data. So, make sure to delete the incorrect items first.
          </div>
          <br />
          <input type="file" onChange={(event) => uploadFile = event.target.files[0]} style={{ marginBottom: '10px' }} />
        </>
      ),
      rightBtnText: 'Upload',
      btnAction: () => handleUpload(uploadFile),
      closeBtnAction: () => hideConfirmModal()
    });
  };

  const columnOptions = {
    filter: false,
    sort: true,
    print: false,
    download: true
  };

  const columns = [
    {
      name: 'wellNumber',
      label: 'Well Number',
      options: {
        ...columnOptions,
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => {
            if (value[0]) {
              return `Well Number: ${value[0]}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              return [];
            }
            return filterList[index];
          },
        },
        filterOptions: {
          names: [],
          logic(wellNumber, filters) {
            if (filters[0]) {
              const trimmedFilterVal = filters[0].trim();
              const trimmedWellNumber = wellNumber.trim();
              return !trimmedWellNumber.includes(trimmedFilterVal);
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <TextField
              variant="standard"
              placeholder="Well Number"
              value={filterList[index][0] || ''}
              onChange={(event) => {
                filterList[index][0] = event.target.value.trim();
                onChange(filterList[index], index, column);
                handleFilterChange('wellNumber', filterList);
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      filterList[index][0] = '';
                      onChange(filterList[index], index, column);
                      handleFilterChange('wellNumber', filterList);
                    }}
                    aria-label="clear filter"
                  >
                    <CloseIcon />
                  </IconButton>
                ),
              }}
              style={{ width: '100%' }}
              size="small"
            />
          ),
        },
      },
    },
    {
      name: 'client',
      label: 'Client',
      options: columnOptions,
    },
    {
      name: 'parish',
      label: 'Parish',
      options: {
        ...columnOptions,
        customBodyRender: (value) => value ?? '-'
      },
    },
    {
      name: 'aquifer',
      label: 'Aquifer',
      options: columnOptions,
    },
    {
      name: 'date',
      label: 'Date',
      options: {
        ...columnOptions,
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: (value) => {
            if (value[0] && value[1]) {
              return `Date: ${value[0]} - ${value[1]}`;
            } else if (value[0]) {
              return `Date: From ${value[0]}`;
            } else if (value[1]) {
              return `Date: Until ${value[1]}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              return [];
            }
            return filterList[index];
          },
        },
        filterOptions: {
          names: [],
          logic(date, filters) {
            const startDate = filters[0] ? moment(filters[0]) : null;
            const endDate = filters[1] ? moment(filters[1]) : null;
            const checkDate = moment(date);
    
            if (startDate && endDate) {
              return !(checkDate.isBetween(startDate, endDate, undefined, '[]'));
            } else if (startDate) {
              return !(checkDate.isSameOrAfter(startDate));
            } else if (endDate) {
              return !(checkDate.isSameOrBefore(endDate));
            }
    
            return false;
          },
          display: (filterList, onChange, index, column) => {
            let tempStartDate = filterList[index][0] || '';
            let tempEndDate = filterList[index][1] || '';
    
            const handleApplyFilter = () => {
              filterList[index][0] = tempStartDate;
              filterList[index][1] = tempEndDate;
              onChange(filterList[index], index, column);
    
              // Update the state with the new filter values
              setTempStartDate(tempStartDate);
              setTempEndDate(tempEndDate);
    
              // Apply the filters
              const newFilterModel = { ...filterModel };
              newFilterModel.startDateTime = tempStartDate;
              newFilterModel.endDateTime = tempEndDate;
              setFilterModel(newFilterModel);
              getWellPumpages(page, rowsPerPage, newFilterModel);
            };
    
            const handleClearFilter = () => {
              tempStartDate = '';
              tempEndDate = '';
              filterList[index][0] = '';
              filterList[index][1] = '';
              onChange(filterList[index], index, column);
    
              // Clear the state
              setTempStartDate('');
              setTempEndDate('');
    
              // Reset filters
              const newFilterModel = { ...filterModel };
              newFilterModel.startDateTime = null;
              newFilterModel.endDateTime = null;
              setFilterModel(newFilterModel);
              getWellPumpages(page, rowsPerPage, newFilterModel);
            };
    
            return (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TextField
                  variant="standard"
                  type="date"
                  label="From"
                  defaultValue={tempStartDate}
                  onChange={(event) => {
                    tempStartDate = event.target.value;
                  }}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  variant="standard"
                  type="date"
                  label="To"
                  defaultValue={tempEndDate}
                  onChange={(event) => {
                    tempEndDate = event.target.value;
                  }}
                  InputLabelProps={{ shrink: true }}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 8 }}>
                  <Button
                    text="Search"
                    className="primary--bg ml-0"
                    size="xs"
                    onClick={handleApplyFilter}
                  />
                  <IconButton
                    onClick={handleClearFilter}
                    aria-label="clear filter"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            );
          },
        },
        customBodyRender: (value) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
      },
    },
    {
      name: 'pumpage',
      label: 'Pumpage',
      options: {
        filter: true,
        sort: true,
        print: false,
        download: true,
        customBodyRenderLite: (dataIndex) => (
          <p>
            {wellPumpages[dataIndex].pumpage}
          </p>
        )
      }
    }
  ];

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              {
                auth.accessLevel >= 20 && (
                  <Section hasNoContainer>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                      <ContentHeader
                        title="Filters"
                        headerSize="md"
                      />
                      <Button
                        text="Upload Well Pumpages"
                        className="primary--bg ml-0"
                        size="xs"
                        onClick={openUploadModal}
                      />
                    </div>
                    <ContentBlock cols={4}>
                      <SelectBlock
                        label="Start Date"
                        placeholder="Select year"
                        options={helper.getYears()}
                        closeMenuOnSelect
                        isClearable
                        value={selectedStartDateOption}
                        onChange={(selectedOpt) => {
                          selectedOpt = selectedOpt === null ? null : selectedOpt;
                          setFilterModel({
                            ...filterModel,
                            startDate: (selectedOpt && selectedOpt.value) ?? null
                          });
                          setSelectedStartDateOption(selectedOpt);
                        }}
                      />
                    </ContentBlock>
                    <ContentBlock cols={4}>
                      <SelectBlock
                        label="End Date"
                        placeholder="Select year"
                        options={helper.getYears()}
                        closeMenuOnSelect
                        isClearable
                        value={selectedEndDateOption}
                        onChange={(selectedOpt) => {
                          selectedOpt = selectedOpt === null ? null : selectedOpt;
                          setFilterModel({
                            ...filterModel,
                            endDate: (selectedOpt && selectedOpt.value) ?? null
                          });
                          setSelectedEndDateOption(selectedOpt);
                        }}
                      />
                    </ContentBlock>
                    <ContentBlock cols={6}>
                      <SelectBlock
                        label="Client"
                        placeholder="Select client"
                        options={clients}
                        isRequired
                        isMulti
                        isClearable
                        closeMenuOnSelect={false}
                        value={selectedClientOption}
                        onChange={(selectedOpt) => {
                          selectedOpt = (selectedOpt) === null ? null : selectedOpt;
                          setFilterModel({
                            ...filterModel,
                            clientId: selectedOpt.map((x) => x.value) ?? ''
                          });
                          console.log(selectedOpt);
                          setSelectedClientOption(selectedOpt);
                        }}
                      />
                    </ContentBlock>

                    <ContentBlock cols={6}>
                      <SelectBlock
                        label="Aquifer"
                        placeholder="Select Aquifer"
                        options={aquifers}
                        isRequired
                        isMulti
                        isClearable
                        closeMenuOnSelect={false}
                        value={selectedAquiferOptions}
                        onChange={(selectedOpt) => {
                          selectedOpt = (selectedOpt) === null ? null : selectedOpt;
                          setFilterModel({
                            ...filterModel,
                            aquiferId: selectedOpt.map((x) => x.value) ?? ''
                          });
                          setSelectedAquiferOptions(selectedOpt);
                        }}
                      />
                    </ContentBlock>

                    <ContentBlock cols={6}>
                      <SelectBlock
                        label="Parishes"
                        placeholder="Select Parish"
                        options={parishes}
                        isRequired
                        isMulti
                        isClearable
                        closeMenuOnSelect={false}
                        value={selectedParishOptions}
                        onChange={(selectedOpt) => {
                          selectedOpt = selectedOpt === null ? null : selectedOpt;
                          setFilterModel({
                            ...filterModel,
                            parishId: selectedOpt.map((x) => x.value) ?? ''
                          });
                          setSelectedParishOptions(selectedOpt);
                        }}
                      />
                    </ContentBlock>
                  </Section>
                )
              }
              &nbsp;
              {
                auth.accessLevel >= 20 && (
                  <CustomBlock style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      text="Reset"
                      className="primary--bg ml-0"
                      size="xs"
                      onClick={resetFilters}
                    />
                    &nbsp;
                    <Button
                      text="Search"
                      className="primary--bg ml-0"
                      size="xs"
                      onClick={() => setToggleWellListing(!toggleWellListing)}
                    />
                  </CustomBlock>
                )
              }
              <CustomBlock>
                <ContentBlock className="content-container--actions flex-start">
                  <Button
                    text={selectedIndexes.length > 0 && selectedIndexes.length === wellPumpages.length ? 'Deselect All' : 'Select All'}
                    className="primary--bg"
                    onClick={handleToggleSelectAll}
                    size="xs"
                  />
                </ContentBlock>

                <MUIDataTable
                  key={tableKey}
                  data={wellPumpages}
                  columns={columns}
                  options={options}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...alert.actions,
    ...confirmModal.actions
  }
)(WellPumpageListingPage);