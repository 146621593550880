import * as Yup from 'yup';

const EditUserValidator = (props) => Yup.object().shape({
  firstName: Yup.string().required('First Name is required').max(100),
  lastName: Yup.string().required('Last Name is required').max(100),
  phone: Yup.string().nullable(),
  // pin: Yup.string().required('Pin is required').min(4).max(4),
  // roleIds: Yup.array().required('Please select role for the user'),
  email: Yup.string().email('Please enter valid email').required('Email is required').max(250),
  registrationCode: Yup.string().when('roleIds', {
    is: () => (props.selectedRole && props.selectedRole.label && (props.selectedRole.label === 'Admin' || props.selectedRole.label === 'Researcher')),
    then: Yup.string().nullable(),
    otherwise: Yup.string().required('Registration Code is required')
  }),
  clientId: Yup.string().when('roleIds', {
    is: () => (props.selectedRole && props.selectedRole.label && (props.selectedRole.label === 'Admin' || props.selectedRole.label === 'Researcher')),
    then: Yup.string().nullable(),
    otherwise: Yup.string().required('Client is required for User and Owner roles')
  }),
  isActive: Yup.bool().nullable(),
});

export default EditUserValidator;