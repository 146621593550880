/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// ICONS
import { RiBuildingLine } from 'react-icons/ri';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import Button from '../../../../components/Button';
import OverLay from '../../../../components/Overlay';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';
// SERVICES AND HELPERS
import * as wellUseService from '../../../../services/well-use/wellUseService';

const WellUseListingPage = (props) => {
  const { showAlert, showConfirmModal, hideConfirmModal } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [wellUses, setWellUses] = useState([]);

  useEffect(() => {
    getWellUses();
  }, []);

  const getWellUses = () => {
    setIsLoading(true);
    wellUseService.getWellUses().then((res) => {
      setWellUses(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const deleteWellUse = (id) => {
    setIsLoading(true);
    hideConfirmModal();
    wellUseService.deleteWellUse(id).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      getWellUses();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const iconSize = 22;
  const iconColor = 'white--clr';

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true
  };

  const options = {
    selectableRows: 'none',
    download: true,
    print: false,
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="View"
              className="primary--bg ml-0"
              size="xxs"
              to={`/well-use/edit/${wellUses[dataIndex].id}`}
            />
            <Button
              text="Delete"
              className="danger--bg"
              size="xxs"
              onClick={() => {
                showConfirmModal({
                  title: 'Delete WellUse',
                  text: 'Are you sure you want to delete this wellUse?',
                  rightBtnText: 'Confirm',
                  btnAction: () => {
                    deleteWellUse(wellUses[dataIndex].id);
                  }
                });
              }}
            />
          </CustomBlock>
        )
      }
    },
    {
      name: 'wellUseId',
      label: 'Well-Use ID',
      options: columnOptions,
    },
    {
      name: 'description',
      label: 'Description',
      options: columnOptions,
    },
    {
      name: 'category',
      label: 'Category',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <p>
            {wellUses[dataIndex].wellUseCategory.description}
          </p>
        )
      }
    },
    {
      name: 'classification',
      label: 'Classification',
      options: columnOptions,
    },
  ];

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Well-Uses"
                headerSize="lg"
                primaryButtonText="New Well-Use"
                primaryButtonIconLeft={<RiBuildingLine className={iconColor} size={iconSize} />}
                primaryButtonTo="/well-use"
              />

              <CustomBlock>
                <MUIDataTable
                  data={wellUses}
                  columns={columns}
                  options={options}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...alert.actions,
    ...confirmModal.actions
  }
)(WellUseListingPage);