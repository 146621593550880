// DEPENDENCIES
import React, {
  useState,
  useEffect,
  useRef
} from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
// COMPONENTS
// import ReCAPTCHA from 'react-google-recaptcha';
// ICONS
import {
  FiEye,
  FiEyeOff,
  FiMail
} from 'react-icons/fi';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import FormBlock from '../../../../components/FormBlock';
import ContentHeader from '../../../../components/ContentHeader';
import InputBlock from '../../../../components/InputBlock';
import Button from '../../../../components/Button';
import OverLay from '../../../../components/Overlay';
// HELPERS AND SERVICES
import * as userService from '../../../../services/management/userService';
import constants from '../../../../constants/constants';
import * as helper from '../../../../helpers/helper';
import RegisterUserValidator from '../../../../helpers/validators/management/user/RegisterUserValidator';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';

const userRegisterModel = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  roleIds: '',
  registrationCode: '',
  clientId: '',
  username: '',
  phone: '',
  password: '',
  reCAPTCHAToken: '',
  confirmPassword: '',
  isActive: true
};

const RegisterBlock = (props) => {
  const captchaRef = useRef(null);
  const { showAlert, handleIsRegisterBlockVisible } = props;
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [isSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const iconSize = 22;
  const inputIconColor = 'grey--clr';
  const emailIcon = <FiMail size={iconSize} className={inputIconColor} />;

  useEffect(() => {
  }, []);

  const registerUser = (model, setSubmitting) => {
    if (!captchaRef.current.getValue()) {
      setSubmitting(false);
      window.alert('Please complete the recaptcha before proceeding.');
    } else {
      setIsLoading(true);
      setSubmitting(true);
      userService.register({
        ...model,
        username: model.email,
        isActive: true,
        reCAPTCHAToken: captchaRef.current.getValue()
      })
        .then((res) => {
          captchaRef.current.reset();
          handleIsRegisterBlockVisible(false);
          showAlert({ text: res.message, state: 'success' });
        }).catch((ex) => {
          showAlert({ text: ex.message, state: 'error' });
        }).finally(() => {
          setIsLoading(false);
          setSubmitting(false);
        });
    }
  };

  const registerUserFormik = useFormik({
    initialValues: userRegisterModel,
    validationSchema: RegisterUserValidator,
    onSubmit: (values, { setSubmitting }) => {
      registerUser(values, setSubmitting);
    },
  });

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="flex-center">
        <FormBlock>
          <ContentHeader
            title="Register"
            headerSize="xl"
          />

          <Section hasNoContainer>
            <ContentBlock cols={6}>
              <InputBlock
                label="First Name"
                isRequired
                backgroundColor="white--sbg"
                errorMessage={registerUserFormik.errors.firstName}
                inputState={`${helper.getInputClasses(registerUserFormik, 'firstName')}`}
                {...registerUserFormik.getFieldProps('firstName')}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                label="Last Name"
                isRequired
                backgroundColor="white--sbg"
                errorMessage={registerUserFormik.errors.lastName}
                inputState={`${helper.getInputClasses(registerUserFormik, 'lastName')}`}
                {...registerUserFormik.getFieldProps('lastName')}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                label="Email Address"
                iconLeft={emailIcon}
                isRequired
                backgroundColor="white--sbg"
                errorMessage={registerUserFormik.errors.email}
                inputState={`${helper.getInputClasses(registerUserFormik, 'email')}`}
                {...registerUserFormik.getFieldProps('email')}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                mask="(999)-999-9999"
                label="Phone"
                backgroundColor="white--sbg"
                errorMessage={registerUserFormik.errors.phone}
                inputState={`${helper.getInputClasses(registerUserFormik, 'phone')}`}
                {...registerUserFormik.getFieldProps('phone')}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                type={isPasswordVisible ? 'text' : 'password'}
                label="Password"
                placeholder="Enter password"
                isRequired
                backgroundColor="white--sbg"
                iconRight={isPasswordVisible ? <FiEye size={iconSize} className="dark-blue--clr" /> : <FiEyeOff size={iconSize} className="dark-blue--clr" />}
                iconRightOnClick={() => {
                  setIsPasswordVisible(!isPasswordVisible);
                }}
                errorMessage={registerUserFormik.errors.password}
                inputState={`${helper.getInputClasses(registerUserFormik, 'password')}`}
                {...registerUserFormik.getFieldProps('password')}
              />
            </ContentBlock>
            <ContentBlock cols={6}>
              <InputBlock
                type={isConfirmPasswordVisible ? 'text' : 'password'}
                label="Confirm Password"
                placeholder="Enter password"
                isRequired
                backgroundColor="white--sbg"
                iconRight={isConfirmPasswordVisible ? <FiEye size={iconSize} className="dark-blue--clr" /> : <FiEyeOff size={iconSize} className="dark-blue--clr" />}
                iconRightOnClick={() => {
                  setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
                }}
                errorMessage={registerUserFormik.errors.confirmPassword}
                inputState={`${helper.getInputClasses(registerUserFormik, 'confirmPassword')}`}
                {...registerUserFormik.getFieldProps('confirmPassword')}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                label="Registration Code"
                // iconLeft={emailIcon}
                backgroundColor="white--sbg"
                isRequired
                errorMessage={registerUserFormik.errors.registrationCode}
                inputState={`${helper.getInputClasses(registerUserFormik, 'registrationCode')}`}
                {...registerUserFormik.getFieldProps('registrationCode')}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={constants.SITE_KEY}
              />
            </ContentBlock>
          </Section>

          <CustomBlock className="button-container flex--between">
            {/* <ReCAPTCHA /> */}

            <Button
              type="submit"
              text="Register"
              className={isSubmitting ? 'grey--sbg' : 'primary--bg'}
              isLoading={registerUserFormik.isSubmitting}
              isDisabled={registerUserFormik.isSubmitting || !registerUserFormik.isValid}
              onClick={registerUserFormik.handleSubmit}
            />
          </CustomBlock>

          <CustomBlock className="register-block">
            <button
              onClick={() => {
                handleIsRegisterBlockVisible(false);
              }}
            >
              Back to Login
            </button>
          </CustomBlock>
        </FormBlock>
      </CustomBlock>
    </>
  );
};
const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...alert.actions,
    ...confirmModal.actions
  }
)(RegisterBlock);