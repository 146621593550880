import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Users/';

const login = async (user) => axiosInstance.post(`${ENDPOINTORIGIN}Authenticate`, user, constants.REQUIRE_INTERCEPTORS);

const register = async (user) => axiosInstance.post(`${ENDPOINTORIGIN}Register`, user, constants.REQUIRE_INTERCEPTORS);

const addUser = async (user) => axiosInstance.post(`${ENDPOINTORIGIN}AddUser`, user, constants.REQUIRE_INTERCEPTORS);

const getUserById = async (clientId, userId) => axiosInstance.get(`${ENDPOINTORIGIN}GetUser?clientId=${clientId}&id=${userId}`, constants.REQUIRE_INTERCEPTORS);

const getLoggedInUser = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetLoggedInUser`, constants.REQUIRE_INTERCEPTORS);

const getAllUsers = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetAllUsers`, constants.REQUIRE_INTERCEPTORS);

const getAllUnAssignedUsers = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetAllUnAssignedUsers`, constants.REQUIRE_INTERCEPTORS);

const updateUser = async (user) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateUser`, user, constants.REQUIRE_INTERCEPTORS);

const changeUserPassword = async (userId, password) => axiosInstance.put(`${ENDPOINTORIGIN}ChangeUserPassword?userId=${userId}&password=${password}`, {}, constants.REQUIRE_INTERCEPTORS);

const changeSelfPassword = async (userId, password) => axiosInstance.put(`${ENDPOINTORIGIN}ChangeUserPassword?userId=${userId}&password=${password}`, {}, constants.REQUIRE_INTERCEPTORS);

const logOutTempUser = async () => axiosInstance.get(`${ENDPOINTORIGIN}LogOutTempUser`, constants.REQUIRE_INTERCEPTORS);

export {
  login,
  register,
  addUser,
  getUserById,
  getLoggedInUser,
  getAllUsers,
  getAllUnAssignedUsers,
  updateUser,
  changeUserPassword,
  changeSelfPassword,
  logOutTempUser
};