/* eslint-disable camelcase */
// DEPENDENCIES
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
// COMPONENTS
// ICONS
import { FiLock, FiUser } from 'react-icons/fi';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import FormBlock from '../../../components/FormBlock';
import ContentHeader from '../../../components/ContentHeader';
import InputBlock from '../../../components/InputBlock';
import Image from '../../../components/Image';
import Button from '../../../components/Button';
// PARTIALS
// import ForgotPasswordBlock from './_partials/ForgotPasswordBlock';
import RegisterBlock from './_partials/RegisterBlock';
// ASSETS
import Logo from '../../../assets/img/logo.png';
// SERVICES & HELPERS
import LoginValidator from '../../../helpers/validators/management/user/LoginValidator';
import * as helper from '../../../helpers/helper';
import * as userService from '../../../services/management/userService';
// REDUX
import * as authRedux from '../../../redux/authRedux';
import * as alertToast from '../../../redux/alertToastRedux';

const initialModel = {
  email: '',
  password: ''
};

const LoginPage = (props) => {
  const { showAlert, login, history } = props;
  const [passwordVisible, setPasswordVisible] = useState(false);
  // const [forgotPasswordBlockVisible, setForgotPasswordBlockVisible] = useState(false);
  const [isRegisterBlockVisible, setIsRegisterBlockVisible] = useState(false);

  const iconSize = 22;

  const loginUser = (model, setSubmitting) => {
    userService.login(model).then((res) => {
      login(res);
      if (res.role === 'Admin') {
        history.replace('/dashboard');
      } else if (res.role === 'User') {
        history.replace('/client/well/all-wells/');
      } else {
        history.replace('/client/dashboard');
      }
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setSubmitting(false);
    });
  };

  const handleIsRegisterBlockVisible = (val) => {
    setIsRegisterBlockVisible(val);
  };

  // const validateAccess = () => {
  //   if (auth.authToken !== '') {
  //     const decodedToken = jwt_decode(auth.authToken);
  //     if (decodedToken) {
  //       history.push('/management/users');
  //     }
  //   }
  // };

  // useEffect(() => {
  //   validateAccess();
  // }, []);

  const formik = useFormik({
    initialValues: initialModel,
    validationSchema: LoginValidator,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);

      loginUser(values, setSubmitting);
    },
  });

  return (
    <CustomBlock className="main-container">
      <CustomBlock className="authenticate-page login reverse">
        <CustomBlock className="input-section light-grey--sbg">
          {
            !isRegisterBlockVisible
              ? (
                <FormBlock onSubmit={formik.handleSubmit}>
                  <ContentHeader
                    title="Account Login"
                    headerSize="xl"
                  />

                  <InputBlock
                    id="email"
                    label="Email"
                    placeholder="Enter Email"
                    backgroundColor="white--sbg"
                    errorMessage={formik.errors.email}
                    inputState={`${helper.getInputClasses(formik, 'email')}`}
                    {...formik.getFieldProps('email')}
                    iconLeft={(
                      <FiUser
                        size={iconSize}
                        className="dark-grey--clr"
                      />
                    )}
                  />

                  <InputBlock
                    id="password"
                    type={passwordVisible ? 'text' : 'password'}
                    label="Password"
                    placeholder="Enter password"
                    backgroundColor="white--sbg"
                    iconLeft={(
                      <FiLock
                        size={iconSize}
                        className="dark-grey--clr"
                      />
                    )}
                    inputRightButtonText={passwordVisible ? 'Hide' : 'Show'}
                    inputRightButtonOnClick={() => setPasswordVisible(!passwordVisible)}
                    {...formik.getFieldProps('password')}
                    errorMessage={formik.errors.password}
                    inputState={`${helper.getInputClasses(formik, 'password')}`}
                    {...formik.getFieldProps('password')}
                  />

                  <CustomBlock className="button-container--flex-between">
                    <button
                      type="button"
                      onClick={(e) => {
                        handleIsRegisterBlockVisible(true);
                        e.preventDefault();
                      }}
                    >
                      Don&apos;t have an account? Register
                    </button>

                    <Button
                      type="submit"
                      text="Sign In"
                      className="primary--bg"
                      isLoading={formik.isSubmitting}
                      isDisabled={formik.isSubmitting || !formik.isValid}
                      onClick={formik.handleSubmit}
                    />
                  </CustomBlock>
                </FormBlock>
              )
              : (
                <RegisterBlock
                  props={props}
                  handleIsRegisterBlockVisible={handleIsRegisterBlockVisible}
                />
              )
          }
        </CustomBlock>
        <CustomBlock className="logo-section white--sbg">
          <CustomBlock className="description-block">
            <CustomBlock className="w-100 flex-center">
              <Image
                source={Logo}
                className="pb-20"
                containerClassName="w-100"
              />
            </CustomBlock>
          </CustomBlock>

          <CustomBlock className="footer-block">
            <p className="black--clr fw-300">
              Copyright © 2022 Capital Area Ground Water Conservation District. All rights reserved.
            </p>
          </CustomBlock>
        </CustomBlock>
      </CustomBlock>
    </CustomBlock>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...authRedux.actions,
  ...alertToast.actions
})(LoginPage);