/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */

// DEPENDENCIES
import React, {
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import jwt_decode from 'jwt-decode';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { enableBodyScroll } from 'body-scroll-lock';
// ROUTES
import PrivateRoute from './routes/PrivateRoute';
import ClientRoute from './routes/ClientRoute';
// CUSTOM COMPONENTS
import ConfirmModal from './components/ConfirmModal';
import AlertToast from './components/AlertToast';
// AUTHENTICATION
import LoginPage from './pages/admin/authentication/LoginPage';
import RegistrationPage from './pages/admin/authentication/RegistrationPage';
// MANAGEMENT PAGES - SYSTEM
import UserListingPage from './pages/admin/management/system/user/UserListingPage';
import UserProfilePage from './pages/admin/management/system/user/UserProfilePage';
// PROJECT
// -- WELL
import WellListingPage from './pages/admin/project/well/WellListingPage';
import InvoiceListingPage from './pages/admin/project/invoice/InvoiceListingPage';
import WellManagementPage from './pages/admin/project/well/WellManagementPage';
// -- CLIENT
import ClientListingPage from './pages/admin/project/management/client/ClientListingPage';
import ClientManagementPage from './pages/admin/project/management/client/ClientManagementPage';
import AquiferListingPage from './pages/admin/project/management/aquifer/AquiferListingPage';
import AquiferManagementPage from './pages/admin/project/management/aquifer/AquiferManagementPage';
import ParishListingPage from './pages/admin/project/management/parish/ParishListingPage';
import ParishManagementPage from './pages/admin/project/management/parish/ParishManagementPage';
import WellUseCategoryListingPage from './pages/admin/project/well-use/WellUseCategoryListingPage';
import WellUseCategoryManagementPage from './pages/admin/project/well-use/WellUseCategoryManagementPage';
import WellUseListingPage from './pages/admin/project/well-use/WellUseListingPage';
import WellUseManagementPage from './pages/admin/project/well-use/WellUseManagementPage';
import WellPumpageListingPage from './pages/admin/project/management/wellPumpage/WellPumpageListingPage';
import UnpaidInvoicePage from './pages/admin/project/client/payment/UnpaidInvoicePage';
import ComplianceReport from './pages/admin/project/management/report/ComplianceReport';
import VolumesByPOA from './pages/admin/project/management/report/VolumesByPOA';
import VolumesByWellUse from './pages/admin/project/management/report/VolumesByWellUse';
// CLIENT ROUTE
import DashboardPage from './pages/admin/project/client/DashboardPage';
// CLIENT WELL
import WellDataPage from './pages/admin/project/client/well/WellDataPage';
// import WellsPage from './pages/admin/project/client/well/WellsPage';
import WellUsageReportPage from './pages/admin/project/client/well/WellUsageReportPage';
// PAYMENTS AND INVOICES
import MakePaymentPage from './pages/admin/project/client/payment/MakePaymentPage';
import InvoicesPage from './pages/admin/project/client/payment/InvoicesPage';
// -- COMPONENTS
// -- MENU ITEMS
// REDUX
import * as alert from './redux/alertToastRedux';
import PaymentConfirmationPage from './pages/admin/project/client/payment/PaymentConfirmationPage';

const Routes = (props) => {
  const { auth } = props;
  const [isAuthorized, setIsAuthorized] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    const body = document.querySelector('body');
    window.scrollTo(0, 0);
    enableBodyScroll(body);
  }, [pathname]);

  const validateAccess = () => {
    if (auth.authToken !== '') {
      try {
        const decodedToken = jwt_decode(auth.authToken);
        setIsAuthorized(decodedToken);
      } catch (ex) {
        setIsAuthorized(false);
      }
    } else {
      setIsAuthorized(false);
    }
  };

  useEffect(() => {
    validateAccess();
  }, [auth]);

  return (
    <>
      <AlertToast />
      <ConfirmModal />

      <Switch>
        <Redirect
          exact
          from="/"
          to={auth.accessLevel < 20 ? '/client/well/all-wells/' : (auth.accessLevel > 40 ? '/dashboard' : '/client/dashboard')}
        />
        <Route
          path="/login"
          component={LoginPage}
        />
        <Route
          path="/register"
          component={RegistrationPage}
        />

        {
          isAuthorized
            ? (
              <>
                <PrivateRoute
                  exact
                  path="/dashboard"
                  component={DashboardPage}
                  title="Dashboard"
                  {...props}
                />

                <PrivateRoute
                  exact
                  path="/payment/make-payment/:invoiceId/:clientId"
                  component={MakePaymentPage}
                  title="Make Payment"
                  {...props}
                />

                <PrivateRoute
                  exact
                  path="/invoices"
                  component={InvoiceListingPage}
                  title="Invoices"
                  {...props}
                />

                <PrivateRoute
                  exact
                  path="/payment/make-payment"
                  component={UnpaidInvoicePage}
                  title="Make Payment"
                  {...props}
                />

                <PrivateRoute
                  exact
                  path="/payment/view-invoice/:invoiceId"
                  component={MakePaymentPage}
                  title="Payment Invoice"
                  {...props}
                />

                <PrivateRoute
                  exact
                  path="/payment/invoices"
                  component={InvoicesPage}
                  title="Invoices"
                  {...props}
                />

                <PrivateRoute
                  exact
                  path="/payment/invoice-confirmation/:clientId/:invoiceId/:paymentIntentId"
                  component={PaymentConfirmationPage}
                  title="Payment Confirmation"
                  {...props}
                />
                {/* MANAGEMENT PAGES - SYSTEM */}
                <PrivateRoute
                  path="/management/users"
                  component={UserListingPage}
                  title="Manage Users"
                />

                <PrivateRoute
                  path="/my-profile"
                  component={UserProfilePage}
                  title="My Profile"
                />

                <PrivateRoute
                  exact
                  path="/well/well-usage-report"
                  component={WellUsageReportPage}
                  title="Well Usage"
                  {...props}
                />

                <PrivateRoute
                  path="/wells"
                  component={WellListingPage}
                  title="All Wells"
                />

                <PrivateRoute
                  exact
                  path="/well/create"
                  component={WellManagementPage}
                  title="Well Details"
                />
                <PrivateRoute
                  path="/well/edit/:wellId"
                  component={WellManagementPage}
                  title="Well Details"
                />
                {/* CLIENT MANAGEMENT */}
                <PrivateRoute
                  path="/management/clients"
                  component={ClientListingPage}
                  title="Manage Clients"
                />

                <PrivateRoute
                  exact
                  path="/management/client"
                  component={ClientManagementPage}
                  title="Add Client"
                />

                <PrivateRoute
                  exact
                  path="/management/client/edit/:clientId"
                  component={ClientManagementPage}
                  title="Manage Client"
                />

                <PrivateRoute
                  path="/management/aquifers"
                  component={AquiferListingPage}
                  title="Manage Aquifers"
                />

                <PrivateRoute
                  exact
                  path="/management/aquifer"
                  component={AquiferManagementPage}
                  title="Add Aquifer"
                />

                <PrivateRoute
                  exact
                  path="/management/aquifer/edit/:id"
                  component={AquiferManagementPage}
                  title="Manage Aquifer"
                />

                <PrivateRoute
                  path="/well-usage-categories"
                  component={WellUseCategoryListingPage}
                  title="Manage Well Usage Categories"
                />

                <PrivateRoute
                  exact
                  path="/well-use-category"
                  component={WellUseCategoryManagementPage}
                  title="Add Well Usage Categories"
                />

                <PrivateRoute
                  exact
                  path="/well-use-category/edit/:id"
                  component={WellUseCategoryManagementPage}
                  title="Manage Well Use Categories"
                />

                <PrivateRoute
                  exact
                  path="/well-pumpages"
                  component={WellPumpageListingPage}
                  title="Manage Well Pumpage"
                />

                <PrivateRoute
                  path="/well-usage"
                  component={WellUseListingPage}
                  title="Manage Well Usage"
                />

                <PrivateRoute
                  exact
                  path="/well-use"
                  component={WellUseManagementPage}
                  title="Add Well Usage"
                />

                <PrivateRoute
                  exact
                  path="/well-use/edit/:id"
                  component={WellUseManagementPage}
                  title="Manage Well Usage"
                />

                <PrivateRoute
                  path="/well/water-data"
                  component={WellDataPage}
                  title="Water Data"
                  {...props}
                />

                <PrivateRoute
                  path="/management/parishes"
                  component={ParishListingPage}
                  title="Manage Parishes"
                />

                <PrivateRoute
                  exact
                  path="/reports"
                  component={WellUsageReportPage}
                  title="Report"
                  {...props}
                />

                <PrivateRoute
                  exact
                  path="/reports/compliance"
                  component={ComplianceReport}
                  title="Compliance Report"
                />

                <PrivateRoute
                  exact
                  path="/reports/volumesByPOA"
                  component={VolumesByPOA}
                  title="Volumes by Parish, Owner & Aquifer Report"
                />

                <PrivateRoute
                  exact
                  path="/reports/volumesByUse"
                  component={VolumesByWellUse}
                  title="Volumes by Well Use Report"
                />

                <PrivateRoute
                  exact
                  path="/management/parish"
                  component={ParishManagementPage}
                  title="Add Parish"
                />

                <PrivateRoute
                  exact
                  path="/management/parish/edit/:id"
                  component={ParishManagementPage}
                  title="Manage Parish"
                />

                {/* CLIENT ROUTE */}
                <ClientRoute
                  exact
                  path="/client/dashboard"
                  component={DashboardPage}
                  title="Dashboard"
                  {...props}
                />

                <ClientRoute
                  path="/well/client-edit/:wellId"
                  component={WellManagementPage}
                  title="Well Details"
                />

                <ClientRoute
                  exact
                  path="/client/well/all-wells"
                  component={WellListingPage}
                  title="Wells"
                  {...props}
                />

                <ClientRoute
                  exact
                  path="/client/well/water-data"
                  component={WellDataPage}
                  title="Water Data"
                  {...props}
                />

                <ClientRoute
                  exact
                  path="/client/well/well-usage-report"
                  component={WellUsageReportPage}
                  title="Well Usage"
                  {...props}
                />

                <ClientRoute
                  path="/client/users"
                  component={UserListingPage}
                  title="Manage Users"
                />

                <ClientRoute
                  exact
                  path="/client/edit/:clientId"
                  component={ClientManagementPage}
                  title="Manage Organization Profile"
                />

                <ClientRoute
                  path="/client/my-profile"
                  component={UserProfilePage}
                  title="My Profile"
                />

                {/* PAYMENTS AND INVOICES */}
                <ClientRoute
                  exact
                  path="/client/payment/make-payment/:invoiceId"
                  component={MakePaymentPage}
                  title="Make Payment"
                  {...props}
                />

                <ClientRoute
                  exact
                  path="/client/payment/make-payment"
                  component={UnpaidInvoicePage}
                  title="Make Payment"
                  {...props}
                />

                <ClientRoute
                  exact
                  path="/client/payment/view-invoice/:invoiceId"
                  component={MakePaymentPage}
                  title="Payment Invoice"
                  {...props}
                />

                <ClientRoute
                  exact
                  path="/client/payment/invoices"
                  component={InvoicesPage}
                  title="Invoices"
                  {...props}
                />

                <ClientRoute
                  exact
                  path="/client/payment/invoice-confirmation/:clientId/:invoiceId/:paymentIntentId"
                  component={PaymentConfirmationPage}
                  title="Payment Confirmation"
                  {...props}
                />
              </>
            )
            : <Redirect to="/login" />
        }
      </Switch>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...alert.actions })(Routes);