/* eslint-disable react/no-unstable-nested-components */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
// import Box from '@mui/material/Box';
// import { DataGrid } from '@mui/x-data-grid';
// ICONS
// CUSTOM COMPONENTS
import Section from '../../../../../components/Section';
import Button from '../../../../../components/Button';
import ContentBlock from '../../../../../components/ContentBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import ContentHeader from '../../../../../components/ContentHeader';

const reports = [
  {
    id: 1,
    name: 'Compliance Report',
    description: 'Compliance Report',
    path: '/reports/compliance'
  },
  {
    id: 1,
    name: 'Volume by Parish, Owner & Aquifer Report',
    description: 'Volume by Parish, Owner & Aquifer Report',
    path: '/reports/volumesByPOA'
  },
  {
    id: 1,
    name: 'Volume by WellUse Report',
    description: 'Volume by WellUse Report',
    path: '/reports/volumesByUse'
  }
];

const WellUsageReportPage = () => {
  const [isLoading] = useState(false);
  const [reportTypes] = useState([...reports]);

  useEffect(() => {
  }, []);

  const columnOptions = {
    filter: true,
    sort: true,
    print: true,
    download: true
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: true,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="View"
              className="primary--bg ml-0"
              size="xxs"
              to={reportTypes[dataIndex].path}
            />
          </CustomBlock>
        )
      }
    },
    {
      name: 'name',
      label: 'Name',
      options: columnOptions,
      width: 1000
    },
    {
      name: 'description',
      label: 'Description',
      options: columnOptions,
    },
  ];

  return (
    <CustomBlock className="content-container--padded">
      <Section isFullWidth>
        <ContentBlock>
          <CustomBlock className="content-container--card-style--with-shadow">
            <ContentHeader
              title="Reports"
              headerSize="lg"
            />
            <Section hasNoContainer>
              <ContentBlock>
                {
                  isLoading && <p>Loading...</p>
                }
                <CustomBlock>
                  <MUIDataTable
                    data={reportTypes}
                    columns={columns}
                    options={{
                      selectableRows: 'none',
                      download: true,
                      print: false,
                    }}
                  />
                </CustomBlock>
              </ContentBlock>
            </Section>
          </CustomBlock>
        </ContentBlock>
      </Section>
    </CustomBlock>
  );
};

export default WellUsageReportPage;