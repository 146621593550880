/* eslint-disable import/prefer-default-export */

import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Roles/';

const getAllRoles = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetAllRoles`, constants.REQUIRE_INTERCEPTORS);

export {
  getAllRoles
};