/* eslint-disable */
// DEPENDENCIES
import React, {
  useState,
  useEffect,
  useRef
} from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Chart } from 'react-google-charts';
import moment from 'moment';
// COMPONENTS
// import L from 'leaflet';
import 'leaflet.markercluster';
import * as turf from '@turf/turf';
// import DatePicker from 'react-datepicker';
// ICONS
import { BsFillCheckCircleFill, BsExclamationCircleFill } from 'react-icons/bs';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import NavBlock from '../../../../components/NavBlock';
import ModalBlock from '../../../../components/ModalBlock';
import SelectBlock from '../../../../components/SelectBlock';
import CheckBoxBlock from '../../../../components/CheckboxBlock';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
// HELPERS AND SERVICES
import constants from '../../../../constants/constants';
import * as helper from '../../../../helpers/helper';
import * as wellService from '../../../../services/well/wellService';
import * as wellPumpageService from '../../../../services/wellPumpage/WellPumpageService';
import * as invoiceService from '../../../../services/payment/invoiceService';
import * as wellUseService from '../../../../services/well-use/wellUseService';
import * as aquiferService from '../../../../services/management/aquiferService';
import * as parishService from '../../../../services/management/parishService';
import * as clientService from '../../../../services/management/clientService';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as authRedux from '../../../../redux/authRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';
import Overlay from '../../../../components/Overlay';

const initialWellChartState = [
  ['Month', 'Pumpage']
];

const coordinates = [
  [30.612844, -91.202003],
  [30.610967, -91.18586],
  [30.61223, -91.181331],
  [30.61223, -91.167561],
  [30.61223, -91.116757],
  [30.583551, -91.126035],
  [30.541803, -91.136437],
  [30.540469, -91.136728],
  [30.534715, -91.140649],
  [30.499725, -91.153188],
  [30.473351, -91.162377],
  [30.472623, -91.183681],
  [30.472623, -91.196689],
  [30.513265, -91.198415],
  [30.517948, -91.200435],
  [30.520638, -91.204329],
  [30.522093, -91.208259],
  [30.521745, -91.21274],
  [30.504214, -91.266992],
  [30.50317, -91.276395],
  [30.504752, -91.279937],
  [30.509646, -91.282503],
  [30.515172, -91.279571],
  [30.519750, -91.275173],
  [30.527727, -91.257141],
  [30.532223, -91.247591],
  [30.538691, -91.242371],
  [30.546424, -91.239256],
  [30.553368, -91.240905],
  [30.559207, -91.24512],
  [30.569778, -91.26033],
  [30.587921, -91.271141],
  [30.595808, -91.277555],
  [30.607409, -91.297762],
  [30.615313, -91.295958],
  [30.613486, -91.237558],
  [30.612828, -91.227049],
  [30.612835, -91.20201],
  [30.610967, -91.18586],
  [30.612844, -91.202003]
];

const model = {
  aquiferId: '',
  wellUseId: '',
  clientId: '',
  parishId: ''
};

const chartModel = {
  aquiferId: null,
  wellUseId: null,
  clientId: null,
  parishId: null,
  startDate: null,
  endDate: null
};

const DashboardPage = (props) => {
  const history = useHistory();
  const { showAlert, auth } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [wellUses, setWellUses] = useState([]);
  const [clients, setClients] = useState([]);
  const [aquifers, setAquifers] = useState([]);
  const [parishes, setParishes] = useState([]);
  const [selectedAquiferOptions, setSelectedAquiferOptions] = useState([]);
  const [selectedParishOptions, setSelectedParishOptions] = useState([]);
  const [selectedClientOption, setSelectedClientOption] = useState([]);
  const [selectedWellUseOption, setSelectedWellUseOption] = useState([]);
  const [filterString, setFilterString] = useState(null);
  const [filterStringChart, setFilterStringChart] = useState(null);
  const [selectedAquiferOptionsChart, setSelectedAquiferOptionsChart] = useState([]);
  const [selectedParishOptionsChart, setSelectedParishOptionsChart] = useState([]);
  const [selectedClientOptionChart, setSelectedClientOptionChart] = useState([]);
  const [selectedWellUseOptionChart, setSelectedWellUseOptionChart] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleChart, setIsModalVisibleChart] = useState(false);
  const [filterModel, setFilterModel] = useState({ ...model });
  const [filterModelChart, setFilterModelChart] = useState({ ...chartModel });
  const [map, setMap] = useState(null);
  const [toggleRefreshMap, setToggleRefreshMap] = useState(false);
  const [toggleRefreshChart, setToggleRefreshChart] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [filterIndustrialDistrict, setFilterIndustrialDistrict] = useState(false);
  const [industrialDistrictPolygon, setIndustrialDistrictPolygon] = useState(null);
  const [wellPumpages, setWellPumpages] = useState([]);
  const [wellChartData, setWellChartData] = useState(
    [...initialWellChartState]
  );
  const [dailyFilterModel, setDailyFilterModel] = useState({
    clientId: [],
    startDate: new Date().getFullYear(),
    endDate: new Date().getFullYear(),
    quarter: [],
    aquiferId: [],
    parishId: []
  });
  const [activeTab, setActiveTab] = useState('0');
  const [invoices, setInvoices] = useState([]);
  const [options, setOptions] = useState({
    title: 'Well Pumpage Over Years',
    hAxis: {
      title: 'Month',
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0,
        max: 12
      },
      gridlines: { count: -1 },
      format: 'MMM'
    },
    vAxis: {
      title: 'Pumpage (million gallons)',
      viewWindowMode: 'explicit',
      viewWindow: {
        min: 0
      }
    },
    series: {
      0: { color: '#1b9e77', pointSize: 5 },
      1: { color: '#d95f02', pointSize: 5 },
      2: { color: '#7570b3', pointSize: 5 },
      3: { color: '#e7298a', pointSize: 5 }
    },
    chartArea: { width: '80%', height: '70%' },
    legend: { position: 'bottom' },
    explorer: {
      actions: ['dragToZoom', 'rightClickToReset'],
      axis: 'horizontal',
      keepInBounds: true,
      maxZoomIn: .1
    }
  });

  const mapRef = useRef();

  useEffect(() => {
    fetchClients();
    fetchAquifers();
    fetchParishes();
    fetchWellUses();
    getDailyPumpageData();
    if (auth.accessLevel > 20) {
      fetchClientInvoice();
    }
  }, []);

  useEffect(() => {
    getAllWells();
  }, [toggleRefreshMap]);

  useEffect(() => {
    getAllWellPumpageChartData();
  }, [toggleRefreshChart]);

  const getAllWells = () => {
    setIsLoading(true);
    wellService.getWellsForDashboard(filterModel).then((res) => {
      let filteredPlots = res;

      if (filterIndustrialDistrict) {
        filteredPlots = res.filter((plot) => {
          if (!plot.latitude || !plot.longitude) {
            return false;
          }
          const lat = helper.extractDMSValues(plot.latitude);
          const lon = helper.extractDMSValues(plot.longitude);
          if (!lat || !lon) {
            return false;
          }
          return isPointInPolygon(helper.convertDMStoDecimal(helper.extractDMSValues(plot.latitude)), helper.convertDMStoDecimal(helper.extractDMSValues(plot.longitude)), industrialDistrictPolygon);
        });
      }

      createMap(filteredPlots);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
      setIsModalVisible(false);
    });
  };

  const getDailyPumpageData = () => {
    setIsLoading(true);
    wellPumpageService.getDailyWellPumpages({
      ...dailyFilterModel,
      getAll: true
    })
      .then((res) => {
        setWellPumpages(res.data);
      })
      .catch((ex) => {
        showAlert({ text: ex.message, state: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getAllWellPumpageChartData = () => {
    setIsLoading(true);

    wellPumpageService.getPumpageVolumesForChart(filterModelChart).then((data) => {
      const currentYear = new Date().getFullYear();
      const startYear = currentYear - 2;
      const endYear = currentYear; // Can be adjusted if needed

      // Initialize chart data with headers
      const chartData = [['Month', ...Array.from({ length: endYear - startYear + 1 }, (_, i) => (startYear + i).toString())]];

      // Initialize data structure to hold monthly values by year
      const dataByYear = {};
      data.forEach((item) => {
        const [year, monthIndex] = item.yearMonth.split('-');
        const yearNum = parseInt(year, 10);
        const month = new Date(0, parseInt(monthIndex, 10) - 1).toLocaleString('default', { month: 'short' });

        if (yearNum >= startYear && yearNum <= endYear) {
          if (!dataByYear[yearNum]) dataByYear[yearNum] = {};
          dataByYear[yearNum][month] = item.pumpage;
        }
      });

      // Convert grouped data to chart format
      const months = Array.from({ length: 12 }, (_, i) => new Date(0, i).toLocaleString('default', { month: 'short' }));
      months.forEach(month => {
        const row = [month];
        for (let year = startYear; year <= endYear; year++) {
          row.push(dataByYear[year] && dataByYear[year][month] ? dataByYear[year][month] : 0);
        }
        chartData.push(row);
      });

      setWellChartData(chartData);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
      setWellChartData([]);
    }).finally(() => {
      setIsLoading(false);
      setIsModalVisibleChart(false);
    });
  };


  const fetchClients = () => {
    clientService.getAllClients(true, auth.clientIds ? auth.clientIds : 'Admin').then((res) => {
      const tempClients = res.map((x) => ({ label: x.name, value: x.id }));
      setClients(tempClients);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    });
  };

  const fetchWellUses = () => {
    wellUseService.getWellUses().then((res) => {
      const tempWellUses = res.map((x) => ({ label: `${x.description}`, value: x.id }));
      setWellUses(tempWellUses);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    });
  };

  const fetchAquifers = () => {
    aquiferService.getAquifers().then((res) => {
      const tempAquifers = res.map((x) => ({ label: x.name, value: x.id }));
      setAquifers(tempAquifers);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    });
  };

  const fetchParishes = () => {
    parishService.getParishes().then((res) => {
      const tempParishes = res.map((x) => ({ label: x.name, value: x.id }));
      setParishes(tempParishes);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    });
  };

  const fetchClientInvoice = () => {
    invoiceService.getCurrentYearInvoices(auth.clientIds).then((res) => {
      setInvoices(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const removeMarkers = () => {
    markers.forEach((marker) => {
      marker.remove();
    });
  };

  const isPointInPolygon = (lat, lng, polygon) => {
    const point = turf.point([lng, lat]);
    const leafletCoordinates = polygon.getLatLngs()[0].map((p) => [p.lng, p.lat]);
    console.log('Leaflet coordinates for Turf.js:', leafletCoordinates);

    if (leafletCoordinates[0][0] !== leafletCoordinates[leafletCoordinates.length - 1][0] || leafletCoordinates[0][1] !== leafletCoordinates[leafletCoordinates.length - 1][1]) {
      leafletCoordinates.push(leafletCoordinates[0]);
    }

    try {
      const poly = turf.polygon([leafletCoordinates]);
      console.log('Turf.js polygon:', poly);
      return turf.booleanPointInPolygon(point, poly);
    } catch (error) {
      console.error('Error creating Turf.js polygon:', error);
    }
  };

  const createMap = (plots) => {
    try {
      removeMarkers();
      const tempMap = (!map) ? initializeMap() : map;

      const districtPolygon = window.L.polygon(coordinates).addTo(tempMap);
      setIndustrialDistrictPolygon(districtPolygon);

      districtPolygon.setStyle({
        fillColor: 'blue',
        fillOpacity: 0.2,
        color: 'blue',
        opacity: 0.5
      });

      districtPolygon.bindPopup('Industrial District Information').openPopup();

      districtPolygon.on('click', () => {
        // Handle district selection or other actions here
      });

      const markerArr = [];
      console.log(plots);
      plots.forEach((plot) => {
        if (!plot.latitude || !plot.longitude) {
          return;
        }
        const lat = helper.extractDMSValues(plot.latitude);
        const lon = helper.extractDMSValues(plot.longitude);

        if (!lat || !lon) {
          return;
        }
        const marker = window.L
          .marker([helper.convertDMStoDecimal(lat), helper.convertDMStoDecimal(lon)], { icon: window.L.mapquest.icons.marker() })
          .addTo(tempMap)
          .bindPopup(getFormattedWellInfoValuesToDisplay(plot), {
            maxWidth: 200,
            minWidth: 100,
            closeButton: true,
            autoClose: true
          });

        marker.on('mouseover', () => {
          marker.openPopup();
        });

        marker.on('mouseout', () => {
          marker.closePopup();
        });

        marker.on('click', () => {
          const newTab = window.open(`/well/edit/${plot.id}`, '_blank');
          newTab.focus();
        });

        markerArr.push(marker);
      });
      const bounds = new window.L.LatLngBounds();
      markerArr.forEach((marker) => {
        marker.addTo(tempMap);
        bounds.extend(marker.getLatLng());
      });
      if (Object.keys(bounds).length !== 0) {
        tempMap.fitBounds(bounds);
      }
      setMarkers(markerArr);
    } catch (ex) {
      console.log('Ex is ', ex);
    }
  };

  const initializeMap = () => {
    const { L } = window;
    L.mapquest.key = constants.MAPQUEST_KEY;
    const temp = new window.L.Map(mapRef.current, {
      center: [30.3886803239576, -91.04900000150919],
      zoom: 10,
      layers: window.L.mapquest.tileLayer('map'),
    });
    setMap(temp);

    return temp;
  };

  const resetFilters = () => {
    setFilterModel({ ...model });
    setSelectedAquiferOptions([]);
    setSelectedClientOption([]);
    setSelectedParishOptions([]);
    setSelectedWellUseOption([]);
    setToggleRefreshMap(!toggleRefreshMap);
    setIndustrialDistrictPolygon(null);
    setFilterIndustrialDistrict(false);
    setFilterString(null);
  };

  const resetFiltersChart = () => {
    setFilterModelChart({ ...chartModel });
    setSelectedAquiferOptionsChart([]);
    setSelectedClientOptionChart([]);
    setSelectedParishOptionsChart([]);
    setSelectedWellUseOptionChart([]);
    setStartDate(null);
    setEndDate(null);
    setFilterStringChart(null);
    setToggleRefreshChart(!toggleRefreshChart);
  };

  // const handleChangeStartDate = (date) => {
  //   setStartDate(date ?? null);
  //   setFilterModelChart({ ...filterModelChart, startDate: date[0] });
  // };

  // const handleChangeEndDate = (date) => {
  //   setEndDate(date ?? null);
  //   setFilterModelChart({ ...filterModelChart, endDate: date[0] });
  // };

  const selectedFiltersString = () => {
    const clientsOptions = selectedClientOption.length > 0 ? (
      <strong style={{ color: 'black' }}>Client: </strong>
    ) : null;

    const aquifersOptions = selectedAquiferOptions.length > 0 ? (
      <strong style={{ color: 'black' }}>Aquifer: </strong>
    ) : null;

    const wellUsesOptions = selectedWellUseOption.length > 0 ? (
      <strong style={{ color: 'black' }}>WellUse: </strong>
    ) : null;

    const parishesOptions = selectedParishOptions.length > 0 ? (
      <strong style={{ color: 'black' }}>Parish: </strong>
    ) : null;

    setFilterString(
      <div>
        {clientsOptions}
        {selectedClientOption.map((x) => x.label).join(', ')}
        <br />
        {aquifersOptions}
        {selectedAquiferOptions.map((x) => x.label).join(', ')}
        <br />
        {wellUsesOptions}
        {selectedWellUseOption.map((x) => x.label).join(', ')}
        <br />
        {parishesOptions}
        {selectedParishOptions.map((x) => x.label).join(', ')}
        <br />
      </div>
    );
  };

  const selectedFiltersStringChart = () => {
    const startDateOption = startDate && startDate.length > 0 ? (
      <strong style={{ color: 'black' }}>Start Date: </strong>
    ) : null;

    const endDateOption = endDate && endDate.length > 0 ? (
      <strong style={{ color: 'black' }}>End Date: </strong>
    ) : null;

    const clientsOptions = selectedClientOptionChart.length > 0 ? (
      <strong style={{ color: 'black' }}>Client: </strong>
    ) : null;

    const aquifersOptions = selectedAquiferOptionsChart.length > 0 ? (
      <strong style={{ color: 'black' }}>Aquifer: </strong>
    ) : null;

    const wellUsesOptions = selectedWellUseOptionChart.length > 0 ? (
      <strong style={{ color: 'black' }}>WellUse: </strong>
    ) : null;

    const parishesOptions = selectedParishOptionsChart.length > 0 ? (
      <strong style={{ color: 'black' }}>Parish: </strong>
    ) : null;

    setFilterStringChart(
      <div>
        {startDateOption}
        {startDate && startDate.length > 0 && startDate.map((x) => moment(x).format('yyyy-MM-DD'))}
        <br />
        {endDateOption}
        {endDate && endDate.length > 0 && endDate.map((x) => moment(x).format('yyyy-MM-DD'))}
        <br />
        {clientsOptions}
        {selectedClientOptionChart.map((x) => x.label).join(', ')}
        <br />
        {aquifersOptions}
        {selectedAquiferOptionsChart.map((x) => x.label).join(', ')}
        <br />
        {wellUsesOptions}
        {selectedWellUseOptionChart.map((x) => x.label).join(', ')}
        <br />
        {parishesOptions}
        {selectedParishOptionsChart.map((x) => x.label).join(', ')}
        <br />
      </div>
    );
  };

  const handleApplyFilter = () => {
    setToggleRefreshMap(!toggleRefreshMap);
    selectedFiltersString();
  };

  const handleApplyFilterChart = () => {
    setToggleRefreshChart(!toggleRefreshChart);
    selectedFiltersStringChart();
  };

  const calculateWidth = (maxZoomLevel) => {
    const baseWidth = 600; // Base width in pixels
    return `${baseWidth + (maxZoomLevel * 100)}px`; // Increase width by 100 pixels per zoom level
  };

  const getFormattedWellInfoValuesToDisplay = (well) => `<strong>Well #:</strong> ${well.wellNumber}<br>
  <strong>Owner Name:</strong> ${well.clientName}<br>
  <strong>Aquifer:</strong> ${well.aquiferName ?? 'N/A'}<br>
  <strong>Owner Well Name:</strong> ${well.clientWellName ?? 'N/A'}<br>
  <strong>Well-Depth:</strong> ${well.wellDepth ?? 'N/A'}<br>`;

  const iconSize = 22;



  return (
    <>
      <CustomBlock className="content-container--padded">
        {
          isLoading && <Overlay hasLoader />
        }
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock>
              <Section hasNoContainer>
                <ContentBlock
                  cols={(auth.accessLevel > 20
                    && auth.accessLevel < 50) ? 8 : 12}
                  style={{ display: 'flex' }}
                >

                  <CustomBlock className="">
                    <Section hasNoContainer>
                      <ContentBlock
                        className="content-container--card-style--with-shadow mb-15"
                      >
                        <ContentHeader
                          title="Well Map"
                          subtitle={filterString}
                          headerSize="md"
                          containerClassName="pb-15"
                          primaryButtonText="Filters"
                          primaryButtonOnClick={() => setIsModalVisible(true)}
                          secondaryButtonText="Reset"
                          secondaryButtonOnClick={resetFilters}
                        />
                        <CustomBlock
                          style={{ display: 'flex' }}
                        >
                          <div
                            className="row"
                            style={{ zIndex: '0', flexGrow: 1, minHeight: '500px' }}
                            id="map"
                            ref={mapRef}
                          />
                        </CustomBlock>
                      </ContentBlock>

                      {/* WELL USAGE LINE GRAPH */}
                      <ContentBlock className="content-container--card-style--with-shadow">
                        <ContentHeader
                          title="Well Usage Chart"
                          subtitle={filterStringChart}
                          headerSize="md"
                          containerClassName="pb-15"
                          primaryButtonText="Filters"
                          primaryButtonOnClick={() => setIsModalVisibleChart(true)}
                          secondaryButtonText="Reset"
                          secondaryButtonOnClick={resetFiltersChart}
                        />
                        <CustomBlock className="ml-5">
                          {
                            wellChartData !== null ? (
                              <div style={{ width: '100%', overflowX: 'auto' }}>
                                {/* <button onClick={handleZoomIn}>Zoom In</button>
                                <button onClick={handleZoomOut}>Zoom Out</button> */}
                                {/* <button onClick={handleResetZoom}>Reset Zoom</button> */}
                                <div style={{ width: '100%', minWidth: calculateWidth(options.hAxis.viewWindow.max) }}>
                                  <Chart
                                    chartType="LineChart"
                                    width="100%"
                                    height="400px"
                                    data={wellChartData}
                                    options={options}
                                    containerId="my-chart"
                                  />
                                </div>
                              </div>

                            ) : (
                              <div>Data not available</div>
                            )
                          }
                        </CustomBlock>
                      </ContentBlock>
                    </Section>
                  </CustomBlock>
                </ContentBlock>

                {/* INVOICE SECTION */}
                <ContentBlock
                  cols={(auth.accessLevel > 20 && auth.accessLevel < 50) ? 4 : 12}
                >
                  <Section hasNoContainer>
                    {
                      (auth.accessLevel > 20 && auth.accessLevel < 50)
                      && (
                        <ContentBlock className="content-container--card-style--with-shadow mb-15">
                          <ContentHeader
                            title={`${(new Date()).getFullYear()} Invoices`}
                            headerSize="md"
                            containerClassName="pb-15"
                          />

                          <CustomBlock className="ml-5">
                            {
                              invoices.map((item) => (
                                <NavBlock
                                  key={item.id}
                                  title={item.title}
                                  subtitle={`Invoice Date: ${item.date}`}
                                  badgeText={!item.hasPaid && 'Make Payment'}
                                  subtitleColor="dark--clr"
                                  iconLeft={item.hasPaid ? <BsFillCheckCircleFill className="success--clr" size={iconSize} /> : <BsExclamationCircleFill className="warning--clr" size={iconSize} />}
                                  backgroundColor="lighter-grey--bg"
                                  onClick={() => {
                                    if (auth.accessLevel >= 50) {
                                      history.push(`/payment/make-payment/${item.id}/${item.clientId}`);
                                    } else {
                                      history.push(`/client/payment/make-payment/${item.id}`);
                                    }
                                  }}
                                />
                              ))
                            }
                            {
                              invoices.length === 0 && (
                                <p>No Invoices for current year.</p>
                              )
                            }

                            <NavBlock
                              title="View All Invoices"
                              titleColor="info--clr"
                              backgroundColor="no-hover-bg"
                              containerClassName="mt-30"
                              onClick={() => {
                                if (auth.accessLevel >= 50) {
                                  history.push('/invoices');
                                } else {
                                  history.push('/client/payment/invoices');
                                }
                              }}
                            />
                          </CustomBlock>
                        </ContentBlock>
                      )
                    }
                  </Section>
                  {
                    (auth.accessLevel > 20 && auth.accessLevel < 50)
                    && (
                      <ContentBlock className="content-container--card-style--with-shadow mb-15">
                        <ContentHeader title="Daily Well Pumpage Data" headerSize="lg" />
                        <Tabs id="well-tabs" activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)} className="mb-3">
                          {wellPumpages.map((well, index) => (
                            <Tab eventKey={well.wellNumber} title={well.wellNumber} key={index}>
                              <div className="scrollable-table-container">
                                <Table striped bordered hover responsive>
                                  <thead>
                                    <tr>
                                      <th>Date</th>
                                      <th>Day</th>
                                      <th>Volume (1000 gal)</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {well.wellPumpageDailyData.map((data, i) => (
                                      <tr key={i}>
                                        <td>{data.formattedDate}</td>
                                        <td>{data.dayName}</td>
                                        <td>{data.dailyPumpage}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </div>
                            </Tab>
                          ))}
                        </Tabs>
                      </ContentBlock>
                    )}
                </ContentBlock>
              </Section>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      {/* WELL MAP FILTER MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={isModalVisible}
        size="lg"
        centered
        contentHeader="Well-Map Filters"
        primaryModalActiontext="Search"
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={handleApplyFilter}
        onHide={() => {
          setIsModalVisible(false);
        }}
      >
        <Section hasNoContainer>
          <ContentBlock cols={6}>
            <SelectBlock
              label="Client"
              placeholder="Select client"
              options={clients}
              isRequired
              isMulti
              isClearable
              closeMenuOnSelect={false}
              value={selectedClientOption}
              onChange={(selectedOpt) => {
                selectedOpt = selectedOpt === null ? null : selectedOpt;
                setFilterModel({
                  ...filterModel,
                  clientId: selectedOpt.map((x) => x.value) ?? ''
                });
                setSelectedClientOption(selectedOpt);
              }}
            />
          </ContentBlock>

          <ContentBlock cols={6}>
            <SelectBlock
              label="Aquifer"
              placeholder="Select Aquifer"
              options={aquifers}
              isRequired
              isMulti
              isClearable
              closeMenuOnSelect={false}
              value={selectedAquiferOptions}
              onChange={(selectedOpt) => {
                selectedOpt = selectedOpt === null ? null : selectedOpt;
                setFilterModel({
                  ...filterModel,
                  aquiferId: selectedOpt.map((x) => x.value) ?? ''
                });
                setSelectedAquiferOptions(selectedOpt);
              }}
            />
          </ContentBlock>

          <ContentBlock cols={6}>
            <SelectBlock
              label="Well-Use"
              placeholder="Select Well-Use"
              options={wellUses}
              isRequired
              isMulti
              isClearable
              closeMenuOnSelect={false}
              value={selectedWellUseOption}
              onChange={(selectedOpt) => {
                selectedOpt = selectedOpt === null ? null : selectedOpt;
                setFilterModel({
                  ...filterModel,
                  wellUseId: selectedOpt.map((x) => x.value) ?? ''
                });
                setSelectedWellUseOption(selectedOpt);
              }}
            />
          </ContentBlock>

          <ContentBlock cols={6}>
            <SelectBlock
              label="Parishes"
              placeholder="Select Parish"
              options={parishes}
              isRequired
              isMulti
              isClearable
              closeMenuOnSelect={false}
              value={selectedParishOptions}
              onChange={(selectedOpt) => {
                selectedOpt = selectedOpt === null ? null : selectedOpt;
                setFilterModel({
                  ...filterModel,
                  parishId: selectedOpt.map((x) => x.value) ?? ''
                });
                setSelectedParishOptions(selectedOpt);
              }}
            />
          </ContentBlock>
          <CheckBoxBlock
            id="industrial-district-checkbox"
            label="Filter by Industrial District"
            isChecked={filterIndustrialDistrict}
            onChange={(e) => setFilterIndustrialDistrict(e.target.checked)}
          />
        </Section>
      </ModalBlock>

      {/* WELL CHART FILTER MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={isModalVisibleChart}
        size="lg"
        centered
        contentHeader="Well-Chart Filters"
        primaryModalActiontext="Search"
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={handleApplyFilterChart}
        onHide={() => {
          setIsModalVisibleChart(false);
        }}
      >
        <Section hasNoContainer>
          {/* <ContentBlock cols={6} className="input-block-container">
            <span className="static-label" style={{ display: 'inline-block', marginBottom: 10 }}>
              Start-Date
            </span>
            <div style={{ maxWidth: '95%' }}>
              <DatePicker
                selected={startDate}
                className="input-block light--sbg label-dark--clr"
                style={{ maxWidth: '95%' }}
                onChange={handleChangeStartDate}
                placeholderText="Select Start-Date"
              />
            </div>
          </ContentBlock>

          <ContentBlock cols={6} className="input-block-container">
            <span className="static-label" style={{ display: 'inline-block', marginBottom: 10 }}>
              End-Date
            </span>
            <div style={{ maxWidth: '95%' }}>
              <DatePicker
                selected={endDate}
                className="input-block light--sbg label-dark--clr"
                style={{ maxWidth: '95%' }}
                onChange={handleChangeEndDate}
                placeholderText="Select End-Date"
              />
            </div>
          </ContentBlock> */}

          {/* <ContentBlock cols={6}>
            <InputBlock
              type="date"
              label="Start Date"
              placeholder="Select Start Date"
              onChange={handleChangeStartDate}
              value={startDate}
              options={{ dateFormat: 'm/d/Y' }}
            />
          </ContentBlock>

          <ContentBlock cols={6}>
            <InputBlock
              type="date"
              label="End Date"
              placeholder="Select End Date"
              onChange={handleChangeEndDate}
              value={endDate}
              options={{ dateFormat: 'm/d/Y' }}
            />
          </ContentBlock> */}

          <ContentBlock cols={6}>
            <SelectBlock
              label="Client"
              placeholder="Select client"
              options={clients}
              isRequired
              isMulti
              isClearable
              closeMenuOnSelect={false}
              value={selectedClientOptionChart}
              onChange={(selectedOpt) => {
                selectedOpt = selectedOpt === null ? null : selectedOpt;
                setFilterModelChart({
                  ...filterModelChart,
                  clientId: selectedOpt.map((x) => x.value) ?? null
                });
                setSelectedClientOptionChart(selectedOpt);
              }}
            />
          </ContentBlock>

          <ContentBlock cols={6}>
            <SelectBlock
              label="Aquifer"
              placeholder="Select Aquifer"
              options={aquifers}
              isRequired
              isMulti
              isClearable
              closeMenuOnSelect={false}
              value={selectedAquiferOptionsChart}
              onChange={(selectedOpt) => {
                selectedOpt = selectedOpt === null ? null : selectedOpt;
                setFilterModelChart({
                  ...filterModelChart,
                  aquiferId: selectedOpt.map((x) => x.value) ?? null
                });
                setSelectedAquiferOptionsChart(selectedOpt);
              }}
            />
          </ContentBlock>

          <ContentBlock cols={6}>
            <SelectBlock
              label="Well-Use"
              placeholder="Select Well-Use"
              options={wellUses}
              isRequired
              isMulti
              isClearable
              closeMenuOnSelect={false}
              value={selectedWellUseOptionChart}
              onChange={(selectedOpt) => {
                selectedOpt = selectedOpt === null ? null : selectedOpt;
                setFilterModelChart({
                  ...filterModelChart,
                  wellUseId: selectedOpt.map((x) => x.value) ?? null
                });
                setSelectedWellUseOptionChart(selectedOpt);
              }}
            />
          </ContentBlock>

          <ContentBlock cols={6}>
            <SelectBlock
              label="Parishes"
              placeholder="Select Parish"
              options={parishes}
              isRequired
              isMulti
              isClearable
              closeMenuOnSelect={false}
              value={selectedParishOptionsChart}
              onChange={(selectedOpt) => {
                selectedOpt = selectedOpt === null ? null : selectedOpt;
                setFilterModelChart({
                  ...filterModelChart,
                  parishId: selectedOpt.map((x) => x.value) ?? null
                });
                setSelectedParishOptionsChart(selectedOpt);
              }}
            />
          </ContentBlock>
        </Section>

      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...authRedux.actions,
    ...alert.actions,
    ...confirmModal.actions
  }
)(DashboardPage);