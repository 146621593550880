/* eslint-disable */
import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'WellPumpage/';

const getCurrentWellPumpages = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}GetAllCurrentWellPumpages`, model, constants.REQUIRE_INTERCEPTORS);

const getHistoricWellPumpages = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}GetAllHistoricWellPumpages`, model, constants.REQUIRE_INTERCEPTORS);

const getAllWellPumpages = async (model, paginationParams, sortParams) => {
  const params = {
    ...paginationParams,
    ...sortParams
  };

  return axiosInstance.post(`${ENDPOINTORIGIN}GetAllWellPumpages`, model, {
    params,
    ...constants.REQUIRE_INTERCEPTORS
  });
};

const getHourlyWellPumpages = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}GetHourlyWellPumpageData`, model, constants.REQUIRE_INTERCEPTORS);
const getDailyWellPumpages = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}GetDailyWellPumpageData`, model, constants.REQUIRE_INTERCEPTORS);

const deleteWellPumpages = async (ids) => {
  const idsArray = Array.isArray(ids) ? ids : [ids];
  
  return axiosInstance.post(`${ENDPOINTORIGIN}DeleteWellPumpages`, idsArray, constants.REQUIRE_INTERCEPTORS);
};

const getPumpageVolumesForChart = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}GetPumpageVolumesForChart`, model, constants.REQUIRE_INTERCEPTORS);

const getComplianceReport = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}GetComplianceReport`, model, constants.REQUIRE_INTERCEPTORS);

const getPumpageVolumes = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}GetWellPumpageVolumesByClientByAquifer`, model, constants.REQUIRE_INTERCEPTORS);

const getPumpageVolumesByWellUse = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}GetWellPumpageVolumesByClientByWellUses`, model, constants.REQUIRE_INTERCEPTORS);

const uploadWellPumpages = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}UploadWellPumpages`, model, {...constants.REQUIRE_INTERCEPTORS, fileHandler: true});


export {
  getCurrentWellPumpages,
  getHistoricWellPumpages,
  getAllWellPumpages,
  getHourlyWellPumpages,
  getPumpageVolumesForChart,
  getPumpageVolumes,
  getComplianceReport,
  getDailyWellPumpages,
  deleteWellPumpages,
  getPumpageVolumesByWellUse,
  uploadWellPumpages
};