/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// ICONS
// CUSTOM COMPONENTS
import Section from '../../../../../components/Section';
import ContentBlock from '../../../../../components/ContentBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import ContentHeader from '../../../../../components/ContentHeader';
import Button from '../../../../../components/Button';
import OverLay from '../../../../../components/Overlay';
// REDUX
import * as alert from '../../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../../redux/confirmModalRedux';
// SERVICES AND HELPERS
import * as parishService from '../../../../../services/management/parishService';

const ParishListingPage = (props) => {
  const { showAlert, showConfirmModal, hideConfirmModal } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [parishes, setParishes] = useState([]);

  useEffect(() => {
    getParishes();
  }, []);

  const getParishes = () => {
    setIsLoading(true);
    parishService.getParishes().then((res) => {
      setParishes(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const deleteParish = (id) => {
    setIsLoading(true);
    hideConfirmModal();
    parishService.deleteParish(id).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      getParishes();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true
  };

  const options = {
    selectableRows: 'none',
    download: true,
    print: false,

  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="View"
              className="primary--bg ml-0"
              size="xxs"
              to={`/management/parish/edit/${parishes[dataIndex].id}`}
            />
            <Button
              text="Delete"
              className="danger--bg"
              size="xxs"
              onClick={() => {
                showConfirmModal({
                  title: 'Delete Parish',
                  text: 'Are you sure you want to delete this parish?',
                  rightBtnText: 'Confirm',
                  btnAction: () => {
                    deleteParish(parishes[dataIndex].id);
                  }
                });
              }}
            />
          </CustomBlock>
        )
      }
    },
    {
      name: 'parishId',
      label: 'Parish Id',
      options: columnOptions,
    },
    {
      name: 'name',
      label: 'Parish',
      options: columnOptions,
    }
  ];

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Parishes"
                headerSize="lg"
                primaryButtonText="New Parish"
                primaryButtonTo="/management/parish"
              />

              <CustomBlock>
                <MUIDataTable
                  data={parishes}
                  columns={columns}
                  options={options}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...alert.actions,
    ...confirmModal.actions
  }
)(ParishListingPage);