/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable arrow-parens */
/* eslint-disable no-unused-vars */
/* eslint-disable */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
// ICONS
import { IoMdAddCircle } from 'react-icons/io';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import Button from '../../../../components/Button';
import SelectBlock from '../../../../components/SelectBlock';
import OverLay from '../../../../components/Overlay';
import ModalBlock from '../../../../components/ModalBlock';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as auth from '../../../../redux/authRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';
// HELPERS AND SERVICES
import * as wellService from '../../../../services/well/wellService';
import * as wellUseService from '../../../../services/well-use/wellUseService';
import * as aquiferService from '../../../../services/management/aquiferService';
import * as parishService from '../../../../services/management/parishService';
import * as clientService from '../../../../services/management/clientService';

const model = {
  aquiferId: '',
  wellUseId: '',
  clientId: '',
  parishId: ''
};

const WellListingPage = (props) => {
  console.log(props);
  const { showAlert, auth, showConfirmModal, hideConfirmModal } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmDeleteModalVisible, setIsConfirmDeleteModalVisible] = useState(false);
  const [filterModel, setFilterModel] = useState({ ...model });
  const [toggleWellListing, setToggleWellListing] = useState(false);
  const [wellUses, setWellUses] = useState([]);
  const [clients, setClients] = useState([]);
  const [aquifers, setAquifers] = useState([]);
  const [parishes, setParishes] = useState([]);
  const [selectedAquiferOptions, setSelectedAquiferOptions] = useState([]);
  const [selectedParishOptions, setSelectedParishOptions] = useState([]);
  const [selectedClientOption, setSelectedClientOption] = useState([]);
  const [selectedWellUseOption, setSelectedWellUseOption] = useState([]);
  const [wells, setWells] = useState([]);
  const [filterDepth, setFilterDepth] = useState({ min: '', max: '' });
  const [filteredData, setFilteredData] = useState(wells);
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState(wells);

  const iconSize = 22;
  const iconColor = 'white--clr';

  useEffect(() => {
    if (auth.accessLevel >= 20) {
      if (auth.accessLevel >= 50) {
        fetchClients();
      }
      fetchAquifers();
      fetchParishes();
      fetchWellUses();
    }
  }, []);

  useEffect(() => {
    getAllWells();
  }, [toggleWellListing]);

  const getAllWells = () => {
    setIsLoading(true);
    wellService.getWellsForDashboard(filterModel).then((res) => {
      setWells(res);
      setFilteredData(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleFilterChange = (event) => {
    setFilterDepth({
      ...filterDepth,
      [event.target.name]: event.target.value
    });
  };

  const resetFilter = () => {
    setFilterDepth({ min: '', max: '' });
    setFilteredData(wells);
  };

  const applyFilter = () => {
    const min = filterDepth.min ? parseInt(filterDepth.min, 10) : 0;
    const max = filterDepth.max ? parseInt(filterDepth.max, 10) : Infinity;

    const newFilteredData = wells.filter(well => {
      const wellDepth = parseInt(well.wellDepth, 10);
      return wellDepth >= min && wellDepth <= max;
    });

    setFilteredData(newFilteredData);
  };

  const options = {
    onFilterChange: (column, filterList, type, changedColumn, display) => {
      if (changedColumn === 'wellDepth' && type === 'checkbox') {
        applyFilter();
      }

      // Check if all filters are empty
      const allFiltersEmpty = filterList.every(filter => filter.length === 0);
      if (allFiltersEmpty) {
        resetFilter();
      }
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      let filteredData = data;
      if (searchTerm && searchTerm.trim() !== '') {
        filteredData = data.filter((item) => item.data.some((d) => d && d.toString().toLowerCase().includes(searchTerm.toLowerCase())));
      }
      return `\uFEFF ${buildHead(columns)} + ${buildBody(filteredData)}`;
    },
    onSearchChange: (searchText) => {
      setSearchTerm(searchText);
    },
    filter: true,
    print: false,
    selectableRows: false,
    download: true,
  };

  const fetchClients = () => {
    clientService.getAllClients(true).then((res) => {
      const tempClients = res.map((x) => ({ label: x.name, value: x.id }));
      setClients(tempClients);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    });
  };

  const fetchWellUses = () => {
    wellUseService.getWellUses().then((res) => {
      const tempWellUses = res.map((x) => ({ label: `${x.description}`, value: x.id }));
      setWellUses(tempWellUses);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    });
  };

  const fetchAquifers = () => {
    aquiferService.getAquifers().then((res) => {
      const tempAquifers = res.map((x) => ({ label: x.name, value: x.id }));
      setAquifers(tempAquifers);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    });
  };

  const fetchParishes = () => {
    parishService.getParishes().then((res) => {
      const tempParishes = res.map((x) => ({ label: x.name, value: x.id }));
      setParishes(tempParishes);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    });
  };

  const resetFilters = () => {
    setFilterModel({ ...model });
    setSelectedAquiferOptions([]);
    setSelectedClientOption([]);
    setSelectedParishOptions([]);
    setSelectedWellUseOption([]);
    setToggleWellListing(!toggleWellListing);
  };

  const deleteWell = (id) => {
    hideConfirmModal();
    setIsLoading(true);
    wellService.deleteWell(id).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      getAllWells();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: true,
    download: true,
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: true,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text={auth.accessLevel >= 50 ? 'Edit' : 'Details'}
              className="primary--bg ml-0"
              size="xxs"
              to={`/well/edit/${filteredData[dataIndex].id}`}
            />

            {
              (auth.accessLevel >= 50) && (
                <Button
                  text="Delete"
                  className="danger--bg"
                  size="xxs"
                  onClick={() => {
                    showConfirmModal({
                      title: 'Delete Well',
                      text: 'Are you sure you want to delete this well?',
                      rightBtnText: 'Confirm',
                      btnAction: () => {
                        deleteWell(filteredData[dataIndex].id);
                        hideConfirmModal();
                      }
                    });
                  }}
                />
              )
            }
          </CustomBlock>
        )
      }
    },
    {
      name: 'identificationNumber',
      label: 'ID #',
      options: {
        filter: false,
        sort: true,
        print: true,
        download: true,
        display: false,
        customBodyRenderLite: (dataIndex) => (
          <p>{filteredData[dataIndex].identificationNumber ? filteredData[dataIndex].identificationNumber : '-'}</p>
        )
      },
      width: 1000
    },
    {
      name: 'wellNumber',
      label: 'Well #',
      options: {
        filter: true,
        sort: true,
        print: true,
        download: true,
        filterType: 'textField',
        customBodyRenderLite: (dataIndex) => (
          <p>{filteredData[dataIndex].wellNumber ? filteredData[dataIndex].wellNumber : '-'}</p>
        )
      },
      width: 1000
    },
    {
      name: 'aquiferName',
      label: 'Aquifer',
      options: {
        filter: false,
        sort: true,
        print: true,
        download: true,
        customBodyRenderLite: (dataIndex) => (
          <p>{filteredData[dataIndex].aquiferName ? filteredData[dataIndex].aquiferName : '-'}</p>
        )
      }
    },
    {
      name: 'clientName',
      label: 'Well Owner',
      options: {
        filter: false,
        sort: true,
        print: true,
        filterType: 'dropdown',
        download: true,
        customBodyRenderLite: (dataIndex) => (
          <p>{filteredData[dataIndex].clientName ? filteredData[dataIndex].clientName : '-'}</p>
        )
      }
    },
    {
      name: 'clientWellName',
      label: 'Owner Well ID',
      options: {
        filter: false,
        sort: true,
        print: true,
        filterType: 'dropdown',
        download: true,
        customBodyRenderLite: (dataIndex) => (
          <p>{filteredData[dataIndex].clientWellName ? filteredData[dataIndex].clientWellName : '-'}</p>
        )
      }
    },
    {
      name: 'wellUseName',
      label: 'Well Use',
      options: {
        filter: false,
        sort: true,
        print: true,
        download: true,
        customBodyRenderLite: (dataIndex) => (
          <p>{filteredData[dataIndex].wellUseName ? filteredData[dataIndex].wellUseName : '-'}</p>
        )
      }
    },
    {
      name: 'parishName',
      label: 'Parish Name',
      options: {
        filter: false,
        sort: true,
        print: true,
        download: true,
        customBodyRenderLite: (dataIndex) => (
          <p>{filteredData[dataIndex].parishName ? filteredData[dataIndex].parishName : '-'}</p>
        )
      }
    },
    {
      name: 'wellDepth',
      label: 'Well Depth',
      options: {
        filter: true,
        filterType: 'custom',
        sort: true,
        customFilterListOptions: {
          render: v => {
            if (v[0] && v[1] && !filterDepth.min && !filterDepth.max) {
              return `Min Depth: ${v[0]}, Max Depth: ${v[1]}`;
            }
            return null;
          },
        },
        filterOptions: {
          names: [],
          logic(depth, filters) {
            if (filters[0] && filters[1]) {
              return depth < filters[0] || depth > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Depth Range</FormLabel>
              <FormGroup row>
                <TextField
                  label="min"
                  value={filterDepth.min}
                  onChange={handleFilterChange}
                  name="min"
                />
                <TextField
                  label="max"
                  value={filterDepth.max}
                  onChange={handleFilterChange}
                  name="max"
                />
                <button onClick={applyFilter}>Apply</button>
              </FormGroup>
            </div>
          )
        }
      }
    },
    {
      name: 'latitude',
      label: 'Latitude',
      options: {
        filter: true,
        sort: true,
        print: true,
        download: true,
        customBodyRenderLite: (dataIndex) => (
          <p>{filteredData[dataIndex].latitude ? filteredData[dataIndex].latitude : '-'}</p>
        )
      }
    },
    {
      name: 'longitude',
      label: 'Longitude',
      options: {
        filter: true,
        sort: true,
        print: true,
        download: true,
        customBodyRenderLite: (dataIndex) => (
          <p>{filteredData[dataIndex].longitude ? filteredData[dataIndex].longitude : '-'}</p>
        )
      }
    },
    {
      name: 'flag',
      label: 'Flag',
      options: {
        filter: true,
        sort: true,
        print: true,
        download: true,
        filterType: 'textField',
        customBodyRenderLite: (dataIndex) => (
          <p>{filteredData[dataIndex].flag === 'E' ? 'Exempt' : '-'}</p>
        )
      }
    },
    {
      name: 'availableInformation',
      label: 'Available Information',
      options: {
        filter: false,
        sort: true,
        display: false,
        print: true,
        download: true,
        customBodyRenderLite: dataIndex => filteredData[dataIndex].availableInformation || '-'
      }
    },
    {
      name: 'completionDateTime',
      label: 'Completion Date',
      options: {
        filter: false,
        sort: true,
        print: true,
        display: false,
        download: true,
        customBodyRenderLite: dataIndex => filteredData[dataIndex].completionDateTime ? new Date(filteredData[dataIndex].completionDateTime).toLocaleDateString() : '-'
      }
    },
    {
      name: 'inactiveDateTime',
      label: 'Inactive Date',
      options: {
        filter: false,
        sort: true,
        display: false,
        print: true,
        download: true,
        customBodyRenderLite: dataIndex => filteredData[dataIndex].inactiveDateTime ? new Date(filteredData[dataIndex].inactiveDateTime).toLocaleDateString() : '-'
      }
    },
    {
      name: 'comment',
      label: 'Comment',
      options: {
        filter: false,
        sort: true,
        display: false,
        print: true,
        download: true,
        customBodyRenderLite: dataIndex => filteredData[dataIndex].comment || '-'
      }
    },
    {
      name: 'cagcdPermitNumber',
      label: 'CAGCD Permit Number',
      options: {
        filter: false,
        display: false,
        sort: true,
        print: true,
        download: true,
        customBodyRenderLite: dataIndex => filteredData[dataIndex].cagcdPermitNumber || '-'
      }
    },
    {
      name: 'cagcdPermitDate',
      label: 'CAGCD Permit Date',
      options: {
        filter: false,
        display: false,
        sort: true,
        print: true,
        download: true,
        customBodyRenderLite: dataIndex => filteredData[dataIndex].cagcdPermitDate ? new Date(filteredData[dataIndex].cagcdPermitDate).toLocaleDateString() : '-'
      }
    },
    {
      name: 'ldnrNotificationDate',
      label: 'LDNR Notification Date',
      options: {
        filter: false,
        sort: true,
        display: false,
        print: true,
        download: true,
        customBodyRenderLite: dataIndex => filteredData[dataIndex].ldnrNotificationDate ? new Date(filteredData[dataIndex].ldnrNotificationDate).toLocaleDateString() : '-'
      }
    },
    {
      name: 'ldnrNotificationNumber',
      label: 'LDNR Notification Number',
      options: {
        filter: false,
        sort: true,
        display: false,
        print: true,
        download: true,
        customBodyRenderLite: dataIndex => filteredData[dataIndex].ldnrNotificationNumber || '-'
      }
    },
    {
      name: 'meterType',
      label: 'Meter Type',
      options: {
        filter: false,
        sort: true,
        display: false,
        print: true,
        download: true,
        customBodyRenderLite: dataIndex => filteredData[dataIndex].meterType || '-'
      }
    },
    {
      name: 'wellStatus',
      label: 'Well Status',
      options: {
        filter: false,
        sort: true,
        display: false,
        print: true,
        download: true,
        customBodyRenderLite: dataIndex => filteredData[dataIndex].wellStatus || '-'
      }
    },
    {
      name: 'wellStatusDate',
      label: 'Well Status Date',
      options: {
        filter: false,
        sort: true,
        print: true,
        display: false,
        download: true,
        customBodyRenderLite: dataIndex => filteredData[dataIndex].wellStatusDate ? new Date(filteredData[dataIndex].wellStatusDate).toLocaleDateString() : '-'
      }
    }
  ];

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              {
                auth.accessLevel >= 50
                && (
                  <ContentHeader
                    title="Wells"
                    headerSize="lg"
                    primaryButtonText="New Well"
                    primaryButtonIconLeft={<IoMdAddCircle className={iconColor} size={iconSize} />}
                    primaryButtonTo="/well/create"
                  />
                )
              }
              <CustomBlock>
                {
                  auth.accessLevel >= 20 && (
                  <Section hasNoContainer>
                    <ContentHeader
                      title="Filters"
                      headerSize="md"
                    />
                    {
                      auth.accessLevel >= 50 && (
                      <ContentBlock cols={6}>
                        <SelectBlock
                          label="Client"
                          placeholder="Select client"
                          options={clients}
                          isRequired
                          isMulti
                          isClearable
                          closeMenuOnSelect={false}
                          value={selectedClientOption}
                          onChange={(selectedOpt) => {
                            selectedOpt = selectedOpt === null ? null : selectedOpt;
                            setFilterModel({
                              ...filterModel,
                              clientId: selectedOpt.map((x) => x.value) ?? ''
                            });
                            setSelectedClientOption(selectedOpt);
                          }}
                        />
                      </ContentBlock>
                      )
                    }
                    <ContentBlock cols={6}>
                      <SelectBlock
                        label="Aquifer"
                        placeholder="Select Aquifer"
                        options={aquifers}
                        isRequired
                        isMulti
                        isClearable
                        closeMenuOnSelect={false}
                        value={selectedAquiferOptions}
                        onChange={(selectedOpt) => {
                          selectedOpt = selectedOpt === null ? null : selectedOpt;
                          setFilterModel({
                            ...filterModel,
                            aquiferId: selectedOpt.map((x) => x.value) ?? ''
                          });
                          setSelectedAquiferOptions(selectedOpt);
                        }}
                      />
                    </ContentBlock>

                    <ContentBlock cols={6}>
                      <SelectBlock
                        label="Well-Use"
                        placeholder="Select Well-Use"
                        options={wellUses}
                        isRequired
                        isMulti
                        isClearable
                        closeMenuOnSelect={false}
                        value={selectedWellUseOption}
                        onChange={(selectedOpt) => {
                          selectedOpt = selectedOpt === null ? null : selectedOpt;
                          setFilterModel({
                            ...filterModel,
                            wellUseId: selectedOpt.map((x) => x.value) ?? ''
                          });
                          setSelectedWellUseOption(selectedOpt);
                        }}
                      />
                    </ContentBlock>

                    <ContentBlock cols={6}>
                      <SelectBlock
                        label="Parishes"
                        placeholder="Select Parish"
                        options={parishes}
                        isRequired
                        isMulti
                        isClearable
                        closeMenuOnSelect={false}
                        value={selectedParishOptions}
                        onChange={(selectedOpt) => {
                          selectedOpt = selectedOpt === null ? null : selectedOpt;
                          setFilterModel({
                            ...filterModel,
                            parishId: selectedOpt.map((x) => x.value) ?? ''
                          });
                          setSelectedParishOptions(selectedOpt);
                        }}
                      />
                    </ContentBlock>
                  </Section>
                  )
                }
                &nbsp;
                {
                  auth.accessLevel >= 20 && (
                  <CustomBlock style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      text="Reset"
                      className="primary--bg ml-0"
                      size="xs"
                      onClick={resetFilters}
                    />
                    &nbsp;
                    <Button
                      text="Search"
                      className="primary--bg ml-0"
                      size="xs"
                      onClick={() => setToggleWellListing(!toggleWellListing)}
                    />
                  </CustomBlock>
                  )
                }
                &nbsp;
                <MUIDataTable
                  data={filteredData}
                  columns={columns}
                  options={options}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      {/* CONFIRM DELETE MODAL */}
      <ModalBlock
        hasCloseAction
        centered
        isVisible={isConfirmDeleteModalVisible}
        size="md"
        contentHeader="Delete Well"
        contentDescription="Are you sure you want to delete this well?"
        primaryModalActionText="Delete"
        primaryModalActionColor="danger--bg"
        primaryModalActionOnClick={() => { }}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="grey--bg"
        onHide={() => {
          setIsConfirmDeleteModalVisible(false);
        }}
      />
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...auth.actions,
    ...alert.actions,
    ...confirmModal.actions
  }
)(WellListingPage);