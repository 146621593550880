/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/no-array-index-key */
// DEPENDENCIES
import React, { useState } from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import { Route, useHistory } from 'react-router-dom';
// ICONS
import {
  TbMenu,
  TbX,
  // TbTools,
  TbUsers,
  // TbBorderAll,
  // TbUserCircle,
  // TbLogout,
  // TbCircleCheck,
  // TbChevronRight,
  TbDropCircle,
  TbLayoutList,
  TbReportAnalytics,
  TbBuildingStore,
  TbBuildingCommunity,
  // TbMailFast,
  TbLayoutRows
} from 'react-icons/tb';
// CUSTOM COMPONENTS;
import DrawerNav from '../components/DrawerNav';
import NavBlock from '../components/NavBlock';
import CustomBlock from '../components/CustomBlock';
// import SideNav from '../components/SideNav';
import TopNav from '../components/TopNav';
// ASSETS
// import Logo from '../assets/img/logo.svg';
import Icon from '../assets/img/icon.svg';
// REDUX
import * as auth from '../redux/authRedux';
// SERVICES AND HELPERS

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // const [isSideNavCollapsed, setIsSideNavCollapsed] = useState(false);
  // const [isSideNavMenuTitleVisible, setIsSideNavMenuTitleVisible] = useState(true);

  const history = useHistory();
  const iconSize = 24;
  const iconSizeLarge = 30;
  const iconColor = 'primary--clr';
  const mainPage = '/projects';

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const navigateToPath = (path) => {
    history.push(path);
    setTimeout(() => {
      setIsDrawerOpen(false);
    }, 250);
  };

  const topNavMenuItems = [
    {
      id: 1,
      name: 'Dashboard',
      to: '/dashboard',
      action: null,
      subMenuItems: null,
      isVisible: rest.auth.accessLevel >= 50
    },
    {
      id: 2,
      name: 'Wells',
      to: '/wells',
      action: null,
      subMenuItems: null,
      isVisible: rest.auth.accessLevel >= 20
    },
    {
      id: 3,
      name: 'Water-Data',
      to: '/well/water-data',
      action: null,
      subMenuItems: null,
      isVisible: rest.auth.accessLevel >= 20
    },
    {
      id: 4,
      iconLeft: <TbReportAnalytics className={iconColor} size={iconSize} />,
      name: 'Reports',
      to: '/well/well-usage-report',
      action: null,
      subMenuItems: null,
      isVisible: rest.auth.accessLevel >= 50
    },
    {
      id: 5,
      name: 'Invoices',
      to: '/invoices',
      action: null,
      subMenuItems: null,
      isVisible: rest.auth.accessLevel >= 50
    },
    // {
    //   id: 6,
    //   name: 'Well Usage',
    //   to: '',
    //   action: null,
    //   isVisible: rest.auth.accessLevel >= 50,
    //   submenuItems: [
    //     {
    //       id: 1,
    //       name: 'Well Usage',
    //       to: '/well-usage',
    //       iconLeft: <TbLayoutRows size={22} className={iconColor} />
    //     },
    //     {
    //       id: 2,
    //       name: 'Well Usage Categories',
    //       to: '/well-usage-categories',
    //       iconLeft: <TbLayoutList size={22} className={iconColor} />
    //     }
    //   ]
    // },
    {
      id: 6,
      name: 'Management',
      to: '',
      action: null,
      isVisible: rest.auth.accessLevel >= 50,
      submenuItems: [
        {
          id: 1,
          name: 'Users',
          to: '/management/users',
          iconLeft: <TbUsers size={22} className={iconColor} />
        },
        {
          id: 2,
          name: 'Clients',
          to: '/management/clients',
          iconLeft: <TbBuildingStore size={22} className={iconColor} />
        },
        {
          id: 3,
          name: 'Aquifers',
          to: '/management/aquifers',
          iconLeft: <TbDropCircle size={22} className={iconColor} />
        },
        {
          id: 4,
          name: 'Parishes',
          to: '/management/parishes',
          iconLeft: <TbBuildingCommunity size={22} className={iconColor} />
        },
        {
          id: 5,
          name: 'Well-Usage',
          to: '/well-usage',
          iconLeft: <TbLayoutRows size={22} className={iconColor} />
        },
        {
          id: 5,
          name: 'Well Usage Categories',
          to: '/well-usage-categories',
          iconLeft: <TbLayoutList size={22} className={iconColor} />
        }
        // ,{
        //   id: 6,
        //   name: 'Well Pumpages',
        //   to: '/well-pumpages',
        //   iconLeft: <TbLayoutList size={22} className={iconColor} />
        // }
      ]
    },
    {
      id: 8,
      name: 'Management',
      to: '',
      action: null,
      isVisible: rest.auth.accessLevel >= 40 && rest.auth.accessLevel < 50,
      submenuItems: [
        {
          id: 1,
          name: 'Owner Profile',
          to: `/management/client/edit/${rest.auth.clientIds}`,
          iconLeft: <TbBuildingStore size={22} className={iconColor} />
        },
        {
          id: 2,
          name: 'Users',
          to: '/management/users',
          iconLeft: <TbUsers size={22} className={iconColor} />
        },
      ]
    },

  ];

  return (
    <Route
      {...rest} render={(props) => (
        <>
          <TopNav
            hasReturnAction
            hasReturnActionBackground
            hasNavigation
            hasHeaderTitle
            icon={Icon}
            iconTo={mainPage}
            headerTitle={rest.title}
            routePath={rest.path}
            userInitials={`${rest.auth.user.firstName[0]}${rest.auth.user.lastName[0]}`}
            menuItems={topNavMenuItems.filter((x) => x.isVisible)}
            menuToggleIcon={
              isDrawerOpen
                ? (
                  <TbX
                    size={iconSizeLarge} className="danger--clr"
                    strokeWidth="3"
                  />
                ) : (
                  <TbMenu
                    size={iconSizeLarge} className="dark-blue--clr"
                    strokeWidth="3"
                  />
                )
            }
            secondaryIconActionOnClick={() => {
              rest.logout();
            }}
            onClickAvatar={() => {
              history.push('/my-profile');
            }}
            onClick={() => {
              toggleDrawer();
            }}
          />

          <DrawerNav
            isOpen={isDrawerOpen}
            onClick={() => {
              setIsDrawerOpen(false);
            }}
            onClickLogout={() => {
              rest.resetProjectDetails();
              rest.logout();
            }}
            navigationItems={
              topNavMenuItems.map((item, index) => (
                <NavBlock
                  tooltipText={item.name}
                  key={index}
                  title={item.name}
                  iconLeft={item.iconLeft}
                  titleColor={item.to === rest.path ? 'info--clr' : 'dark-blue--clr'}
                  backgroundColor={item.to === rest.path ? 'lighter-blue--sbg' : 'white--sbg'}
                  onClick={() => {
                    navigateToPath(item.subMenuItems == null ? item.to : item.subMenuItems.to);
                  }}
                />
              ))
            }
          />

          <CustomBlock className="content-wrapper">
            {/* <SideNav
              // isCollapsible
              isExpanded={!isSideNavCollapsed}
              onClick={() => {
                setIsSideNavCollapsed(!isSideNavCollapsed);

                if (isSideNavCollapsed) {
                  setTimeout(() => {
                    setIsSideNavMenuTitleVisible(true);
                  }, 200);
                } else {
                  setIsSideNavMenuTitleVisible(false);
                }
              }}
            >
              {
                sideNavItems.map((item, index) => (
                  <NavBlock
                    isCollapsed={isSideNavCollapsed}
                    tooltipText={item.name}
                    key={index}
                    title={isSideNavMenuTitleVisible ? item.name : ''}
                    titleColor={item.link.includes(rest.path.replace('/:projectId', '')) ? 'info--clr' : 'dark-blue--clr'}
                    backgroundColor={item.link.includes(rest.path.replace('/:projectId', '')) ? 'lighter-blue--sbg' : 'white--sbg'}
                    iconLeft={item.iconLeft}
                    onClick={() => {
                      navigateToPath(`${item.link}${rest.projectDetail.projectId}`);
                    }}
                  />
                ))
              }
            </SideNav> */}

            <CustomBlock className="component-wrapper">
              <Component {...props} />
            </CustomBlock>
          </CustomBlock>
        </>
      )}
    />
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...auth.actions })(PrivateRoute);