import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string().required('Name is required').max(500),
  contactName: Yup.string().nullable().max(150),
  contactPhone: Yup.string().nullable().max(20),
  contactEmail: Yup.string().nullable().email('Please enter a valid email address').max(100),
  address1: Yup.string().required('Address is required').max(150),
  address2: Yup.string().nullable().max(150),
  city: Yup.string().required('City is required').max(150),
  state: Yup.string().required('State is required').max(50),
  zipCode: Yup.string().required('Zip Code is required').max(10),
  registrationCode: Yup.string().required('Registration Code is required').max(20),
  isActive: Yup.bool().nullable(),
});