/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
// COMPONENTS
// ICONS
// CUSTOM COMPONENTS
import Overlay from '../../../../../components/Overlay';
import Section from '../../../../../components/Section';
import ContentBlock from '../../../../../components/ContentBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import FormBlock from '../../../../../components/FormBlock';
import ContentHeader from '../../../../../components/ContentHeader';
import InputBlock from '../../../../../components/InputBlock';
import Button from '../../../../../components/Button';
// REDUX
import * as alert from '../../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../../redux/confirmModalRedux';
// SERVICES AND HELPERS
import * as aquiferService from '../../../../../services/management/aquiferService';
import AquiferValidator from '../../../../../helpers/validators/management/aquifer/AquiferValidator';
import * as helper from '../../../../../helpers/helper';

const initialAquiferModel = {
  id: 0,
  name: '',
  isActive: true,
  aquiferId: ''
};

const AquiferManagementPage = (props) => {
  const {
    showAlert,
    history,
  } = props;
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [aquifer, setAquifer] = useState(initialAquiferModel);

  useEffect(() => {
    if (id) {
      populateForm();
    }
  }, []);

  const formik = useFormik({
    initialValues: aquifer,
    validationSchema: AquiferValidator,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      saveAquifer(values);
    },
  });

  const populateForm = () => {
    setIsLoading(true);
    aquiferService.getAquiferById(id).then((res) => {
      setAquifer(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const saveAquifer = (model) => {
    setIsLoading(true);
    aquiferService.saveAquifer(model).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.replace(`/management/aquifer/edit/${res.id}`);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <FormBlock className="content-container--card-style--with-shadow" onSubmit={formik.handleSubmit}>
            <ContentHeader
              title="Aquifer Details"
              headerSize="lg"
            />

            <Section hasNoContainer>
              <ContentBlock>
                <ContentHeader
                  title="Basic Information"
                  headerSize="md"
                  className="alt-font fw-600 pb-15"
                />
              </ContentBlock>

              <ContentBlock cols={6}>
                <InputBlock
                  label="Aquifer Id"
                  isRequired
                  errorMessage={formik.errors.aquiferId}
                  inputState={`${helper.getInputClasses(formik, 'aquiferId')}`}
                  {...formik.getFieldProps('aquiferId')}
                />
              </ContentBlock>

              <ContentBlock cols={6}>
                <InputBlock
                  label="Aquifer Name"
                  isRequired
                  errorMessage={formik.errors.name}
                  inputState={`${helper.getInputClasses(formik, 'name')}`}
                  {...formik.getFieldProps('name')}
                />
              </ContentBlock>

              <ContentBlock className="content-container--actions flex-end">
                <Button
                  text="Cancel"
                  className="danger--bg"
                  onClick={() => history.push('/management/aquifers')}
                />

                <Button
                  text={id ? 'Save Changes' : 'Create'}
                  className="primary--bg"
                  onClick={formik.handleSubmit}
                />
              </ContentBlock>
            </Section>
          </FormBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...alert.actions,
  ...confirmModal.actions
})(AquiferManagementPage);