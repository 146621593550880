import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Client/';

const getAllClients = async (isActive) => axiosInstance.get(`${ENDPOINTORIGIN}GetAllClients?isActive=${isActive}`, constants.REQUIRE_INTERCEPTORS);

const getAllClientsOfAClient = async (isActive, clientIds) => axiosInstance.get(`${ENDPOINTORIGIN}GetAllClients?isActive=${isActive}&clientIds=${clientIds}`, constants.REQUIRE_INTERCEPTORS);

const getClientById = async (clientId) => axiosInstance.get(`${ENDPOINTORIGIN}GetClientById?clientId=${clientId}`, constants.REQUIRE_INTERCEPTORS);

const createClient = async (client) => axiosInstance.post(`${ENDPOINTORIGIN}CreateClient`, client, constants.REQUIRE_INTERCEPTORS);

const updateClient = async (model) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateClient`, model, constants.REQUIRE_INTERCEPTORS);

const deleteClient = async (id) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteClient?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const toggleClientMenuItem = async (id) => axiosInstance.put(`${ENDPOINTORIGIN}ToggleClientMenuItem?id=${id}`, {}, constants.REQUIRE_INTERCEPTORS);

const getAvailableUsers = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetAvailableUsers`, constants.REQUIRE_INTERCEPTORS);

const assignClientUsers = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}AssignClientUsers`, model, constants.REQUIRE_INTERCEPTORS);

const removeUserAssignment = async (clientId, userId) => axiosInstance.delete(`${ENDPOINTORIGIN}RemoveUserAssignment?clientId=${clientId}&userId=${userId}`, constants.REQUIRE_INTERCEPTORS);

export {
  getAllClients,
  getClientById,
  createClient,
  updateClient,
  deleteClient,
  getAllClientsOfAClient,
  toggleClientMenuItem,
  getAvailableUsers,
  assignClientUsers,
  removeUserAssignment
};