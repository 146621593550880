/* eslint-disable */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// ICONS
// CUSTOM COMPONENTS
import Section from '../../../../../components/Section';
import ContentBlock from '../../../../../components/ContentBlock';
import SelectBlock from '../../../../../components/SelectBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import ContentHeader from '../../../../../components/ContentHeader';
import OverLay from '../../../../../components/Overlay';
import Button from '../../../../../components/Button';
// REDUX
import * as alert from '../../../../../redux/alertToastRedux';
import * as auth from '../../../../../redux/authRedux';
import * as confirmModal from '../../../../../redux/confirmModalRedux';
// HELPERS AND SERVICES
import * as wellPumpageService from '../../../../../services/wellPumpage/WellPumpageService';
import * as excelExportService from '../../../../../services/excelExport/excelExportService';

import * as helper from '../../../../../helpers/helper';

const initialModel = {
  year: new Date().getFullYear(),
  quarter: null
};

const quarters = [1, 2, 3, 4];

const ComplianceReport = (props) => {
  const { showAlert, auth } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [quarterOptions, setquarterOptions] = useState(null);
  const [model, setModel] = useState({ ...initialModel });
  const [data, setData] = useState([]);
  const [columnYear, setColumnYear] = useState(initialModel.year);
  const [applyFilter, setApplyFilter] = useState(false);
  const [selectedquarterOption, setSelectedquarterOption] = useState(null);
  const [selectedYearOption, setSelectedYearOption] = useState(
    {
      label: new Date().getFullYear(),
      value: new Date().getFullYear()
    }
  );

  useEffect(() => {
    setquarterOptions(quarters.map((x) => helper.setToOptionModel(x, x)));
    fetchComplianceReport(model);
  }, [applyFilter]);

  const handleReset = () => {
    setModel({
      year: new Date().getFullYear(),
      quarter: null,
    });
    setColumnYear(new Date().getFullYear());
    setSelectedquarterOption(
      [helper.setToOptionModel(model.quarter, model.quarter)]
    );
    setSelectedYearOption({
      label: new Date().getFullYear(),
      value: new Date().getFullYear()
    });
    setApplyFilter(!applyFilter);
  };

  const handleDownloadClick = async () => {
    if (initialModel.quarter?.length === 0) {
      alert('Please select at least one quarter.');
      return;
    }
    excelExportService.downloadComplianceReport(model.year ?? initialModel.year, model.quarter ?? initialModel?.quarter ?? [1, 2, 3, 4])
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `ComplianceReport_${model.year ?? initialModel.year}.xlsx`;
        document.body.appendChild(link);
        link.click();

        window.setTimeout(() => {
          window.URL.revokeObjectURL(url);
          link.remove();
        }, 100);
      })
      .catch(error => {
        console.error('Error while downloading the file:', error);
      });
  };

  const fetchComplianceReport = (modelFilter) => {
    setIsLoading(true);
    wellPumpageService.getComplianceReport(modelFilter).then((res) => {
      setData(res);
      setColumnYear(modelFilter.year);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: true,
    download: true
  };

  const columns = [
    {
      name: 'wellNumber',
      label: 'Well Number',
      options: columnOptions,
      width: 1000
    },
    {
      name: 'client',
      label: 'Client',
      options: columnOptions,
    },
    {
      name: 'aquifer',
      label: 'Aquifer',
      options: columnOptions,
    },
    {
      name: 'q1Y1',
      label: `Q1 ${columnYear}`,
      options: columnOptions,
    },
    {
      name: 'q2Y1',
      label: `Q2 ${columnYear}`,
      options: columnOptions,
    },
    {
      name: 'q3Y1',
      label: `Q3 ${columnYear}`,
      options: columnOptions,
    },
    {
      name: 'q4Y1',
      label: `Q4 ${columnYear}`,
      options: columnOptions,
    },
    {
      name: 'q1Y2',
      label: `Q1 ${columnYear + 1}`,
      options: columnOptions,
    },
    {
      name: 'q2Y2',
      label: `Q2 ${columnYear + 1}`,
      options: columnOptions,
    },
    {
      name: 'q3Y2',
      label: `Q3 ${columnYear + 1}`,
      options: columnOptions,
    },
    {
      name: 'q4Y2',
      label: `Q4 ${columnYear + 1}`,
      options: columnOptions,
    },
    {
      name: 'q1Y3',
      label: `Q1 ${columnYear + 2}`,
      options: columnOptions,
    },
    {
      name: 'q2Y3',
      label: `Q2 ${columnYear + 2}`,
      options: columnOptions,
    },
    {
      name: 'q3Y3',
      label: `Q3 ${columnYear + 2}`,
      options: columnOptions,
    },
    {
      name: 'q4Y3',
      label: `Q4 ${columnYear + 2}`,
      options: columnOptions,
    }
  ];

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock cl assName="content-container--card-style--with-shadow">
              {
                auth.accessLevel >= 50
                && (
                  <ContentHeader
                    title="Compliance Report"
                    headerSize="lg"
                  />
                )
              }

              <Section hasNoContainer>
                <ContentBlock>
                  {
                    isLoading && <p>Loading...</p>
                  }
                  <CustomBlock>
                    <Section hasNoContainer>
                      <ContentHeader
                        title="Filters"
                        headerSize="md"
                      />
                      <ContentBlock cols={4}>
                        <SelectBlock
                          label="Year"
                          placeholder="Select year"
                          options={helper.getYears()}
                          closeMenuOnSelect
                          value={selectedYearOption}
                          onChange={(selectedOpt) => {
                            selectedOpt = selectedOpt === null ? null : selectedOpt;
                            setModel({
                              ...model,
                              year: (selectedOpt && selectedOpt.value) ?? null
                            });
                            setSelectedYearOption(selectedOpt);
                          }}
                        />
                      </ContentBlock>
                      <ContentBlock cols={2}>
                        <SelectBlock
                          label="quarter"
                          isClearable
                          isMulti
                          placeholder="Select quarter"
                          options={quarterOptions}
                          closeMenuOnSelect={false}
                          value={selectedquarterOption}
                          onChange={(selectedOpt) => {
                            selectedOpt = selectedOpt === null ? null : selectedOpt;
                            setModel({
                              ...model,
                              quarter: selectedOpt.map((x) => x.value) ?? ''
                            });
                            setSelectedquarterOption(selectedOpt);
                          }}
                        />
                      </ContentBlock>
                    </Section>
                    &nbsp;
                    <CustomBlock style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        text="Reset"
                        className="primary--bg ml-0"
                        size="xs"
                        onClick={handleReset}
                      />
                      &nbsp;
                      <Button
                        text="Search"
                        className="primary--bg ml-0"
                        size="xs"
                        onClick={() => setApplyFilter(!applyFilter)}
                      />
                    </CustomBlock>
                    &nbsp;
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        text="Export"
                        size="xs"
                        className="primary--bg"
                        onClick={handleDownloadClick}
                      />
                    </div>
                    &nbsp;
                    <MUIDataTable
                      data={data}
                      columns={columns}
                      options={{
                        selectableRows: 'none',
                        download: true,
                        print: true,
                      }}
                    />
                  </CustomBlock>
                </ContentBlock>
              </Section>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...auth.actions,
    ...alert.actions,
    ...confirmModal.actions
  }
)(ComplianceReport);