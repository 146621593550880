/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// ICONS
import {
  FiEye,
  FiEyeOff,
  FiMail
} from 'react-icons/fi';
// import { IoIosKeypad } from 'react-icons/io';
// CUSTOM COMPONENTS
import Section from '../../../../../components/Section';
import ContentBlock from '../../../../../components/ContentBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import FormBlock from '../../../../../components/FormBlock';
import ContentHeader from '../../../../../components/ContentHeader';
import Button from '../../../../../components/Button';
import ModalBlock from '../../../../../components/ModalBlock';
import InputBlock from '../../../../../components/InputBlock';
import CheckboxBlock from '../../../../../components/CheckboxBlock';
import SelectBlock from '../../../../../components/SelectBlock';
import OverLay from '../../../../../components/Overlay';
// HELPERS AND SERVICES
import * as userService from '../../../../../services/management/userService';
import * as roleService from '../../../../../services/management/roleService';
import * as clientService from '../../../../../services/management/clientService';
import * as helper from '../../../../../helpers/helper';
import RegisterUserValidator from '../../../../../helpers/validators/management/user/RegisterUserValidator';
import UpdatePasswordValidator from '../../../../../helpers/validators/management/user/UpdatePasswordValidator';
import EditUserValidator from '../../../../../helpers/validators/management/user/EditUserValidator';
// REDUX
import * as alert from '../../../../../redux/alertToastRedux';
import * as auth from '../../../../../redux/authRedux';

const userRegisterModel = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  roleIds: '',
  registrationCode: '',
  clientId: '',
  username: '',
  phone: '',
  password: '',
  confirmPassword: '',
  isActive: true
};

const initialUpdatePasswordModel = {
  id: 0,
  password: '',
  confirmPassword: '',
};

const initialEditModel = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  roleIds: [],
  clientId: '',
  phone: '',
  isActive: true,
  dateRegistered: null,
  registrationCode: ''
};

const UserListingPage = (props) => {
  const { showAlert, location, auth } = props;
  const [registerModalVisible, setRegisterModalVisible] = useState(false);
  const [updatePasswordModalVisible, setUpdatePasswordRegisterModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [updatePasswordModel, setUpdatePasswordModel] = useState(initialUpdatePasswordModel);
  const [editUserModel, setEditUserModel] = useState(initialEditModel);
  const [roleOptions, setRoleOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientOptions, setClientOptions] = useState([]);
  const [clients, setClients] = useState([]);
  // const [client, setClient] = useState([]);

  useEffect(() => {
    getAllUsers();
    getAllRoles();
    getClients();
    if (location.state) {
      setRegisterModalVisible(location.state.addNew);
    }
  }, []);

  useEffect(() => {
    if (selectedRole) {
      registerUserFormik.validateForm();
      editUserFormik.validateForm();
    }
  }, [selectedRole]);

  const iconSize = 22;
  // const iconColor = 'white--clr';
  const inputIconColor = 'grey--clr';
  // const pinCodeIcon = <IoIosKeypad size={iconSize} className={inputIconColor} />;
  const emailIcon = <FiMail size={iconSize} className={inputIconColor} />;

  const getAllRoles = () => {
    roleService.getAllRoles().then((res) => {
      if (auth.accessLevel < 50) {
        setRoleOptions(res.filter((x) => x.name !== 'Admin').map((x) => helper.setToOptionModel(x.name, x.id)));
      } else {
        setRoleOptions(res.map((x) => helper.setToOptionModel(x.name, x.id)));
      }
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => { });
  };

  const getClients = () => {
    setIsLoading(true);
    clientService.getAllClients(true).then((res) => {
      setClientOptions(res.map((x) => helper.setToOptionModel(x.name, x.id)));
      setClients(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const registerUserFormik = useFormik({
    initialValues: userRegisterModel,
    validationSchema: RegisterUserValidator({ selectedRole }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      registerUser(values);
    },
  });

  const updatePasswordUserFormik = useFormik({
    initialValues: updatePasswordModel,
    validationSchema: UpdatePasswordValidator,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      updateUserPassword(values);
    },
  });

  const editUserFormik = useFormik({
    initialValues: editUserModel,
    validationSchema: () => EditUserValidator({ selectedRole }),
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      editUser(values);
    },
  });

  const getUserDetails = (id) => {
    setIsLoading(true);
    userService.getUserById(auth.clientIds ? parseInt(auth.clientIds, 10) : '', id).then((res) => {
      setEditUserModel(res);
      setEditModalVisible(true);
      const roles = roleOptions.filter((x) => res.roleIds.includes(x.value));
      if (roles.length > 0) {
        setSelectedRole(roles[0]);
      }
      const client = clientOptions.find((x) => res.clientId === x.value.toString());
      const registrationCode = clients.find((x) => x.id.toString() === res.clientId);
      if (client && registrationCode) {
        setSelectedClient(client);
        editUserFormik.setFieldValue('registrationCode', registrationCode.registrationCode);
      }
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const getAllUsers = (ids) => {
    setIsLoading(true);
    userService.getAllUsers(ids).then((res) => {
      setUserList(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const registerUser = (model) => {
    setIsLoading(true);
    userService.addUser({ ...model, username: model.email }).then((res) => {
      getAllUsers();
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
      closeRegisterModal();
    });
  };

  const closeRegisterModal = () => {
    setRegisterModalVisible(false);
    registerUserFormik.resetForm();
    setSelectedRole(null);
    setSelectedClient(null);
  };

  const closePasswordModal = () => {
    setUpdatePasswordModel(initialUpdatePasswordModel);
    updatePasswordUserFormik.resetForm();
    setUpdatePasswordRegisterModalVisible(false);
  };

  const updateUserPassword = (model) => {
    setIsLoading(true);
    userService.changeUserPassword(model.id, model.password).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      closePasswordModal();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const editUser = (model) => {
    setIsLoading(true);
    userService.updateUser(model).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      getAllUsers();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
      closeEditModal();
    });
  };

  const closeEditModal = () => {
    setEditModalVisible(false);
    setTimeout(() => {
      editUserFormik.resetForm();
      setEditUserModel(initialEditModel);
      setSelectedClient(null);
      setSelectedRole(null);
      // setRegistrationCode('');
    }, 100);
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true,
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (

          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text={auth.accessLevel >= 40 ? 'Edit' : 'View'}
              className="primary--bg ml-0"
              size="xxs"
              onClick={() => {
                getUserDetails(userList[dataIndex].id);
              }}
            />
            {
              auth.accessLevel >= 50
              && (
                <Button
                  text="Reset Password"
                  className="secondary--bg ml-5"
                  size="xxs"
                  onClick={() => {
                    setUpdatePasswordRegisterModalVisible(true);
                    setUpdatePasswordModel({ ...updatePasswordModel, id: userList[dataIndex].id });
                  }}
                />
              )
            }

          </CustomBlock>
        )
      }
    },
    {
      name: 'firstName',
      label: 'First Name',
      options: columnOptions,
    },
    {
      name: 'lastName',
      label: 'Last Name',
      options: columnOptions,
    },
    {
      name: 'email',
      label: 'Email',
      options: columnOptions,
    },
    {
      name: 'phone',
      label: 'Phone',
      options: columnOptions,
    },
    {
      name: 'role',
      label: 'Role',
      options: columnOptions,
    },
    {
      name: 'client',
      label: 'Client',
      options: {
        filter: true,
        sort: true,
        print: false,
        download: true,
        customBodyRenderLite: (dataIndex) => (<p>{userList[dataIndex].client ? userList[dataIndex].client : '-'}</p>)
      },
    },
    {
      name: 'dateRegistered',
      label: 'Registered Date',
      options: {
        filter: true,
        sort: true,
        print: false,
        download: true,
        customBodyRenderLite: (dataIndex) => (<p>{userList[dataIndex].dateRegistered}</p>)
      },
    },
  ];

  return (
    <>
      {isLoading && <OverLay hasLoader />}

      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              {
                auth.accessLevel >= 50
                && (
                  <ContentHeader
                    title="Users"
                    headerSize="lg"
                    primaryButtonText="New User"
                    primaryButtonOnClick={() => {
                      setRegisterModalVisible(true);
                    }}
                  />
                )
              }

              <CustomBlock>
                <MUIDataTable
                  data={userList}
                  columns={columns}
                  options={{
                    selectableRows: 'none',
                    download: true,
                    print: false,
                    jumpToPage: true,
                    textLabels: { pagination: { jumpToPage: 'Page No:' } }
                  }}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      {/* REGISTER MODAL */}
      <ModalBlock
        hasCloseAction
        centered
        isVisible={registerModalVisible}
        size="lg"
        contentHeader="New User"
        primaryModalActionText="Add"
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={registerUserFormik.handleSubmit}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="danger--bg"
        onHide={closeRegisterModal}
      >
        <FormBlock onSubmit={registerUserFormik.handleSubmit}>
          <Section hasNoContainer>
            <ContentBlock cols={6}>
              <InputBlock
                label="First Name"
                isRequired
                errorMessage={registerUserFormik.errors.firstName}
                inputState={`${helper.getInputClasses(registerUserFormik, 'firstName')}`}
                {...registerUserFormik.getFieldProps('firstName')}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                label="Last Name"
                isRequired
                errorMessage={registerUserFormik.errors.lastName}
                inputState={`${helper.getInputClasses(registerUserFormik, 'lastName')}`}
                {...registerUserFormik.getFieldProps('lastName')}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <SelectBlock
                label="User Role"
                placeholder="Select user roles"
                id="User Role"
                name="User Role"
                options={roleOptions}
                isRequired
                value={selectedRole}
                inputState={registerUserFormik.errors.roleIds ? 'error' : ''}
                errorMessage={registerUserFormik.errors.roleIds}
                onChange={(opt) => {
                  opt = opt === null ? [] : opt;
                  setSelectedRole(opt);
                  registerUserFormik.setFieldValue('roleIds', [opt.value]);
                }}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                label="Email Address"
                iconLeft={emailIcon}
                isRequired
                errorMessage={registerUserFormik.errors.email}
                inputState={`${helper.getInputClasses(registerUserFormik, 'email')}`}
                {...registerUserFormik.getFieldProps('email')}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                mask="(999)-999-9999"
                label="Phone"
                errorMessage={registerUserFormik.errors.phone}
                inputState={`${helper.getInputClasses(registerUserFormik, 'phone')}`}
                {...registerUserFormik.getFieldProps('phone')}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                type={isPasswordVisible ? 'text' : 'password'}
                label="Password"
                placeholder="Enter password"
                isRequired
                iconRight={isPasswordVisible ? <FiEye size={iconSize} className="dark-blue--clr" /> : <FiEyeOff size={iconSize} className="dark-blue--clr" />}
                iconRightOnClick={() => {
                  setIsPasswordVisible(!isPasswordVisible);
                }}
                errorMessage={registerUserFormik.errors.password}
                inputState={`${helper.getInputClasses(registerUserFormik, 'password')}`}
                {...registerUserFormik.getFieldProps('password')}
              />
            </ContentBlock>
            <ContentBlock cols={6}>
              <InputBlock
                type={isConfirmPasswordVisible ? 'text' : 'password'}
                label="Confirm Password"
                placeholder="Enter password"
                isRequired
                iconRight={isConfirmPasswordVisible ? <FiEye size={iconSize} className="dark-blue--clr" /> : <FiEyeOff size={iconSize} className="dark-blue--clr" />}
                iconRightOnClick={() => {
                  setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
                }}
                errorMessage={registerUserFormik.errors.confirmPassword}
                inputState={`${helper.getInputClasses(registerUserFormik, 'confirmPassword')}`}
                {...registerUserFormik.getFieldProps('confirmPassword')}
              />
            </ContentBlock>
            
            <ContentBlock cols={6}>
              <SelectBlock
                label="Client"
                placeholder="Select Client Association"
                id="Client"
                name="Client"
                isDisabled={auth.accessLevel < 40
                  || (selectedRole && selectedRole.label === 'Admin')
                  || (selectedRole && selectedRole.label === 'Researcher')}
                options={clientOptions}
                isRequired
                isClearable
                value={selectedClient}
                errorMessage={registerUserFormik.errors.clientId}
                inputState={`${helper.getInputClasses(registerUserFormik, 'clientId')}`}
                onChange={(opt) => {
                  if (opt) {
                    setSelectedClient(opt);
                    registerUserFormik.setFieldValue('clientId', opt.value);
                    const client = clients.find((x) => x.id === opt.value);
                    if (client) {
                      registerUserFormik.setFieldValue('registrationCode', client.registrationCode);
                    }
                  } else {
                    registerUserFormik.setFieldValue('clientId', '');
                    setSelectedClient({});
                    registerUserFormik.setFieldValue('registrationCode', '');
                  }
                }}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                label="Registration Code"
                isRequired
                // iconLeft={emailIcon}
                errorMessage={registerUserFormik.errors.registrationCode}
                inputState={`${helper.getInputClasses(registerUserFormik, 'registrationCode')}`}
                {...registerUserFormik.getFieldProps('registrationCode')}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <CheckboxBlock
                label="Is user active?"
                id="isActive"
                {...registerUserFormik.getFieldProps('isActive')}
                isChecked={registerUserFormik.values.isActive}
              />
            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>

      {/* RESET PASSWORD MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={updatePasswordModalVisible}
        size="lg"
        centered
        contentHeader="Reset User Password"
        primaryModalActionText="Reset"
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={updatePasswordUserFormik.handleSubmit}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="danger--bg"
        onHide={() => {
          setUpdatePasswordRegisterModalVisible(false);
        }}
      >
        <FormBlock onSubmit={updatePasswordUserFormik.handleSubmit}>
          <Section hasNoContainer>
            <ContentBlock cols={6}>
              <InputBlock
                type={isPasswordVisible ? 'text' : 'password'}
                label="New Password"
                placeholder="Enter new password"
                iconRight={isPasswordVisible ? <FiEye size={iconSize} className="dark-blue--clr" /> : <FiEyeOff size={iconSize} className="dark-blue--clr" />}
                iconRightOnClick={() => {
                  setIsPasswordVisible(!isPasswordVisible);
                }}
                errorMessage={updatePasswordUserFormik.errors.password}
                inputState={`${helper.getInputClasses(updatePasswordUserFormik, 'password')}`}
                {...updatePasswordUserFormik.getFieldProps('password')}
                isRequired
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                type={isConfirmPasswordVisible ? 'text' : 'password'}
                label="Confirm New Password"
                placeholder="Confirm new password"
                iconRight={isConfirmPasswordVisible ? <FiEye size={iconSize} className="dark-blue--clr" /> : <FiEyeOff size={iconSize} className="dark-blue--clr" />}
                iconRightOnClick={() => {
                  setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
                }}
                errorMessage={updatePasswordUserFormik.errors.confirmPassword}
                inputState={`${helper.getInputClasses(updatePasswordUserFormik, 'confirmPassword')}`}
                {...updatePasswordUserFormik.getFieldProps('confirmPassword')}
                isRequired
              />
            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>

      {/* EDIT MODAL */}
      <ModalBlock
        hasCloseAction
        centered
        isVisible={editModalVisible}
        size="lg"
        isPrimaryActionHidden={auth.accessLevel < 40}
        contentHeader="Edit User"
        primaryModalActionText="Update"
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={editUserFormik.handleSubmit}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="danger--bg"
        onHide={() => {
          closeEditModal();
        }}
      >
        <FormBlock onSubmit={editUserFormik.handleSubmit}>
          <Section hasNoContainer>
            <ContentBlock cols={6}>
              <InputBlock
                label="First Name"
                isRequired
                isDisabled={auth.accessLevel < 40}
                inputState={`${helper.getInputClasses(editUserFormik, 'firstName')}`}
                errorMessage={editUserFormik.errors.firstName}
                {...editUserFormik.getFieldProps('firstName')}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                label="Last Name"
                isDisabled={auth.accessLevel < 40}
                inputState={`${helper.getInputClasses(editUserFormik, 'lastName')}`}
                errorMessage={editUserFormik.errors.lastName}
                {...editUserFormik.getFieldProps('lastName')}
                isRequired
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <SelectBlock
                label="User Role"
                isDisabled={auth.accessLevel < 40}
                placeholder="Select user roles"
                options={roleOptions}
                isRequired
                inputState={editUserFormik.errors.roleIds ? 'error' : ''}
                errorMessage={editUserFormik.errors.roleIds}
                value={selectedRole}
                onChange={(opt) => {
                  opt = opt === null ? [] : opt;
                  setSelectedRole(opt);
                  editUserFormik.setFieldValue('roleIds', [opt.value]);
                  if (opt.label === 'Admin' || opt.label === 'Researcher') {
                    setSelectedClient('');
                    editUserFormik.setFieldValue('registrationCode', '');
                    editUserFormik.setFieldValue('clientId', '');
                  }
                }}
              />
            </ContentBlock>
            <ContentBlock cols={6}>
              <SelectBlock
                label="Client"
                placeholder="Select Client Association"
                id="Client"
                name="Client"
                isDisabled={auth.accessLevel < 40
                  || (selectedRole && selectedRole.label === 'Admin')
                  || (selectedRole && selectedRole.label === 'Researcher')}
                options={clientOptions}
                isRequired
                isClearable
                value={selectedClient}
                errorMessage={editUserFormik.errors.clientId}
                inputState={`${helper.getInputClasses(editUserFormik, 'clientId')}`}
                onChange={(opt) => {
                  if (opt) {
                    setSelectedClient(opt);
                    editUserFormik.setFieldValue('clientId', opt.value);
                    const client = clients.find((x) => x.id === opt.value);
                    if (client) {
                      editUserFormik.setFieldValue('registrationCode', client.registrationCode);
                    }
                  } else {
                    editUserFormik.setFieldValue('clientId', '');
                    setSelectedClient({});
                    editUserFormik.setFieldValue('registrationCode', '');
                  }
                }}
              />
            </ContentBlock>

            {/* <ContentBlock cols={6}>
              <InputBlock
                label="4-Digit Pin"
                iconLeft={pinCodeIcon}
                mask="9999"
                isRequired
                errorMessage={editUserFormik.errors.pin}
                inputState={`${helper.getInputClasses(editUserFormik, 'pin')}`}
                {...editUserFormik.getFieldProps('pin')}
              />
            </ContentBlock> */}

            <ContentBlock cols={6}>
              <InputBlock
                isDisabled={auth.accessLevel < 40}
                label="Email"
                errorMessage={editUserFormik.errors.email}
                inputState={`${helper.getInputClasses(editUserFormik, 'email')}`}
                {...editUserFormik.getFieldProps('email')}
                isRequired
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                label="Phone"
                isDisabled={auth.accessLevel < 40}
                mask="(999)-999-9999"
                errorMessage={editUserFormik.errors.phone}
                inputState={`${helper.getInputClasses(editUserFormik, 'phone')}`}
                {...editUserFormik.getFieldProps('phone')}
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                label="Registration Code"
                // iconLeft={emailIcon}
                isDisabled
                inputState={`${helper.getInputClasses(editUserFormik, 'registrationCode')}`}
                errorMessage={editUserFormik.errors.registrationCode}
                {...editUserFormik.getFieldProps('registrationCode')}
              />
            </ContentBlock>

            <ContentBlock cols={6} className="mt-50">
              <CheckboxBlock
                label="Is Active?"
                id="isActive"
                disabled={auth.accessLevel < 40}
                {...editUserFormik.getFieldProps('isActive')}
                isChecked={editUserFormik.values.isActive}
              />
            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...auth.actions, ...alert.actions })(UserListingPage);