/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
// COMPONENTS
// ICONS
import { IoPersonSharp } from 'react-icons/io5';
import {
  FiEye,
  FiEyeOff,
  FiMail
} from 'react-icons/fi';
// CUSTOM COMPONENTS
import Section from '../../../../../components/Section';
import ContentBlock from '../../../../../components/ContentBlock';
import CustomBlock from '../../../../../components/CustomBlock';
import FormBlock from '../../../../../components/FormBlock';
import ContentHeader from '../../../../../components/ContentHeader';
import ModalBlock from '../../../../../components/ModalBlock';
import InputBlock from '../../../../../components/InputBlock';
// import CheckboxBlock from '../../../../../components/CheckboxBlock';
import OverLay from '../../../../../components/Overlay';
// HELPERS AND SERVICES
import * as userService from '../../../../../services/management/userService';
import * as helper from '../../../../../helpers/helper';
import UpdatePasswordValidator from '../../../../../helpers/validators/management/user/UpdatePasswordValidator';
// REDUX
import * as alert from '../../../../../redux/alertToastRedux';
import * as auth from '../../../../../redux/authRedux';

const initialEditModel = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  clientId: '',
  isActive: true,
};

const initialUpdatePasswordModel = {
  id: 0,
  password: '',
  confirmPassword: '',
};

const UserProfilePage = (props) => {
  const { showAlert } = props;
  const [userProfile, setUserProfile] = useState(initialEditModel);
  const [isLoading, setIsLoading] = useState(false);
  const [updatePasswordModel, setUpdatePasswordModel] = useState(initialUpdatePasswordModel);
  const [updatePasswordModalVisible, setUpdatePasswordRegisterModalVisible] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

  useEffect(() => {
    getUserDetail();
  }, []);

  const iconSize = 22;
  const iconColor = 'white--clr';
  const inputIconColor = 'grey--clr';
  // const pinCodeIcon = <IoIosKeypad size={iconSize} className={inputIconColor} />;
  const emailIcon = <FiMail size={iconSize} className={inputIconColor} />;

  const updatePasswordUserFormik = useFormik({
    initialValues: updatePasswordModel,
    validationSchema: UpdatePasswordValidator,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      updateUserPassword(values);
    },
  });

  const updateUserPassword = (model) => {
    setIsLoading(true);
    userService.changeSelfPassword(userProfile.id, model.password).then((res) => {
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
      closePasswordModal();
    });
  };

  const closePasswordModal = () => {
    setUpdatePasswordModel(initialUpdatePasswordModel);
    updatePasswordUserFormik.resetForm();
    setUpdatePasswordRegisterModalVisible(false);
  };

  const getUserDetail = () => {
    setIsLoading(true);
    userService.getLoggedInUser().then((res) => {
      setUserProfile(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="My Profile"
                headerSize="lg"
                primaryButtonText="Reset Password"
                primaryButtonBackgroundColor="secondary--bg"
                primaryButtonIconLeft={<IoPersonSharp className={iconColor} size={iconSize} />}
                primaryButtonOnClick={() => {
                  setUpdatePasswordRegisterModalVisible(true);
                }}
              />
              <CustomBlock>
                <FormBlock>
                  <Section hasNoContainer>
                    <ContentBlock cols={3}>
                      <InputBlock
                        label="First Name"
                        isRequired
                        inputState="disabled"
                        value={userProfile.firstName}
                      />
                    </ContentBlock>

                    <ContentBlock cols={3}>
                      <InputBlock
                        label="Last Name"
                        isRequired
                        inputState="disabled"
                        value={userProfile.lastName}
                      />
                    </ContentBlock>

                    {/* <ContentBlock cols={3}>
                      <InputBlock
                        label="4-Digit Pin"
                        isRequired
                        inputState="disabled"
                        value={userProfile.pin}
                      />
                    </ContentBlock> */}

                    <ContentBlock cols={3}>
                      <InputBlock
                        label="Email Address"
                        iconLeft={emailIcon}
                        isRequired
                        inputState="disabled"
                        value={userProfile.email}
                      />
                    </ContentBlock>

                    {/* <ContentBlock cols={6}>
                      <CheckboxBlock
                        label="Is user active?"
                        id="isActive"
                        isChecked={userProfile.isActive}
                      />
                    </ContentBlock> */}
                  </Section>
                </FormBlock>
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      {/* RESET PASSWORD MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={updatePasswordModalVisible}
        size="lg"
        centered
        contentHeader="Reset User Password"
        primaryModalActionText="Reset"
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={updatePasswordUserFormik.handleSubmit}
        secondaryModalActionText="Cancel"
        secondaryModalActionColor="danger--bg"
        onHide={() => {
          setUpdatePasswordRegisterModalVisible(false);
        }}
      >
        <FormBlock onSubmit={updatePasswordUserFormik.handleSubmit}>
          <Section hasNoContainer>
            <ContentBlock cols={6}>
              <InputBlock
                type={isPasswordVisible ? 'text' : 'password'}
                label="New Password"
                placeholder="Enter new password"
                iconRight={isPasswordVisible ? <FiEye size={iconSize} className="dark-blue--clr" /> : <FiEyeOff size={iconSize} className="dark-blue--clr" />}
                iconRightOnClick={() => {
                  setIsPasswordVisible(!isPasswordVisible);
                }}
                errorMessage={updatePasswordUserFormik.errors.password}
                inputState={`${helper.getInputClasses(updatePasswordUserFormik, 'password')}`}
                {...updatePasswordUserFormik.getFieldProps('password')}
                isRequired
              />
            </ContentBlock>

            <ContentBlock cols={6}>
              <InputBlock
                type={isConfirmPasswordVisible ? 'text' : 'password'}
                label="Confirm New Password"
                placeholder="Confirm new password"
                iconRight={isConfirmPasswordVisible ? <FiEye size={iconSize} className="dark-blue--clr" /> : <FiEyeOff size={iconSize} className="dark-blue--clr" />}
                iconRightOnClick={() => {
                  setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
                }}
                errorMessage={updatePasswordUserFormik.errors.confirmPassword}
                inputState={`${helper.getInputClasses(updatePasswordUserFormik, 'confirmPassword')}`}
                {...updatePasswordUserFormik.getFieldProps('confirmPassword')}
                isRequired
              />
            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...auth.actions, ...alert.actions })(UserProfilePage);